import styled from "styled-components";
import { errorTextColor } from "../../utils/colorConstants";

const H1 = styled.h1`
  color: black;
`;

const CenterH1 = styled.h1`
  color: black;
  text-align: center;
`;

const CenterPara = styled.p`
  color: black;
  text-align: center;
  margin: 0 auto;
`;

const LightCenterPara = styled.p`
  color: #00000040;
  text-align: center;
`;

export const ErrorText = styled.p`
  color: ${errorTextColor} !important;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 16px;
  text-align: left;
`;

export const ErrorTextpassw = styled.p`
  color: ${errorTextColor} !important;
  font-size: 14px;
  margin: auto;
  margin-top: 0px;
  line-height: 0px;
  margin-bottom: 16px;
  text-align: left;
`;
export const ErrorTextlastname = styled.p`
  color: ${errorTextColor} !important;
  font-size: 14px;
  line-height: 20px;
  margin-left: 45px;
  margin-bottom: 16px;
  text-align: left;
`;

export { H1, CenterH1, CenterPara, LightCenterPara };
