import * as React from "react";
import { Grid } from "@mui/material";
import { useHistory } from "react-router";
import Header from "../../../parts/Header";
import styles from "./styles.module.css";
import WelcomeImg from "../../../assets/images/welcomeScreen.png";
import partyGif from "../../../assets/images/party.gif";
import ButtonContainer from "../../../styledComponents/button/button";
import { APPLICATION_ROUTES } from "../../../utils/constant";
import { getUserSurveyStats } from "../../../redux/action-creator/userAction";
import { useDispatch } from "react-redux";
import { AuthContext } from "../../../context/authContext/authContext";

const WelcomeScreen: React.FC = () => {
  const history = useHistory();

  const { authData } = React.useContext(AuthContext);

  console.log("authContextin welcome screen", authData);

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getUserSurveyStats());
  }, []);

  return (
    <>
      <Header />
      <div className={`classContainer ${styles.welcomeScreenContainer}`}>
        <Grid container alignItems="center" justifyContent="center">
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
            className={styles.welcomeCol1}
          >
            <div className={styles.welcomeScreenHeading}>
              <div className={styles.partyPopper}>
                <img src={partyGif} alt="congratulations" />
              </div>
              <h1
                style={{
                  color: "#000",
                  fontWeight: 600,
                  marginBottom: 25,
                  fontSize: "32px",
                }}
              >
                Email Verified!
              </h1>
              <h3 className={`${styles.majorHeading}`}>
                Subscription Successful! <br />
                <span style={{ color: "rgba(64, 84, 123, 1)" }}>
                  {`${authData && authData.userPlan}`} Plan
                </span>
              </h3>
            </div>

            <div className={styles.btnContainer}>
              <ButtonContainer
                primary={false}
                color={false}
                onClick={() => {
                  history.push(APPLICATION_ROUTES.CREATE_NEW_SURVEY);
                }}
              >
                SurveyChatty Dashboard
              </ButtonContainer>
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
            className={styles.welcomeCol2}
          >
            <img src={WelcomeImg} alt="Welcome" />
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default WelcomeScreen;
