import * as React from "react";
import { Grid } from "@mui/material";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import Header from "../../../parts/Header";
import styles from "./styles.module.css";
import contactImg from "../../../assets/images/contactPage.png";
import sendGif from "../../../assets/images/sendGif.gif";
import sendTail from "../../../assets/images/sendTail.png";
import { contactUs } from "../../../redux/action-creator/userAction";
import { contactSchema, contactValues } from "./util";
import { ALERT_CONSTANTS } from "../../../utils/constant";
import SuccessErrorMessageBox from "../../../components/SuccessErrorMessageBox/SuccessErrorMessageBox";

type ContactType = {
  isRestricted?: boolean;
};

const Contact: React.FC<ContactType> = ({ isRestricted }) => {
  const dispatch = useDispatch();
  const [isSubmit, setIsSubmit] = React.useState(false);

  const formik = useFormik({
    initialValues: contactValues,
    async onSubmit(values) {
      console.log(values);
      const res = await dispatch(contactUs(values));
      setIsSubmit(true);
      console.log("res", res);
    },
    validationSchema: contactSchema,
  });

  React.useEffect(() => {
    if (!formik.isSubmitting) return;
    if (Object.keys(formik.errors).length > 0) {
      document.getElementsByName(Object.keys(formik.errors)[0])[0].focus();
    }
  }, [formik]);

  return (
    <>
      <Header isRestricted={isRestricted} />
      <div className={`classContainer ${styles.contactContainer}`}>
        <h1 className="majorHeading">Contact Us</h1>
        <Grid container spacing={4} className={styles.contactFormContainer}>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
            className={styles.contactFormCol1}
          >
            <div className={styles.contactFormInnerContainer}>
              {!isSubmit ? (
                <form
                  className={styles.contactFormBlock}
                  onSubmit={formik.handleSubmit}
                >
                  <h5>We would love to hear from you!</h5>
                  <SuccessErrorMessageBox
                    type="success"
                    messageKey={ALERT_CONSTANTS.CONTACT_US}
                  />
                  <SuccessErrorMessageBox
                    type="error"
                    messageKey={ALERT_CONSTANTS.CONTACT_US}
                  />
                  <div className={styles.gridFormFields}>
                    <div className={styles.firstNameField}>
                      <label className={styles.inputLabel}>First Name</label>
                      <input
                        type="text"
                        name="firstname"
                        id="firstname"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.firstname}
                        placeholder="First Name"
                      />
                      {formik.touched.firstname && formik.errors.firstname && (
                        <span style={{ color: "red", marginTop: "2px" }}>
                          {formik.errors.firstname}
                        </span>
                      )}
                    </div>
                    <div className={styles.lastNameField}>
                      <label className={styles.inputLabel}>Last Name</label>
                      <input
                        type="text"
                        name="lastname"
                        id="lastname"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.lastname}
                        placeholder="Last Name"
                      />
                      {formik.touched.lastname && formik.errors.lastname && (
                        <span style={{ color: "red", marginTop: "2px" }}>
                          {formik.errors.lastname}
                        </span>
                      )}
                    </div>
                  </div>
                  <div
                    className={`${styles.emailField} ${styles.contactField}`}
                  >
                    <label className={styles.inputLabel}>Business Name</label>
                    <input
                      type="text"
                      name="businessName"
                      id="businessName"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.businessName}
                      placeholder="Business Name"
                    />
                  </div>
                  <div
                    className={`${styles.emailField} ${styles.contactField}`}
                  >
                    <label className={styles.inputLabel}>Email</label>
                    <input
                      type="email"
                      name="email"
                      id="email"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.email}
                      placeholder="Email Address"
                    />
                    {formik.touched.email && formik.errors.email && (
                      <span style={{ color: "red", marginTop: "2px" }}>
                        {formik.errors.email}
                      </span>
                    )}
                  </div>
                  <div
                    className={`${styles.phoneField} ${styles.contactField}`}
                  >
                    <label className={styles.inputLabel}>Phone Number</label>
                    <input
                      type="text"
                      name="phone"
                      id="phone"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.phone}
                      placeholder="Phone Number  (xxx-xxx-xxxx)"
                    />
                    {formik.touched.phone && formik.errors.phone && (
                      <span style={{ color: "red", marginTop: "2px" }}>
                        {formik.errors.phone}
                      </span>
                    )}
                  </div>

                  <div
                    className={`${styles.messageField} ${styles.contactField}`}
                  >
                    <label className={styles.inputLabel}>Message</label>
                    <textarea
                      name="message"
                      id="message"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.message}
                      placeholder="Message"
                      rows={4}
                    />
                    {formik.touched.message && formik.errors.message && (
                      <span style={{ color: "red", marginTop: "2px" }}>
                        {formik.errors.message}
                      </span>
                    )}
                  </div>
                  <div className={styles.contactSubmitContainer}>
                    <button type="submit">Send</button>
                  </div>
                </form>
              ) : (
                <div className={styles.formSubmittedContainer}>
                  <div className={styles.sendGifContainer}>
                    <img src={sendGif} alt="Send Message" />
                    <img src={sendTail} alt="Send Tail" />
                  </div>
                  <h4 style={{ color: "#0d0", fontWeight: "700" }}>
                    Thanks for contacting us
                  </h4>
                  <p>One of our executive will connect with you soon!</p>
                </div>
              )}
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
            style={{ display: "flex", alignItems: "center" }}
            className={styles.contactFormCol2}
          >
            <img src={contactImg} alt="Contact" />
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default Contact;
