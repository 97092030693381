import * as React from "react";
import { ProfileButton } from "../Header/headerStyled";
import { useHistory } from "react-router-dom";
import { APPLICATION_ROUTES } from "../../utils/constant";
import { AuthContext } from "../../context/authContext/authContext";
import styles from "./styles.module.css";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Popover from "@mui/material/Popover";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";

type ProfileMenuType = {
  isSticky?: boolean;
  trigger: boolean;
};

const HeaderProfileMenu: React.FC<ProfileMenuType> = ({
  isSticky,
  trigger,
}) => {
  const authContext = React.useContext(AuthContext);
  const { authData, onLogout } = authContext;
  const history = useHistory();
  const profileBtn: any = React.createRef();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {};

  return (
    <>
      <PopupState variant="popover" popupId="demo-popup-popover">
        {(popupState) => (
          <div style={{ position: "relative" }}>
            <Button
              className={styles.profileBtn}
              variant="contained"
              {...bindTrigger(popupState)}
              ref={profileBtn}
            >
              Open Popover
            </Button>
            <ProfileButton
              scroll_on={isSticky ? trigger : true}
              id="basic-button"
              aria-haspopup="true"
              onClick={(e: any) => {
                handleClick(e);
                profileBtn.current.click();
              }}
            >
              <span className={styles.proIconSpan}>
                {authData.firstName.substring(0, 1)}
              </span>
              <span>{authData.firstName}</span>
              &nbsp;
              <span>{authData.lastName}</span>
            </ProfileButton>
            <Popover
              {...bindPopover(popupState)}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              <Typography
                style={{ cursor: "pointer" }}
                onClick={() => history.push(APPLICATION_ROUTES.ACCOUNT)}
                sx={{ p: 2 }}
              >
                Profile{" "}
              </Typography>

              <Typography
                sx={{ p: 2 }}
                style={{ cursor: "pointer" }}
                onClick={() => onLogout()}
              >
                Log out{" "}
              </Typography>
            </Popover>
          </div>
        )}
      </PopupState>
    </>
  );
};

export default HeaderProfileMenu;
