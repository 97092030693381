import * as React from "react";
import "./style.css";
import { Grid } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import OtpInput from "react-otp-input";
import { useHistory } from "react-router";
import { decodeToken } from "react-jwt";
import { Input as InputBox } from "../../../components";
import Header from "../../../parts/Header";
// import { AuthContext } from "../../../context/authContext/authContext";
import {
  ALERT_CONSTANTS,
  ALL_BUTTON_FLAGS,
  // APPLICATION_ROUTES,
} from "../../../utils/constant";
import { sendOtp } from "../../../redux/action-creator/userAction";
import { RootState } from "../../../redux/store";
import styles from "../Login/styles.module.css";
import FormHalfDiv from "../../../styledComponents/FormHalfDiv";
import otpScreen from "../../../assets/images/otpscreen.png";
import NewButton from "../../../components/Button/newButton";
// import { AuthData } from "../../../context/authContext/type";
import { setSuccessErrorMessage } from "../../../redux/action-creator/common.action";
import SuccessErrorMessageBox from "../../../components/SuccessErrorMessageBox/SuccessErrorMessageBox";
import { AuthContext } from "../../../context/authContext/authContext";
const OtpVerification: React.FC = () => {
  const authContext = React.useContext(AuthContext);
  const { verifyEmailOtp } = authContext;
  const dispatch = useDispatch();
  const history = useHistory();
  // const { setAuthState } = authContext;
  const { loading } = useSelector((state: RootState) => state.CommonReducer);
  const [otp, setOtp] = React.useState("");
  const [userData, setUserData] = React.useState<any>(null);
  // const [type, setType] = React.useState<any>(null);
  // const location: any = useLocation();
  const otpToken = localStorage.getItem("OTP_TOKEN");

  const verifyOtpEmail = () => {
    if (!userData || otp.length !== 6) {
      return dispatch(
        setSuccessErrorMessage(
          "Invalid OTP",
          "error",
          ALERT_CONSTANTS.OTP_VERIFICATION,
        ),
      );
    } else {
      dispatch(verifyEmailOtp({ otp, otpToken }, history));

      // dispatch(
      //   verifyEmailOtp({ userData, otp }, async (res: any) => {
      //     if (!res) {
      //       return;
      //     }
      //     if (res.isValid) {
      //       if (type == "verify") {
      //         console.log(
      //           "🚀 ~ file: index.tsx ~ line 68 ~ verifyEmailOtp ~ userData",
      //           userData,
      //         );
      //         dispatch(
      //           setSuccessErrorMessage(
      //             "Your Email has been successfully verified,Please login!",
      //             "success",
      //             ALERT_CONSTANTS.OTP_VERIFICATION,
      //           ),
      //         );
      //         if (
      //           userData?.selectedPlan === "Standard" &&
      //           res?.data &&
      //           res?.data?.token
      //         ) {
      //           console.log("in if condition===>");
      //           sessionStorage.setItem(
      //             "token",
      //             JSON.stringify(res?.data?.token),
      //           );
      //           localStorage.setItem("token", JSON.stringify(res?.data?.token));

      //           await setAuthState(decodeToken(res.data.token) as AuthData);
      //           history.push(APPLICATION_ROUTES.CONTINUE_PAYMENT, {
      //             userData,
      //           });
      //           return;
      //         }
      //         history.replace(APPLICATION_ROUTES.WELCOMESCREEN);
      //         return;
      //       }
      //       if (type == "forgot") {
      //         dispatch(
      //           setSuccessErrorMessage(
      //             "OTP has been verified",
      //             "success",
      //             ALERT_CONSTANTS.OTP_VERIFICATION,
      //           ),
      //         );
      //         history.replace(APPLICATION_ROUTES.RESET_PASSWORD, { userData });
      //         return;
      //       }
      //     }

      //     dispatch(
      //       setSuccessErrorMessage(
      //         "Invalid OTP",
      //         "error",
      //         ALERT_CONSTANTS.OTP_VERIFICATION,
      //       ),
      //     );
      //   }),
      // );
    }
  };
  const ResendOtp = () => {
    if (userData) dispatch(sendOtp({ otpToken }));
  };

  // React.useEffect(() => {
  //   if (location.state?.userData && location.state?.type) {
  //     setUserData(location.state?.userData);
  //     setType(location.state?.type);
  //   }
  // }, []);

  // React.useEffect(() => {
  //   sendOtpHandler();
  // }, [userData]);

  const handleChange = (otp: any) => setOtp(otp);
  React.useMemo(() => {
    if (otpToken && otpToken !== undefined && otpToken !== "undefined") {
      setUserData(decodeToken(otpToken));
    }
  }, [otpToken]);
  if (!otpToken) {
    return (
      <div>
        <p>OTP Expired! Please try to create account again.</p>
      </div>
    );
  }

  return (
    <>
      <Header />
      <Grid
        container
        spacing={4}
        alignItems="center"
        justifyContent="center"
        className={`classContainer ${styles.loginContainer}`}
      >
        <Grid item xs={12} md={6} lg={6} className={`${styles.loginCol1}`}>
          <div className={`${styles.loginBlock}`}>
            <h1 className={`majorHeading ${styles.loginTitle}`}>
              Verify Your Email
            </h1>
            <p style={{ marginTop: "20px", marginBottom: "20px" }}>
              Please check your email to verify the One Time Password (OTP).
            </p>
            <SuccessErrorMessageBox
              type="success"
              messageKey={ALERT_CONSTANTS.OTP_VERIFICATION}
            />
            <FormHalfDiv className="signUpFormContainer">
              <InputBox
                className="emailLogin"
                type="email"
                name="email"
                placeholder="Email*"
                value={userData ? userData?.email : ""}
                disabled
              />

              <div className="optVerify">
                <OtpInput
                  value={otp}
                  onChange={handleChange}
                  numInputs={6}
                  separator={<span />}
                  className="otpInputsFields"
                />
              </div>
              <br />
              <SuccessErrorMessageBox
                type="error"
                messageKey={ALERT_CONSTANTS.OTP_VERIFICATION}
              />
              <NewButton
                loader={loading}
                primary
                btnPrimary={false}
                type="button"
                onClick={verifyOtpEmail}
                buttonFlag={ALL_BUTTON_FLAGS.ON_OTP_VERIFY}
              >
                Verify
              </NewButton>

              <br />
              <br />

              <h6 className="verificationOtpPara">
                Didn't receive the verification OTP?{" "}
                <span
                  className="resendOtp"
                  onClick={ResendOtp}
                  // onClick={() => {
                  //   dispatch(
                  //     setSuccessErrorMessage(
                  //       "OTP sent succesfully",
                  //       "success",
                  //       ALERT_CONSTANTS.OTP_VERIFICATION,
                  //     ),
                  //   );

                  //   // sendOtpHandler();
                  // }}
                >
                  Resend OTP
                </span>
              </h6>
            </FormHalfDiv>
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          lg={6}
          className={styles.singUpImg}
          style={{ alignSelf: "center" }}
        >
          <img src={otpScreen} alt="Login/Signin" />
        </Grid>
      </Grid>
    </>
  );
};

export default React.memo(OtpVerification);
