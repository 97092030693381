import "./style.css";
import * as React from "react";
import { Box } from "@mui/material";
import { useFormik } from "formik";
import logoSvg from "../../assets/images/logo-white.png";
import { footerSchema, footerValues } from "./utils";
import { BASE_URL_FOR_NEXT_WEB } from "../../utils/apiConstant";

const Footer: React.FC = () => {
  const [, setIsSubmited] = React.useState(false);
  const formik = useFormik({
    initialValues: footerValues,
    validationSchema: footerSchema,
    onSubmit: () => {
      setIsSubmited(true);
      formik.setFieldValue("email", "");
    },
  });

  return (
    <Box component="main" sx={{ flexGrow: 1, p: 0 }}>
      <div className="FooterBg">
        <div className="footerContainer">
          <div className="footerlogo">
            <img
              src={logoSvg}
              alt=""
              onClick={() => {
                window.open(`${BASE_URL_FOR_NEXT_WEB}`, "_self");
              }}
              style={{ cursor: "pointer" }}
            />
          </div>

          <div className="FooterEnd">
            <p>Copyright ©{new Date().getFullYear()}. All Rights Reserved.</p>
          </div>
        </div>
      </div>
    </Box>
  );
};

export default Footer;
