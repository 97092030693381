import * as React from "react";
import { Drawer, IconButton } from "@material-ui/core";
import { Menu, Close } from "@material-ui/icons";
import {
  CustomLink,
  MobileLink,
  MobileLinkContainer,
  MobileNav,
} from "./headerStyled";
import { secondaryColor } from "../../utils/colorConstants";
import { APPLICATION_ROUTES } from "../../utils/constant";
import { AuthContext } from "../../context/authContext/authContext";
import HeaderProfileMenu from "../HeaderMenuOptions/headerProfileMenu";
import { BASE_URL_FOR_NEXT_WEB } from "../../utils/apiConstant";

const MobileMenu = () => {
  const [state, setState] = React.useState(false);
  const authContext = React.useContext(AuthContext);
  const toggleDrawer = () => {
    setState((prev) => !prev);
  };

  return (
    <div>
      <IconButton onClick={toggleDrawer}>
        <Menu />
      </IconButton>
      <Drawer anchor="top" open={state} onClose={toggleDrawer}>
        <MobileLinkContainer>
          <MobileNav onClick={toggleDrawer}>
            <Close style={{ color: secondaryColor }} />
          </MobileNav>
          {/* <CustomLink to="#pricing">
            <MobileLink>Plan & Pricing</MobileLink>
          </CustomLink> */}
          <a
            href={BASE_URL_FOR_NEXT_WEB + "/planPricing"}
            className="header-logo"
          >
            <MobileLink>Plan & Pricing</MobileLink>
          </a>

          {/* <CustomLink to={APPLICATION_ROUTES.ABOUT_US}>
              <MobileLink>About Us</MobileLink>
            </CustomLink> */}
          <CustomLink to={APPLICATION_ROUTES.DASHBOARD}>
            <MobileLink>My Surveys</MobileLink>
          </CustomLink>
          <CustomLink to="#contact">
            <MobileLink>Contact Us</MobileLink>
          </CustomLink>
          {authContext?.authData ? (
            <HeaderProfileMenu trigger={false} />
          ) : (
            <>
              <CustomLink to={APPLICATION_ROUTES.LOGIN}>
                <MobileLink>Login</MobileLink>
              </CustomLink>
              <CustomLink to={APPLICATION_ROUTES.SIGN_UP}>
                <MobileLink>Sign Up</MobileLink>
              </CustomLink>
            </>
          )}
        </MobileLinkContainer>
      </Drawer>
    </div>
  );
};

export default MobileMenu;
