import styled from "styled-components";

type CardType = {
  width?: string;
  height?: string;
};

const Card = styled.div<CardType>`
  width: ${(props) => (props.width ? props.width : "auto")};
  height: ${(props) => (props.height ? props.height : "auto")};
  padding: 2%;
  border-radius: 20px;
  background: #fff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
`;

export default Card;
