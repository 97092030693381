export const mainButtonColor = "#40547B";
export const primaryColor = "#0000FF";
export const secondaryColor = "#ffffff";
export const mainBorderColor = "#ccc";
export const normalTextColor = "#666666";
export const primaryOrangeGradient =
  "linear-gradient(270.27deg, #FC644C -10.48%, #FF7E45 82.09%, #E9AE16 123.16%)";
export const secondaryButtonColor = " #40547B";
export const errorTextColor = "red";
export const headingColor = "#000000";
export const tertiaryButtonColor = "#2c4b74";
export const linkColor = "#002ED0";
export const secondaryBorderColor = "#F69033";
export const menuFontColor = "#121212";
export const tooltipBackgroundColor = "#616161";
