import { CircularProgress } from "@mui/material";
import { IButtonProps } from ".";
import ButtonContainer from "../../styledComponents/button/button";

const NewButton: React.FC<IButtonProps> = ({
  onClick,
  className,
  type,
  loader,
  icon,
  background,
  buttonFlag,
  disable,
  padding,
  children,
  primary,
  btnPrimary,
}) => {
  return (
    <ButtonContainer
      onClick={onClick}
      className={className || ""}
      type={type || "submit"}
      disabled={
        (loader && loader.state && loader.buttonFlag === buttonFlag) || disable
      }
      primary={primary}
      btnPrimary={btnPrimary}
      background={background}
      padding={padding}
    >
      {loader && loader.state && loader.buttonFlag === buttonFlag ? (
        <CircularProgress color="secondary" size="20px" />
      ) : (
        children
      )}

      {icon}
    </ButtonContainer>
  );
};

export default NewButton;
