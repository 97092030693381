import styled from "styled-components";

const Select = styled.select`
  width: 100%;
  color: #1d1d1b;
  padding: 0.5em;
  background-color: white;
  margin: 0.5em;
  border-radius: 8px;

  &:first-child {
    color: black;
    padding: 0.5em;
    background-color: white;
    margin: 1em;
    border-radius: 8px;
  }
`;

export default Select;
