import * as React from "react";
import { Grid } from "@mui/material";
import Header from "../../../parts/Header";
import image from "../../../assets/images/Survey.png";
import styles from "./styles.module.css";

const AboutUs: React.FC = () => {
  return (
    <>
      <Header />
      <div className={`classContainer ${styles.aboutContainer}`}>
        <h1 className="majorHeading">About Us</h1>
        <Grid container spacing={2} style={{ marginTop: "30px" }}>
          <Grid item xs={12} lg={6} style={{ paddingTop: "0px" }}>
            <p style={{ margin: "20px 0px" }}>
              Survey Assistant is an AI-powered conversational Assistant which
              provides new interactive way of getting feedback from your
              customers. Assistant engages the customer in interactive
              conversation while collecting the survey feedback, which enriches
              customer experience.
            </p>
            <p>
              A customer may frown at a long survey form, but not to a friendly
              survey assistant collecting feedback. People are more likely to
              interact with an interactive survey assistant, which allows
              businesses to leverage the conversational nature to enhance the
              customer experience and profile their target audience better.
            </p>
          </Grid>
          <Grid item xs={12} lg={6}>
            <img src={image} alt="About" />
          </Grid>
        </Grid>
      </div>
    </>
  );
};
export default AboutUs;
