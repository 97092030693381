import { Dispatch } from "redux";
import { _GET, _POST } from "../../services/axios.method";
import {
  CREATE_DIALOGFLOW_INTENT,
  GET_INTENTS_SURVEY,
  GET_SURVEY_DETAIL,
  UPDATE_WELCOME_MESSAGE_API,
  UPDATE_SURVEY,
  GET_SURVEY_LIST,
  UPDATE_INTENT,
  CREATE_SURVEY,
  GET_DASHBOARD_DATA,
  DELETE_SURVEY_QUESTION,
  DELETE_DIALOGFLOW_INTENT_API,
  GET_QUESTIONS_SUGGESTIONS,
  GET_WELCOME_SUGGESTIONS,
  GET_CLOSING_SUGGESTIONS,
  GET_ALL_MESSAGES,
  SAVE_SURVEY_QUESTIONS,
  GET_SURVEY_QUESTIONS,
  DISABLE_ENABLE_SURVEY,
  GET_ANSWER_GENIUS,
} from "../../utils/apiConstant";
import { ALERT_CONSTANTS, ALL_BUTTON_FLAGS } from "../../utils/constant";
import {
  ICreateDfIntent,
  ICreateSurvey,
  IUpdateDefaultIntent,
} from "../../utils/interfaceModel";
import toast from "../../utils/toast";
import { Action } from "../action";
import { ActionType } from "../types";
import { setLoading, setSuccessErrorMessage } from "./common.action";

export const createSurveyAction =
  (payload: ICreateSurvey, callback: any) =>
  async (dispatch: Dispatch<any>) => {
    try {
      dispatch(setLoading(true, ALL_BUTTON_FLAGS.CREATE_SURVEY_BUTTON));
      const { data } = await _POST(CREATE_SURVEY, payload);
      dispatch(setLoading(false, ALL_BUTTON_FLAGS.CREATE_SURVEY_BUTTON));
      if (data && data.code === 200 && data.status === true) {
        dispatch({
          type: ActionType.CREATE_SURVEY,
          payload: data.data,
        });
        dispatch({
          type: ActionType.UPDATE_DEFAULT_INTENT,
          payload: null,
        });
        dispatch({
          type: ActionType.GET_INTENTS_SURVEY,
          payload: null,
        });

        await callback(data.data);
      } else {
        dispatch(
          setSuccessErrorMessage(
            "Failed to create survey. Please contact admin!",
            "error",
            ALERT_CONSTANTS.CREATE_NEW_SURVEY,
          ),
        );
        console.error("Failed to create survey. Please contact admin!");
      }
    } catch (err) {
      dispatch(setLoading(false, ALL_BUTTON_FLAGS.CREATE_SURVEY_BUTTON));
    }
  };

export const saveSurveyQuestions =
  (
    payload: ICreateDfIntent,
    isSavingQuestion?: boolean,
    callback?: any,
    customMessage?: string,
  ) =>
  async (dispatch: Dispatch<any>) => {
    try {
      const newPayloadContent = payload.payloadContent.map((data, index) => ({
        ...data,
        questionNo: index + 1,
      }));
      dispatch(setLoading(true, ALL_BUTTON_FLAGS.SAVE_SURVEY_QUESTIONS));
      const res = await _POST(SAVE_SURVEY_QUESTIONS, {
        ...payload,
        payloadContent: newPayloadContent,
      });
      if (!isSavingQuestion) {
        dispatch({
          type: ActionType.GET_INTENTS_SURVEY,
          payload: res.data?.data?.questions,
        });
        if (res.data?.data?.questions) {
          const questions = res.data?.data?.questions || [];

          dispatch({
            type: ActionType.UPDATE_DEFAULT_INTENT,
            payload: questions.find((data: any) => data?.isWelcome),
          });
        }
      }
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      callback && callback();
      dispatch(
        setSuccessErrorMessage(
          customMessage ? customMessage : "Saved as draft!",
          "success",
          ALERT_CONSTANTS.ADD_QUESTIONAIRE,
        ),
      );
      dispatch(setLoading(false, ALL_BUTTON_FLAGS.SAVE_SURVEY_QUESTIONS));
    } catch (err) {
      dispatch(setLoading(false, ALL_BUTTON_FLAGS.SAVE_SURVEY_QUESTIONS));
      toast.error("Failed to add Survey Questions. Please contact admin!");
    }
  };

export const createDialogFlowIntent =
  (surveyId: string, callback: any) => async (dispatch: Dispatch<any>) => {
    try {
      dispatch(
        setLoading(true, ALL_BUTTON_FLAGS.CREATE_DIALOG_FLOW_INTENT_BUTTON),
      );
      const { data } = await _POST(CREATE_DIALOGFLOW_INTENT, {
        surveyId,
      });
      dispatch(
        setLoading(false, ALL_BUTTON_FLAGS.CREATE_DIALOG_FLOW_INTENT_BUTTON),
      );
      if (data && data.code === 200 && data.status === true) {
        dispatch({
          type: ActionType.CREATE_SURVEY,
          payload: data.data,
        });
        dispatch(
          setLoading(false, ALL_BUTTON_FLAGS.CREATE_DIALOG_FLOW_INTENT_BUTTON),
        );
        if (data && data.code === 200 && data.status === true) {
          dispatch({
            type: ActionType.CREATE_SURVEY,
            payload: data.data,
          });

          callback();
        } else {
          dispatch(
            setSuccessErrorMessage(
              "Failed to add Survey Questions. Please contact admin!",
              "error",
              ALERT_CONSTANTS.ADD_QUESTIONAIRE,
            ),
          );

          console.error(
            "Failed to add Survey Questions. Please contact admin!",
          );
        }
      }
    } catch (err) {
      dispatch(
        setLoading(false, ALL_BUTTON_FLAGS.CREATE_DIALOG_FLOW_INTENT_BUTTON),
      );
      dispatch(
        setSuccessErrorMessage(
          "Failed to add Survey Questions. Please contact admin!",
          "error",
          ALERT_CONSTANTS.ADD_QUESTIONAIRE,
        ),
      );
      toast.error("Failed to add Survey Questions. Please contact admin!");
    }
  };

export const deleteExistingQuestionBeforeUpdate =
  (payload: any, callback: any) => async (dispatch: Dispatch<any>) => {
    await dispatch(
      setLoading(true, ALL_BUTTON_FLAGS.DELETE_EXISTING_SURVEY_QUESTION),
    );
    try {
      const { data } = await _POST(DELETE_DIALOGFLOW_INTENT_API, payload);
      if (data && data.code === 200 && data.status === true) {
        dispatch({
          type: ActionType.CREATE_SURVEY,
          payload: data.data,
        });
        callback();
      } else {
        toast.error("Something went wrong. Please try again.");
      }
      await dispatch(
        setLoading(false, ALL_BUTTON_FLAGS.DELETE_EXISTING_SURVEY_QUESTION),
      );
    } catch (err) {
      await dispatch(
        setLoading(false, ALL_BUTTON_FLAGS.DELETE_EXISTING_SURVEY_QUESTION),
      );
    }
  };

export const updateDefaultIntent =
  (payload: IUpdateDefaultIntent, callback: any) =>
  async (dispatch: Dispatch<any>) => {
    try {
      await dispatch(setLoading(true, ALL_BUTTON_FLAGS.SAVE_PROCEES));

      const { data } = await _POST(UPDATE_WELCOME_MESSAGE_API, payload);
      await dispatch(setLoading(false, ALL_BUTTON_FLAGS.SAVE_PROCEES));
      if (data && data.code === 200 && data.status === true) {
        dispatch({
          type: ActionType.UPDATE_DEFAULT_INTENT,
          payload,
        });

        callback();
      } else {
      }
    } catch (err) {
      dispatch(setLoading(false, ALL_BUTTON_FLAGS.SAVE_PROCEES));
    }
  };

export const setErrorOnSections =
  (errorOnSections: any) => async (dispatch: Dispatch<any>) => {
    if (errorOnSections) {
      dispatch({
        type: ActionType.SET_ERROR_ON_SECTIONS,
        payload: errorOnSections,
      });
    }
  };

export const updateIntent = (payload: any, callback: any) => async () => {
  try {
    const { data } = await _POST(UPDATE_INTENT, payload);
    if (data && data.code === 200 && data.status === true) {
      callback();
    } else {
    }
  } catch (err) {}
};

export const updateSurvey =
  (payload: any) => async (dispatch: Dispatch<any>) => {
    dispatch(setLoading(true, ALL_BUTTON_FLAGS.UPDATE_SURVEY));
    try {
      await _POST(UPDATE_SURVEY, payload);

      dispatch({
        type: ActionType.UPDATE_SURVEY,
        payload,
      });

      dispatch(setLoading(false, ALL_BUTTON_FLAGS.UPDATE_SURVEY));
    } catch (err) {
      dispatch(setLoading(false, ALL_BUTTON_FLAGS.UPDATE_SURVEY));
    }
  };

export const getSurveySavedQuestions =
  (surveyId: any) => async (dispatch: Dispatch<Action>) => {
    const res = await _POST(GET_SURVEY_QUESTIONS, { surveyId });
    dispatch({
      type: ActionType.GET_INTENTS_SURVEY,
      payload: res.data?.data?.questions,
    });
    if (res.data?.data?.questions) {
      const questions = res.data?.data?.questions || [];

      dispatch({
        type: ActionType.UPDATE_DEFAULT_INTENT,
        payload: questions.find((data: any) => data?.isWelcome),
      });
    } else {
      dispatch({
        type: ActionType.UPDATE_DEFAULT_INTENT,
        payload: null,
      });
      dispatch({
        type: ActionType.GET_INTENTS_SURVEY,
        payload: null,
      });
    }
  };

export const getSurveyIntent =
  (surveyId: any, callback: any) => async (dispatch: Dispatch<Action>) => {
    const res = await _POST(GET_INTENTS_SURVEY, { surveyId });
    dispatch({
      type: ActionType.GET_INTENTS_SURVEY,
      payload: res.data?.data?.intents,
    });
    if (res.data?.data?.intents) {
      const intents = res.data?.data?.intents || [];

      dispatch({
        type: ActionType.UPDATE_DEFAULT_INTENT,
        payload: intents.find((data: any) => data?.isWelcome),
      });
    }
    callback(res.data?.data?.intents);
  };

export const getQuestionSuggestions =
  (categorySlug: any) => async (dispatch: Dispatch<Action>) => {
    try {
      const res = await _POST(GET_QUESTIONS_SUGGESTIONS, { categorySlug });
      console.log("res", res);
      dispatch({
        type: ActionType.GET_QUESTION_SUGGESTIONS,
        payload: res.data?.data?.length > 0 ? res.data?.data[0] : [],
      });
    } catch (err) {
      console.log("err on get question", err);
    }
  };

export const getQuestionBankModalSuggestions =
  (categorySlug: any) => async (dispatch: Dispatch<any>) => {
    try {
      const res = await _POST(GET_QUESTIONS_SUGGESTIONS, { categorySlug });
      console.log("res", res);
      dispatch({
        type: ActionType.GET_QUESTION_SUGGESTIONS_MODAL,
        payload: res.data?.data?.length > 0 ? res.data?.data[0] : null,
      });
    } catch (err) {
      console.log("err on get question", err);
    }
  };

export const getWelcomeSuggestions =
  (categorySlug: any) => async (dispatch: Dispatch<Action>) => {
    try {
      const res = await _POST(GET_WELCOME_SUGGESTIONS, { categorySlug });
      console.log("welcome message suggestion data", res);
      dispatch({
        type: ActionType.GET_WELCOME_SUGGESTIONS,
        payload: res.data?.data?.length > 0 ? res.data?.data[0] : [],
      });
    } catch (err) {
      console.log("err on get welcome", err);
    }
  };

export const getAnswerGenius = () => async (dispatch: Dispatch<any>) => {
  try {
    const res = await _GET(GET_ANSWER_GENIUS);
    if (res.data?.data) {
      dispatch({
        type: ActionType.GET_ANSWER_GENIUS,
        payload: res.data?.data?.length > 0 ? res.data?.data : [],
      });
    }
  } catch (err) {
    console.log("err on get welcome", err);
  }
};

export const getClosingSuggestions =
  (categorySlug: any) => async (dispatch: Dispatch<Action>) => {
    try {
      const res = await _POST(GET_CLOSING_SUGGESTIONS, { categorySlug });
      console.log("closing message suggestion data", res);
      dispatch({
        type: ActionType.GET_CLOSING_SUGGESTIONS,
        payload: res.data?.data?.length > 0 ? res.data?.data[0] : [],
      });
    } catch (err) {
      console.log("err on get closing", err);
    }
  };

export const getSurveyDetail =
  (payload: any) => async (dispatch: Dispatch<any>) => {
    dispatch(setLoading(true, ALL_BUTTON_FLAGS.GET_SURVEY_DETAILS));
    const res = await _GET(`${GET_SURVEY_DETAIL}?surveyId=${payload.surveyId}`);
    dispatch(setLoading(false, ALL_BUTTON_FLAGS.GET_SURVEY_DETAILS));
    dispatch({
      type: ActionType.GET_SURVEY,
      payload: res.data.data,
    });
  };

export const getSurveysByUser =
  (search?: any, filter?: any) => async (dispatch: Dispatch<any>) => {
    dispatch(setLoading(true, ALL_BUTTON_FLAGS.GET_SURVEYS_BY_USER));
    const res = await _GET(
      `${GET_SURVEY_LIST}?getActive=true&search=${search || ""}&filter=${
        filter || ""
      }`,
    );
    dispatch(setLoading(false, ALL_BUTTON_FLAGS.GET_SURVEYS_BY_USER));
    dispatch({
      type: ActionType.GET_SURVEY_LIST,
      payload: res.data.data,
    });
  };

export const getPublishedSurveysByUser =
  (search?: any, filter?: any) => async (dispatch: Dispatch<any>) => {
    dispatch(setLoading(true, ALL_BUTTON_FLAGS.GET_PUBLISHED_SURVEYS_BY_USER));
    const res = await _GET(
      `${GET_SURVEY_LIST}?getPublished=true&getActive=true&search=${
        search || ""
      }&filter=${filter || ""}`,
    );
    dispatch(setLoading(false, ALL_BUTTON_FLAGS.GET_PUBLISHED_SURVEYS_BY_USER));
    dispatch({
      type: ActionType.GET_PUBLISHED_SURVEY_LIST,
      payload: res.data.data,
    });
  };

export const getDisabledSurveysByUser =
  (search?: any, filter?: any) => async (dispatch: Dispatch<any>) => {
    dispatch(setLoading(true, ALL_BUTTON_FLAGS.GET_DISABLED_SURVEYS_BY_USER));
    const res = await _GET(
      `${GET_SURVEY_LIST}?search=${search || ""}&filter=${filter || ""}`,
    );
    dispatch(setLoading(false, ALL_BUTTON_FLAGS.GET_DISABLED_SURVEYS_BY_USER));
    dispatch({
      type: ActionType.GET_DISABLED_SURVEY_LIST,
      payload: res.data.data,
    });
  };

export const searchSurveysByUser =
  (search?: any) => async (dispatch: Dispatch<any>) => {
    dispatch(setLoading(true, ALL_BUTTON_FLAGS.SEARCH_SURVEY_HEADER));
    const res = await _GET(
      `${GET_SURVEY_LIST}?getActive=true&search=${search || ""}`,
    );
    const disabledRes = await _GET(`${GET_SURVEY_LIST}?search=${search || ""}`);
    dispatch(setLoading(false, ALL_BUTTON_FLAGS.SEARCH_SURVEY_HEADER));
    dispatch({
      type: ActionType.SEARCH_SURVEY_HEADER,
      payload: [...res.data.data, ...disabledRes.data.data],
    });
  };
export const getDashboardData = () => async (dispatch: Dispatch<any>) => {
  dispatch(setLoading(true, ALL_BUTTON_FLAGS.GET_DASHBOARD_DATA));
  const res = await _GET(GET_DASHBOARD_DATA);
  dispatch(setLoading(false, ALL_BUTTON_FLAGS.GET_DASHBOARD_DATA));
  dispatch({
    type: ActionType.GET_DASHBOARD_DATA,
    payload: res.data.data,
  });

  console.log("Dashboard Data", res.data.data);
};

export const deleteSurveyQuestion =
  (surveyId: string, intentId: string) => async (dispatch: Dispatch<any>) => {
    dispatch(setLoading(true, ALL_BUTTON_FLAGS.DELETE_SURVEY_QUESTION));
    const res = await _POST(DELETE_SURVEY_QUESTION, { surveyId, intentId });
    dispatch(setLoading(false, ALL_BUTTON_FLAGS.DELETE_SURVEY_QUESTION));
    dispatch({
      type: ActionType.DELETE_SURVEY_QUESTION,
      payload: res.data.data,
    });
  };

export const disableEnableSurvey =
  ({ surveyData, callback }: any) =>
  async (dispatch: Dispatch<any>) => {
    dispatch(setLoading(true, ALL_BUTTON_FLAGS.DELETE_SURVEY));
    const res = await _POST(DISABLE_ENABLE_SURVEY, surveyData);

    if (res.data?.data) {
      dispatch({
        type: ActionType.GET_SURVEY_LIST,
        payload: res.data?.data?.activeSurveys,
      });
      dispatch({
        type: ActionType.GET_DISABLED_SURVEY_LIST,
        payload: res.data?.data?.disabledSurveys,
      });
      dispatch({
        type: ActionType.GET_DASHBOARD_DATA,
        payload: res.data?.data?.dashboardData,
      });
    }
    dispatch(setLoading(false, ALL_BUTTON_FLAGS.DELETE_SURVEY));
    callback(res);
  };

export const getAllMessages =
  (surveyId: string, currentPage?: number) =>
  async (dispatch: Dispatch<any>) => {
    const res = await _GET(
      `${GET_ALL_MESSAGES}/${surveyId}?currentPage=${currentPage}`,
    );

    dispatch({
      type: ActionType.GET_MESSAGES,
      payload: res.data.data,
    });
    if (res.data.data.length > 0) {
    }
  };
