import { Alert } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";

type SuccessErrorMessageBoxType = {
  type: "success" | "error";
  messageKey: string;
};

const SuccessErrorMessageBox: React.FC<SuccessErrorMessageBoxType> = ({
  type,
  messageKey,
}) => {
  const { errorMessages, successMessages } = useSelector(
    (state: RootState) => state.CommonReducer,
  );

  console.log(
    "errormessage and successMessages",
    errorMessages,
    successMessages,
  );

  return (
    <div style={{ width: "100%" }} className="alertContainer">
      {type === "error"
        ? errorMessages &&
          errorMessages.length < 2 &&
          errorMessages.map(
            (errorMessage: any) =>
              errorMessage.key === messageKey &&
              errorMessage.message !== "" && (
                <Alert
                  style={{ margin: "10px 0" }}
                  // variant="filled"
                  severity="error"
                >
                  {errorMessage.message}
                </Alert>
              ),
          )
        : successMessages &&
          successMessages.length < 2 &&
          successMessages.map(
            (successMessage: any) =>
              successMessage.key === messageKey && (
                <Alert
                  style={{ margin: "10px 0" }}
                  // variant="filled"
                  severity="success"
                >
                  {successMessage.message}
                </Alert>
              ),
          )}
    </div>
  );
};

export default SuccessErrorMessageBox;
