import { createStore, applyMiddleware, combineReducers } from "redux";
import { persistStore, persistReducer, createMigrate } from "redux-persist"; // imports from redux-persist
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import logger from "redux-logger";
import {
  dialogFlowReducer,
  userReducer,
  commonReducer,
  analyticsReducer,
} from "./reducers";

const appReducer = combineReducers({
  DialogFlowReducer: dialogFlowReducer,
  UserReducer: userReducer,
  CommonReducer: commonReducer,
  AnalyticsReducer: analyticsReducer,
});

const rootReducer = (state: any, action: any) => {
  if (action.type === "USER_LOGOUT") {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

const migrations = {
  0: (state: any) => {
    return {
      ...state,
    };
  },
};

const persistConfig = {
  key: "root",
  version: 0,
  storage,
  blacklist: ["DialogFlowReducer", "AnalyticsReducer"],
  migrate: createMigrate(migrations, { debug: true }),
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
const middleware = [thunk, logger];
const composeEnacher = composeWithDevTools({ trace: true, traceLimit: 25 });
const STORE = createStore(
  persistedReducer,
  composeEnacher(applyMiddleware(...middleware)),
);

const persistor = persistStore(STORE);

type RootState = ReturnType<typeof rootReducer>;

export { STORE, persistor };
export type { RootState };
