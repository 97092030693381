import { AnyAction, Dispatch } from "redux";
import { _GET, _POST } from "../../services/axios.method";
import {
  CREATE_CHECKOUT_SESSION,
  CONTACTUS_API_URL,
  GET_BUSINESS_CATEGORIES_URL,
  GET_CATEGORIES_URL,
  PASSOWORD_UPDATE,
  REDUCE_PLAN_SURVEY_COUNT,
  SEND_OTP_API_URL,
  USER_PLAN,
  USER_PROFILE_UPDATE_API_URL,
  VERIFY_OTP_API_URL,
  UPDATE_BUSINESS_NAME,
  PLAN_AND_PRICING,
  ADD_BILLING_DETAILS,
  ORDER_DETAILS,
  GET_USER_SURVEYS,
} from "../../utils/apiConstant";
import {
  ALERT_CONSTANTS,
  ALL_BUTTON_FLAGS,
  APPLICATION_ROUTES,
} from "../../utils/constant";
import {
  IContactUs,
  IUpdatePassword,
  IRequestQuote,
  IUpdateProfile,
} from "../../utils/interfaceModel";
import toast from "../../utils/toast";
import { Action } from "../action";
import { ActionType } from "../types";
import { setLoading, setSuccessErrorMessage } from "./common.action";

export const updateProfile =
  (payload: IUpdateProfile, callback: any) => async () => {
    const res = await _POST(USER_PROFILE_UPDATE_API_URL, payload);
    if (res?.data?.data) {
      localStorage.setItem("token", JSON.stringify(res.data.data.token));

      sessionStorage.setItem("token", JSON.stringify(res.data.data.token));

      callback(res.data.data.token);
    }
  };

export const updatePassword =
  (payload: IUpdatePassword, callback: any) => async () => {
    try {
      const res = await _POST(PASSOWORD_UPDATE, payload);

      if (res?.data && res?.data?.status) callback(res?.data?.status);
      else callback(false);
    } catch (error) {
      callback(false);
    }
  };
export const contactUs =
  (payload: IContactUs, callback?: any) => async (dispatch: Dispatch<any>) => {
    try {
      dispatch(setLoading(true, ALL_BUTTON_FLAGS.ON_CONTACT_US));
      const res = await _POST(CONTACTUS_API_URL, payload);

      console.log("response", res);
      if (res?.data && res?.data?.status) {
        dispatch(
          setSuccessErrorMessage(
            "Query sent successfully",
            "success",
            ALERT_CONSTANTS.CONTACT_US,
          ),
        );
      } else {
        dispatch(
          setSuccessErrorMessage(
            "Failed to send query",
            "error",
            ALERT_CONSTANTS.CONTACT_US,
          ),
        );
      }

      dispatch(setLoading(false, ALL_BUTTON_FLAGS.ON_CONTACT_US));
      return res;
    } catch (error) {
      dispatch(
        setSuccessErrorMessage(
          "Failed to send query",
          "error",
          ALERT_CONSTANTS.CONTACT_US,
        ),
      );
      dispatch(setLoading(false, ALL_BUTTON_FLAGS.ON_CONTACT_US));
      callback(false);
      toast.error("Something went wrong");
    }
  };
export const sendOtp = (otpToken: any) => async (dispatch: Dispatch<any>) => {
  try {
    const res = await _POST(SEND_OTP_API_URL, otpToken);
    if (res.data && res.data.code === 200 && res.data.status) {
      dispatch(
        setSuccessErrorMessage("", "error", ALERT_CONSTANTS.OTP_VERIFICATION),
      );
      localStorage.setItem("OTP_TOKEN", res.data.data.otpToken);
      dispatch(
        setSuccessErrorMessage(
          "OTP has been sent successfully",
          "success",
          ALERT_CONSTANTS.OTP_VERIFICATION,
        ),
      );
    } else {
      return dispatch(
        setSuccessErrorMessage(
          res.data.message,
          "error",
          ALERT_CONSTANTS.OTP_VERIFICATION,
        ),
      );
    }
  } catch (error) {
    dispatch(
      setSuccessErrorMessage(
        "Something went wrong while sending OTP",
        "error",
        ALERT_CONSTANTS.OTP_VERIFICATION,
      ),
    );
    console.error("Something went wrong while sending OTP");
  }
};

export const addBillingDetail = (data: any) => async (dispatch: any) => {
  try {
    // dispatch(setLoading(true, ALL_BUTTON_FLAGS.ON_OTP_VERIFY));
    const res = await _POST(ADD_BILLING_DETAILS, data);
    // dispatch(setLoading(false, ALL_BUTTON_FLAGS.ON_OTP_VERIFY));
    if (res && res.data && res.data.code === 200 && res.data.status) {
      // get the token with updated billing details and store in localstorage
      console.log("add billing", res.data.data);
      localStorage.setItem("OTP_TOKEN", res.data.data.otpToken);
    } else {
      dispatch(
        setSuccessErrorMessage(
          "Something went wrong! Please try again",
          "error",
          ALERT_CONSTANTS.OTP_VERIFICATION,
        ),
      );
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    // res?.data && callback(res.data?.data || null);
  } catch (error) {
    // dispatch(setLoading(false, ALL_BUTTON_FLAGS.ON_OTP_VERIFY));
    console.log("error", error);
  }
};

export const requestQuoteHandler =
  (payload: IRequestQuote, callback?: any) => async () => {
    try {
      const res = await _POST("/user/requestQuote", payload);

      console.log("response", res);
      if (res?.data && res?.data?.status)
        console.log("You have successfully posted your quotes");
      else {
        console.log("Something went wrong");
      }
      return res;
    } catch (error) {
      callback(false);
    }
  };

export const getAllCategories = () => async (dispatch: Dispatch<Action>) => {
  const res = await _GET(GET_CATEGORIES_URL);

  if (res.data?.data && res.data?.data?.categories)
    dispatch({
      type: ActionType.GET_ALL_CATEGORIES,
      payload: res.data?.data?.categories,
    });
};
export const getBusinessCategories =
  () => async (dispatch: Dispatch<AnyAction>) => {
    const res = await _GET(GET_BUSINESS_CATEGORIES_URL);

    if (res.data?.data && res.data?.data?.categories)
      dispatch({
        type: ActionType.GET_BUSINESS_CATEGORIES,
        payload: res.data?.data?.categories,
      });
  };

export const createCheckoutSession =
  (payload: any) => async (dispatch: any) => {
    try {
      dispatch(setLoading(true, ALL_BUTTON_FLAGS.CREATE_CHECKOUT_SESSION));
      const res = await _POST(CREATE_CHECKOUT_SESSION, payload);
      window.location.replace(res.data.url);
      console.log(res);
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setLoading(false, ALL_BUTTON_FLAGS.CREATE_CHECKOUT_SESSION));
    }
  };

export const GetUserPlan = () => async (dispatch: Dispatch<Action>) => {
  try {
    const res = await _GET(USER_PLAN);
    if (res?.data && res?.data?.status) {
      dispatch({
        type: ActionType.GET_USER_PLAN,
        payload: res.data.data,
      });
    }
  } catch (error) {
    console.log(error);
  }
};

export const GetPlanAndPricing = () => async (dispatch: any) => {
  try {
    dispatch(setLoading(true, ALL_BUTTON_FLAGS.GET_USER_PAYMENT_STATUS));
    const res = await _GET(PLAN_AND_PRICING);
    dispatch(setLoading(false, ALL_BUTTON_FLAGS.GET_USER_PAYMENT_STATUS));
    console.log("res for get plan and pricing", res?.data);
    if (res?.data && res?.data?.status) {
      dispatch({
        type: ActionType.PLAN_AND_PRICING,
        payload: res.data.data,
      });
    }
  } catch (error) {
    dispatch(setLoading(false, ALL_BUTTON_FLAGS.GET_USER_PAYMENT_STATUS));
    console.log(error);
  }
};

export const GetOrderDetails =
  (id: string , callback?:any) => async (dispatch: Dispatch<Action>) => {
    try {
      const res = await _GET(`${ORDER_DETAILS}/${id}`);
      console.log("res for get order details", res?.data);
      if (res?.data && res?.data?.status) {
        console.log("in if res data", res.data)
        dispatch({
          type: ActionType.ORDER_DETAILS,
          payload: res.data.data,
        });
        callback({status:res?.data?.status, data:res.data.data})
      }
      else callback(false)
    } catch (error) {
      console.log(error);
      callback(false)
    }
  };

export const ReduceUserPlanSurveyCount =
  () => async (dispatch: Dispatch<Action>) => {
    try {
      const res = await _POST(REDUCE_PLAN_SURVEY_COUNT);
      if (res?.data && res?.data?.status) {
        dispatch({
          type: ActionType.GET_USER_PLAN,
          payload: res.data.data,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

export const updateBusinessName =
  (businessName: string, callback: (data: any) => void) =>
  async (dispatch: any) => {
    try {
      if (businessName) {
        const res = await _POST(UPDATE_BUSINESS_NAME, { businessName });
        if (res?.data && res?.data?.status) {
          dispatch({
            type: ActionType.UPDATE_BUSINESS_NAME,
            payload: res.data.data,
          });
          callback(res.data.data);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

export const getUserSurveyStats = () => async (dispatch: Dispatch<any>) => {
  dispatch(setLoading(true, ALL_BUTTON_FLAGS.GET_USER_SURVEYS));
  const res = await _GET(`${GET_USER_SURVEYS}`);
  console.log("getUserSurveyStats", res);
  dispatch(setLoading(false, ALL_BUTTON_FLAGS.GET_USER_SURVEYS));
  if (res && res.data.code === 200 && res.data.status) {
    dispatch({
      type: ActionType.GET_USER_SURVEYS,
      payload: res.data.data,
    });
  }

  console.log("user survey data for Dashboard", res.data.data);
};
