import * as React from "react";
import { Link } from "react-router-dom";
import { useScrollTrigger } from "@material-ui/core";
import { useLocation } from "react-router";
import { styled, Theme, CSSObject } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import ListItemIcon from "@mui/material/ListItemIcon";
import * as Icons from "@material-ui/icons/";
import { ListItem } from "@mui/material";

import { dashboardRoutes } from "../../utils/routesConstant";
import whiteLogo from "../../assets/images/logo-white.png";
// import DrawerHeader from "./headerComponents/drawerHeader";
import HeaderSearchBar from "./headerComponents/headerSearchBar";
import { AuthContext } from "../../context/authContext/authContext";
import ProfileMenu from "../HeaderMenuOptions/profileMenu";
import { APPLICATION_ROUTES } from "../../utils/constant";
import styles from "./styles.module.css";
import { BASE_URL_FOR_NEXT_WEB } from "../../utils/apiConstant";

const drawerWidth = 200;

// const openedMixin = (theme: Theme): CSSObject => ({
//   width: drawerWidth,
//   transition: theme.transitions.create("width", {
//     easing: theme.transitions.easing.sharp,
//     duration: theme.transitions.duration.enteringScreen,
//   }),
//   overflowX: "hidden",
// });

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: "calc(90px + 15px)",
  [theme.breakpoints.up("sm")]: {
    width: "calc(90px + 15px)",
  },
});

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar)<AppBarProps>(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  // ...(open && {
  //   marginLeft: drawerWidth,
  //   width: `calc(100% - ${drawerWidth}px)`,
  //   transition: theme.transitions.create(["width", "margin"], {
  //     easing: theme.transitions.easing.sharp,
  //     duration: theme.transitions.duration.enteringScreen,
  //   }),
  // }),
}));

const Drawer = styled(MuiDrawer)(({ theme }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...{
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  },
}));

const MiniDrawer = () => {
  // const theme = useTheme();

  const authContext = React.useContext(AuthContext);
  const { onLogout } = authContext;

  const { pathname } = useLocation();
  // const [open, setOpen] = React.useState(pathname === "/createNewSurvey");

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 50,
  });

  // const handleDrawerOpen = () => {
  //   setOpen(true);
  // };

  // const handleDrawerClose = () => {
  //   setOpen(false);
  // };

  const [showSearch, setShowSearch] = React.useState(false);

  React.useEffect(() => {
    if (
      pathname == APPLICATION_ROUTES.DASHBOARD ||
      pathname == APPLICATION_ROUTES.ALL_ANALYTICS_PAGE
    ) {
      setShowSearch(true);
    } else {
      setShowSearch(false);
    }
  }, [pathname]);

  return (
    <>
      <CssBaseline />
      <AppBar position="fixed" className={`${styles.headerContainer}`}>
        <Toolbar className={styles.headerBlock} style={{ minHeight: "54px" }}>
          <div className={styles.logoContainer}>
            {/* <IconButton
              color="inherit"
              aria-label="open drawer"
              // onClick={handleDrawerOpen}
              edge="start"
              sx={{
                marginRight: 3,
                display: "none",
                // ...(open && { display: "none" }),
              }}
              className={styles.burgerMenu}
            >
              <MenuIcon />
            </IconButton> */}
            {/* <Link to={APPLICATION_ROUTES.HOME} className={styles.headerLogo}> */}
            <a href={BASE_URL_FOR_NEXT_WEB} className="header-logo">
              <img src={whiteLogo} alt="" />
            </a>
            {/* <Link to="#features" className={styles.headerLogo}>
              <img src={whiteLogo} alt="" />
            </Link> */}
          </div>
          <div className={styles.mobileHeaderLogo}>
            <Link to="#features" className="header-logo" />
          </div>
          <div className={styles.searchBlock}>
            {showSearch ? (
              <HeaderSearchBar
                responsive={{ display: { xs: "none", sm: "block" } }}
                placeholder="Search"
              />
            ) : (
              ""
            )}
          </div>

          <div className={ `${styles.profileContainer} ${styles.helpNewClass}` } >
            <a
              href="https://www.youtube.com/playlist?list=PLfQuqq2-HF0m7Kc9OYmxhpXgqxH7vpZaB"
              target="_blank"
              rel="noreferrer"
              style={{ color: "white", fontSize: "18px", fontWeight: 600 }}
            >
              Help Tutorial
            </a>
            <div className={styles.marginlr}><a>|</a></div>
            <ProfileMenu isSticky={false} trigger={trigger} />
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        // onMouseEnter={() => setOpen(true)}
        // onMouseLeave={() => setOpen(false)}
        variant="permanent"
        open={false}
        className={styles.headerDrawer}
      >
        {/* <DrawerHeader style={{ minHeight: "54px" }}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <div className={styles.arrowleft} />
            ) : (
              <div className={styles.arrowleft} />
            )}
          </IconButton>
        </DrawerHeader> */}
        {/* <Divider /> */}
        <List className={styles.dashboardLinkList}>
          {dashboardRoutes.map((menu, index) => (
            <ListItem
              button
              component={Link}
              to={menu.url}
              key={index}
              sx={{
                minHeight: 48,
                justifyContent: "center",
                px: 2.5,
              }}
              onClick={() => menu?.id == dashboardRoutes.length && onLogout()}
              className={
                menu.activeUrls.includes(pathname)
                  ? `${styles.activeNav} ${styles.dashboardList}`
                  : styles.dashboardList
              }
              selected={pathname === menu.url}
            >
              <ListItemIcon
                sx={{
                  minWidt00h: 0,
                  mr: 0,
                  justifyContent: "center",
                }}
                className={styles.menuIconContainer}
              >
                {React.createElement(Icons[`${menu.icon}`])}

                <span className={styles.menuSmallText}>{menu.name}</span>
              </ListItemIcon>
            </ListItem>
          ))}
        </List>
      </Drawer>
    </>
  );
};

export default MiniDrawer;
