import * as React from "react";
import { Input as InputStyle } from "../../styledComponents";

interface IInputProps {
  placeholder?: string;
  style?: string;
  type: string;
  name: string;
  handleChange?: any;
  className?: string;
  value?: string | boolean | undefined;
  width?: string;
  borderRadius?: string;
  height?: string;
  disabled?: boolean;
  icon?: any;
  onIconClick?: any;
  maxCharacter?: number;
  validateMaxCharacter?: boolean;
  key?: number;
  id?: string;
}
const Input: React.FC<IInputProps> = ({
  name,
  handleChange,
  placeholder,
  type,
  className,
  width,
  borderRadius,
  height,
  disabled,
  value,
  onIconClick,
  icon,
  maxCharacter,
  key,
  id,
}: IInputProps) => {
  return (
    <div style={{ position: "relative", width: "100%" }}>
      <InputStyle
        key={key}
        type={type}
        style={type === "checkbox" ? { cursor: "pointer" } : null}
        className={className}
        name={name}
        id={id}
        onChange={handleChange}
        placeholder={placeholder}
        value={value}
        width={width}
        borderRadius={borderRadius}
        height={height}
        disabled={disabled || false}
        maxLength={maxCharacter}
      />

      {icon && (
        <div
          onClick={onIconClick}
          className="svgEyeContainer"
          style={{
            position: "absolute",
            display: "flex",
            alignItems: "center",
            top: "0px",
            bottom: "0px",
            right: "15px",
            cursor: "pointer",
            margin: "auto",
          }}
        >
          {icon}
        </div>
      )}
    </div>
  );
};

export default Input;
