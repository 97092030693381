import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@mui/material";
import { Grid } from "@material-ui/core";
import { useHistory } from "react-router";
import DashboardHeader from "../../../parts/DashboardHeader";
import { ALL_BUTTON_FLAGS, APPLICATION_ROUTES } from "../../../utils/constant";
import { getPublishedSurveysByUser } from "../../../redux/action-creator/dialogflow.action";
import { getUserSurveyStats } from "../../../redux/action-creator/userAction";
import { RootState } from "../../../redux/store";
import CircularLoader from "../../../components/Loader";
import styles from "./styles.module.css";
import DashboardCard from "../../../components/DashBoardCard/DashBoardCard";
import useDebounce from "../../../hooks/useDebounce";
import noAnalyticsImg from "../../../assets/images/noAnalytics.png";
import ButtonContainer from "../../../styledComponents/button/button";

const AllSurveyAnalytics: React.FC = () => {
  const dispatch = useDispatch();

  const { publishedSurveyList } = useSelector(
    (state: RootState) => state.DialogFlowReducer,
  );

  const history = useHistory();
  const { loading } = useSelector((state: RootState) => state.CommonReducer);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [search, setSearch] = React.useState("");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [currentSort, setCurrentSort] = React.useState("");
  const debouncedSearch = useDebounce(search, 700);

  React.useEffect(() => {
    // dispatch(getDashboardData());
    dispatch(getUserSurveyStats());
  }, []);

  React.useEffect(() => {
    dispatch(getPublishedSurveysByUser(debouncedSearch, currentSort));
  }, [debouncedSearch, currentSort]);

  // const onSort = (sort: any) => {
  //   setCurrentSort(sort);
  // };

  return (
    <Box sx={{ display: "flex" }}>
      <DashboardHeader />

      <Box component="main" sx={{ flexGrow: 1, p: 0 }}>
        <div className={`classContainer ${styles.allSurveyAnalyticsPage}`}>
          <div className={`breadCrumbBlock ${styles.AnalyticsBreadcrumbs}`} />
          <div className={`${styles.allAnalytics}`}>
            <Grid container className={styles.analyticsHeaderContainer}>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <h2 className={`majorHeading ${styles.pageTitle}`}>
                  Survey Analytics
                </h2>
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={6}>
                <Grid container spacing={2} />
              </Grid>
            </Grid>
            {loading.state &&
            (loading.buttonFlag === ALL_BUTTON_FLAGS.GET_DASHBOARD_DATA ||
              loading.buttonFlag ===
                ALL_BUTTON_FLAGS.GET_PUBLISHED_SURVEYS_BY_USER) ? (
              <CircularLoader />
            ) : (
              <>
                {publishedSurveyList && publishedSurveyList.length > 0 ? (
                  <div
                    className={`${styles.cardContainer} ${styles.analyticsSingleCard}`}
                  >
                    {publishedSurveyList &&
                      publishedSurveyList.map((survey: any, index: any) => (
                        <DashboardCard
                          showAnalytics
                          key={survey._id}
                          data={survey}
                          delay={index * 1000}
                        />
                      ))}
                  </div>
                ) : (
                  <div className={`${styles.noAnalyticsSurvey}`}>
                    <img src={noAnalyticsImg} alt="No Analytics" />
                    <h4>You don't have any Published Surveys</h4>
                    <ButtonContainer
                      onClick={() =>
                        history.push(`${APPLICATION_ROUTES.CREATE_NEW_SURVEY}`)
                      }
                    >
                      Create Survey
                    </ButtonContainer>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </Box>
    </Box>
  );
};

export default AllSurveyAnalytics;
