import * as React from "react";
import styled from "styled-components";
import { CircularProgress } from "@material-ui/core";
import { mainButtonColor } from "../../utils/colorConstants";
import theme from "../../utils/theme";
import "./style.css";
import { ILoading } from "../../utils/interfaceModel";

type ButtonProps = {
  background?: string;
  padding?: string;
};
const StyledButton = styled.button<ButtonProps>`
  background: ${(props) =>
    props.background ? props.background : mainButtonColor};
  border-radius: 30px;
  font-size: 1.2em;
  border: none;
  color: #ffffff;
  margin: 0.25em;
  padding: ${(props) => (props.padding ? props.padding : "0.4em 2em")};
  justify-content: space-evenly;
  align-items: center;
  /* Color the border and text with theme.main */
  // color: ${(props) => props.theme.primary};
`;

export interface IButtonProps {
  text?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  className?: string;
  type?: "button" | "submit";
  loading?: any;
  icon?: any;
  background?: string;
  buttonFlag?: string;
  loader?: ILoading;
  disable?: boolean;
  padding?: string;
  primary?: boolean;
  btnPrimary?: boolean;
  children?: React.ReactNode;
}

const Button: React.FC<IButtonProps> = ({
  text,
  onClick,
  className,
  type,
  loader,
  icon,
  background,
  buttonFlag,
  disable,
  padding,
}) => {
  return (
    <StyledButton
      onClick={onClick}
      theme={theme}
      className={className || ""}
      type={type || "submit"}
      disabled={
        (loader && loader.state && loader.buttonFlag === buttonFlag) || disable
      }
      background={background}
      padding={padding}
    >
      {loader && loader.state && loader.buttonFlag === buttonFlag ? (
        <CircularProgress color="secondary" size="20px" />
      ) : (
        text
      )}

      {icon}
    </StyledButton>
  );
};

export default Button;
