import * as React from "react";
import "./style.css";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import { Grid } from "@material-ui/core";
import { useSelector } from "react-redux";
import {
  // ConstructionOutlined,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import { Input as InputBox } from "../../../components";
import ContainerDiv from "../../../styledComponents/ContainerDiv";
import Header from "../../../parts/Header";
import { registerSchema, registerValues } from "./util";
import { ErrorText, ErrorTextlastname } from "../../../styledComponents/Text";
import { AuthContext } from "../../../context/authContext/authContext";
import {
  ALERT_CONSTANTS,
  ALL_BUTTON_FLAGS,
  APPLICATION_ROUTES,
  USER_PLANS,
} from "../../../utils/constant";
import { RootState } from "../../../redux/store";
import GoogleLoginButton from "../Login/GoogleLoginButton";
import ButtonContainer from "../../../styledComponents/button/button";
import styles from "../Login/styles.module.css";
import FormHalfDiv from "../../../styledComponents/FormHalfDiv";
// import signUpImage from "../../../assets/images/SignUpGraphics.png";
import NewButton from "../../../components/Button/newButton";
import SuccessErrorMessageBox from "../../../components/SuccessErrorMessageBox/SuccessErrorMessageBox";
import { BASE_URL_FOR_NEXT_WEB } from "../../../utils/apiConstant";
import { useQuery } from "../../../hooks/useQueryHook";
// import CryptoJS from "crypto-js";
const SignUp: React.FC = () => {
  const authContext = React.useContext(AuthContext);
  const [showPassword, setShowPassword] = React.useState(false);
  const [userPlan, setUserPlan] = React.useState<string>("Free");

  const query = useQuery();
  // const userSelectedPlan = query.get("planType");
  const { loading } = useSelector((state: RootState) => state.CommonReducer);
  const { onSignUp } = authContext;
  const formik = useFormik({
    initialValues: registerValues,
    validationSchema: registerSchema,
    onSubmit: (values) => {
      onSignUp({ ...values, userPlan });
    },
  });

  React.useEffect(() => {
    setUserPlan(query.get("planType"));
  }, []);
  // React.useEffect(() => {
  //   console.log("selectedPlanType planType", encryptedPlanType);
  //   if (encryptedPlanType) {
  //     (async () => {
  //       const bytes = await CryptoJS.AES.decrypt(
  //         encryptedPlanType,
  //         CRYPTOJS_SECRET_KEY,
  //       );
  //       console.log("selectedPlanType bytes", bytes);
  //       const decryptedPlanType = await bytes.toString(CryptoJS.enc.Utf8);
  //       const selectedPlanType = JSON.parse(decryptedPlanType);
  //       console.log("selectedPlanType", selectedPlanType);
  //       setUserPlan(selectedPlanType.plan);
  //     })();
  //   }
  // }, []);

  return (
    <>
      <Header />
      <Grid
        container
        spacing={4}
        alignItems="center"
        justifyContent="center"
        className={`classContainer ${styles.loginContainer}`}
      >
        <Grid item xs={12} md={7} lg={6} className={`${styles.loginCol1}`}>
          <div className={`${styles.loginBlock}`}>
            <h1 className={`majorHeading ${styles.loginTitle}`}>Sign Up</h1>
            <p style={{ marginTop: "20px", marginBottom: "20px" }}>
              Great Survey Experience is just a step away for you!
            </p>
            <div className={styles.pricePlanContainer}>
              <div className={styles.selectedPlan}>
                <p>
                  Selected Plan:{" "}
                  <span
                    className={
                      userPlan == USER_PLANS.FREE
                        ? styles.greenColor
                        : styles.blueColor
                    }
                  >
                    <b>{userPlan || ""}</b>
                  </span>
                </p>
              </div>
              <div className={styles.upgradeBlock}>
                <ButtonContainer
                  onClick={() =>
                    window.open(`${BASE_URL_FOR_NEXT_WEB}/planPricing`, "_self")
                  }
                  className={
                    userPlan == USER_PLANS.FREE
                      ? styles.greenButton
                      : styles.blueButton
                  }
                >
                  Change Plan
                </ButtonContainer>
              </div>
            </div>
            <GoogleLoginButton userPlan = {userPlan} type={"signUp"} />
            <div className={`${styles.separatingContainer}`}>
              <hr />
              <p>or </p>

              <hr />
            </div>
            <p className={styles.manualHeadingLine}>Sign Up with your Email</p>
            <FormHalfDiv
              onSubmit={formik.handleSubmit}
              className="signUpFormContainer"
            >
              <ContainerDiv style={{ columnGap: "20px" }}>
                <InputBox
                  className="emailLogin"
                  type="text"
                  name="firstName"
                  placeholder="First Name*"
                  handleChange={formik.handleChange}
                />

                <InputBox
                  type="text"
                  name="lastName"
                  placeholder="Last Name*"
                  handleChange={formik.handleChange}
                />
              </ContainerDiv>
              {(formik.touched.firstName || formik.touched.lastName) && (
                <ContainerDiv>
                  <ErrorText>
                    {formik.touched.firstName && formik.errors.firstName}
                  </ErrorText>

                  <ErrorTextlastname>
                    {formik.touched.lastName && formik.errors.lastName}
                  </ErrorTextlastname>
                </ContainerDiv>
              )}
              <InputBox
                className="emailLogin"
                type="text"
                name="businessName"
                placeholder="Business Name (Optional)"
                handleChange={formik.handleChange}
              />

              <InputBox
                className="emailLogin"
                type="email"
                name="email"
                placeholder="Email*"
                handleChange={formik.handleChange}
              />
              {formik.touched.email && (
                <ErrorText>{formik.errors.email}</ErrorText>
              )}
              <div className="signUpPassword">
                <InputBox
                  className={`${styles.passwordLogin}`}
                  type={showPassword ? "text" : "password"}
                  name="password"
                  placeholder="Password*"
                  handleChange={formik.handleChange}
                  icon={showPassword ? <Visibility /> : <VisibilityOff />}
                  onIconClick={() => setShowPassword(!showPassword)}
                />
                {formik.touched.password && (
                  <ErrorText>{formik.errors.password}</ErrorText>
                )}
              </div>
              <InputBox
                className={`${styles.passwordLogin}`}
                type="password"
                name="confirmPassword"
                placeholder="Confirm Password*"
                handleChange={formik.handleChange}
              />
              {formik.touched.confirmPassword && (
                <ErrorText>{formik.errors.confirmPassword}</ErrorText>
              )}
              <SuccessErrorMessageBox
                type="error"
                messageKey={ALERT_CONSTANTS.SIGN_UP}
              />
              <NewButton
                loader={loading}
                primary
                btnPrimary={false}
                type="submit"
                buttonFlag={ALL_BUTTON_FLAGS.ON_REGISTER}
              >
                Next
                {/* {userPlan === USER_PLANS.STANDARD ? "Next" : "Sign Up"} */}
              </NewButton>

              <p
                style={{
                  marginBottom: "10px",
                  textAlign: "center",
                  marginTop: 10,
                }}
              >
                Already have an account?{" "}
                <Link
                  to={APPLICATION_ROUTES.LOGIN}
                  style={{ color: "#0846D5", fontWeight: "600" }}
                >
                  Login
                </Link>
              </p>
            </FormHalfDiv>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default React.memo(SignUp);
