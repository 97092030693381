import * as yup from "yup";

export const loginValues = {
  email: "",
  password: "",
  rememberMe: false,
};

export const loginSchema = yup
  .object({
    email: yup
      .string()
      .email("Email must be a valid email.")
      .required("This is a required field."),
    password: yup
      .string()
      .min(5, "Password must be at least 5 characters.")
      .required("This is a required field."),
  })
  .required();
