import axios from "axios";
import { BASE_URL } from "../utils/apiConstant";
import { STATUS_CODES } from "../utils/constant";
// const BASE_URL2 = process.env.REACT_APP_ENV_TEST as string;
// console.log("BASE_URL", BASE_URL2);

const axiosInstance = axios.create({
  baseURL: BASE_URL,
  validateStatus: function (status) {
    return status < STATUS_CODES.INTERNAL_ERROR;
  },
});

const errHandling = (error: Error) => Promise.reject(error);

const reqHandling = async (config: any) => {
  const obj = { ...config };
  if (localStorage.getItem("token")) {
    const token = await JSON.parse(
      localStorage.getItem("token") || sessionStorage.getItem("token"),
    );
    obj.headers["'Access-Control-Allow-Origin'"] = "*";
    // obj.headers["'Content-Type'"] = "application/json;charset=UTF-8";
    obj.headers.Authorization = token;
  }

  return obj;
};

const resHandling = (response: any) => response;
axiosInstance.interceptors.request.use(reqHandling, errHandling);
axiosInstance.interceptors.response.use(resHandling, errHandling);

export default axiosInstance;
