import * as React from "react";
import { useContext } from "react";
import { Route, Redirect, RouteProps } from "react-router-dom";
import { AuthContext } from "../context/authContext/authContext";
import LoadingPage from "../pages/staticPages/loadingPage";
import { APPLICATION_ROUTES } from "../utils/constant";

type PrivateRouteType = {
  visibleAfterLogin: boolean;
} & RouteProps;
export const RestrictedRoute: React.FC<PrivateRouteType> = ({
  component: Component,
  visibleAfterLogin,
  ...rest
}) => {
  const authContext = useContext(AuthContext);
  const { authData, authStateLoading }: any = authContext;

  if (authStateLoading) {
    return <LoadingPage />;
  }

  return (
    <Route
      {...rest}
      render={(props) => {
        if (authData && !visibleAfterLogin) {
          return (
            <Redirect
              to={{
                pathname: APPLICATION_ROUTES.CREATE_NEW_SURVEY,
                state: { from: props.location },
              }}
            />
          );
        }

        return <Component {...props} />;
      }}
    />
  );
};
