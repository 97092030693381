import * as yup from "yup";

export const footerValues = {
  email: "",
};

export const footerSchema = yup
  .object({
    email: yup.string().email().required("This is a required field."),
  })
  .required();
