import * as React from "react";
// ----------------------- Authenthication screens
export const LoginComponent = React.lazy(
  () => import("../pages/authentication/Login"),
);

export const SignupComponent = React.lazy(
  () => import("../pages/authentication/Signup"),
);

export const ResetPasswordComponent = React.lazy(
  () => import("../pages/authentication/ResetPassword"),
);

// ----------------------- Authenthication screens

// ----------------------- Dashboard screens
export const HomeComponent = React.lazy(
  () => import("../pages/screens/HomePage"),
);
export const ContactComponent = React.lazy(
  () => import("../pages/screens/contact"),
);
export const PaymentComponent = React.lazy(
  () => import("../pages/screens/Payment"),
);
export const WelcomeScreenComponent = React.lazy(
  () => import("../pages/staticPages/welcomeScreen"),
);
export const CreateNewSurveyComponent = React.lazy(
  () => import("../pages/screens/CreateNewSurvey"),
);
export const PricingComponent = React.lazy(
  () => import("../pages/screens/Pricing"),
);
export const AddQuestionariesComponent = React.lazy(
  () => import("../pages/screens/AddQuestionaries"),
);
// The account page
export const Account = React.lazy(() => import("../pages/screens/Account"));

// Manage Survey
export const ManageSurvey = React.lazy(
  () => import("../pages/screens/ManageSurvey"),
);

export const SurveyAnalytics = React.lazy(
  () => import("../pages/screens/SurveyAnalytics"),
);

export const Analytics = React.lazy(() => import("../pages/screens/Analytics"));

// New user dashboard
export const DashboardNewUser = React.lazy(
  () => import("../pages/screens/DashboardNewUser"),
);
export const ViewSurvey = React.lazy(
  () => import("../pages/screens/ViewSurvey/ViewSurvey"),
);
// error 404
export const Error404 = React.lazy(
  () => import("../pages/staticPages/errorPage"),
);
export const AboutUs = React.lazy(() => import("../pages/staticPages/aboutUs"));

export const PaymentSuccessful = React.lazy(
  () => import("../pages/screens/Payment/paymentSuccessful"),
);
export const PaymentSummary = React.lazy(
  () => import("../pages/screens/PaymentSummary"),
);
export const ShareSurvey = React.lazy(
  () => import("../pages/screens/AddQuestionaries/SharingSection"),
);
