import * as React from "react";
import { createContext, useState } from "react";
import { decodeToken } from "react-jwt";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router";
import CryptoJS from "crypto-js";
import { _POST } from "../../services/axios.method";
import {
  BASE_URL_FOR_NEXT_WEB,
  FORGOT_PASSWORD_API_URL,
  GOOGLE_LOGIN_URL,
  LOGIN_API_URL,
  RESET_PASSWORD_API_URL,
  SEND_RESET_PASS_EMAIL_API_URL,
  SIGNUP_AFTER_PAYMENT,
  SIGNUP_SEND_VERIFY_OTP_API_URL,
  VERIFY_OTP_API_URL,
} from "../../utils/apiConstant";
import {
  ALERT_CONSTANTS,
  ALL_BUTTON_FLAGS,
  APPLICATION_ROUTES,
  APP_ROLE,
  USER_PLANS,
} from "../../utils/constant";
import toast from "../../utils/toast";
import { IAuthContext, ResetPasswordType, AuthData, SignupType } from "./type";
import {
  setSuccessErrorMessage,
  setLoading as setCommonLoading,
} from "../../redux/action-creator/common.action";
import { GetUserPlan } from "../../redux/action-creator/userAction";
// import { RootState } from "../../redux/store";

const AuthContext = createContext<IAuthContext>(undefined!);
const { Provider } = AuthContext;

const AuthProvider: React.FC = (props) => {
  const [authState, setAuthState] = useState(null);
  const [userPlan, setUserPlan] = useState(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [Remember, setRemember] = useState(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [authError, setAuthError] = useState<string>("");
  const [authSuccess, setAuthSuccess] = useState<string>("");
  const history = useHistory();
  const dispatch = useDispatch();
  // const { myPlan } = useSelector((state: RootState) => state.UserReducer);
  // const { userSurveyStats } = useSelector(
  //   (state: RootState) => state.UserReducer,
  // );
  const { pathname } = useLocation();
  // console.log("userPlan", userPlan);

  const refreshToken = (token: string) => {
    setAuthState(decodeToken(token) as AuthData);
    if (localStorage.getItem("token")) {
      localStorage.setItem("token", JSON.stringify(token));
    } else {
      sessionStorage.setItem("token", JSON.stringify(token));
    }
  };

  const onLogin = async (
    email: string,
    password: string,
    rememberMe: boolean,
  ) => {
    try {
      dispatch(setCommonLoading(true, ALL_BUTTON_FLAGS.ON_LOGIN));
      const res = await _POST(LOGIN_API_URL, { email, password });
      if (res?.data?.data) {
        if (res?.data?.data?.isEmailVerified) {
          if (rememberMe) {
            localStorage.setItem("token", JSON.stringify(res.data.data.token));
            const ciphertext = CryptoJS.AES.encrypt(
              JSON.stringify({ email, password, rememberMe }),
              process.env.REACT_APP_ENV_CRYPTOJS_SECRET_KEY,
            ).toString();
            localStorage.setItem("credentials", ciphertext);
          } else {
            localStorage.setItem("token", JSON.stringify(res.data.data.token));
            if (localStorage.getItem("credentials")) {
              localStorage.removeItem("credentials");
            }
          }
          await setAuthState(decodeToken(res.data.data.token) as AuthData);
          dispatch(setSuccessErrorMessage("", "error", ALERT_CONSTANTS.LOGIN));

          dispatch(
            setSuccessErrorMessage(
              "You have successfully logged in !",
              "success",
              ALERT_CONSTANTS.LOGIN,
            ),
          );
          dispatch(setCommonLoading(false, ALL_BUTTON_FLAGS.ON_LOGIN));
          console.log("res data in onLogin", res.data.data);
          history.push(APPLICATION_ROUTES.DASHBOARD);
          return;
        }
        history.push(APPLICATION_ROUTES.OTP_VERIFICATION, {
          userData: res?.data?.data
            ? { ...res.data.data, _id: res.data.data._userId }
            : null,
          type: "verify",
        });
        dispatch(setCommonLoading(false, ALL_BUTTON_FLAGS.ON_LOGIN));
        dispatch(setSuccessErrorMessage("", "error", ALERT_CONSTANTS.LOGIN));
        return;
      }

      dispatch(
        setSuccessErrorMessage(
          "Invalid email or password",
          "error",
          ALERT_CONSTANTS.LOGIN,
        ),
      );
      dispatch(setCommonLoading(false, ALL_BUTTON_FLAGS.ON_LOGIN));
    } catch (error) {
      dispatch(
        setSuccessErrorMessage(
          "Error occurred on login",
          "error",
          ALERT_CONSTANTS.LOGIN,
        ),
      );
      dispatch(setCommonLoading(false, ALL_BUTTON_FLAGS.ON_LOGIN));
    }
  };

  const onSignUp = async (signUpData: SignupType) => {
    try {
      dispatch(setCommonLoading(true, ALL_BUTTON_FLAGS.ON_REGISTER));
      const res = await _POST(SIGNUP_SEND_VERIFY_OTP_API_URL, {
        ...signUpData,
        role: APP_ROLE.ADMIN,
      });
      console.log("res from signup", res);
      dispatch(setCommonLoading(false, ALL_BUTTON_FLAGS.ON_REGISTER));
      if (res.data && res.data.code === 200 && res.data.status) {
        dispatch(setSuccessErrorMessage("", "error", ALERT_CONSTANTS.SIGN_UP));
        localStorage.setItem("OTP_TOKEN", res.data.data.otpToken);
        if (res.data.data.userPlan === USER_PLANS.FREE) {
          return history.push(APPLICATION_ROUTES.OTP_VERIFICATION, {
            type: "verify",
          });
        } else {
          return history.push(APPLICATION_ROUTES.PAYMENT);
        }
      } else {
        console.log("res on else block", res.code);
        return dispatch(
          setSuccessErrorMessage(
            res.data.message,
            "error",
            ALERT_CONSTANTS.SIGN_UP,
          ),
        );
      }
    } catch (error) {
      console.log("error message in opt", error);
      dispatch(
        setSuccessErrorMessage(
          "Something went wrong. Please try again!",
          "error",
          ALERT_CONSTANTS.SIGN_UP,
        ),
      );

      dispatch(setCommonLoading(false, ALL_BUTTON_FLAGS.ON_REGISTER));
    }
  };

  const signUpAfterPayment = async (signUpData: any , callback?:any) => {
    try {
      dispatch(setCommonLoading(true, ALL_BUTTON_FLAGS.ON_REGISTER));
      const res = await _POST(SIGNUP_AFTER_PAYMENT, {
        ...signUpData,
        role: APP_ROLE.ADMIN,
      });
      console.log("res from signup", res);
      dispatch(setCommonLoading(false, ALL_BUTTON_FLAGS.ON_REGISTER));
      if (res.data && res.data.code === 200 && res.data.status) {
        console.log("in condition signup after payment", res.data.data);
        if (res?.data?.data?.token) {
          await setAuthState(decodeToken(res.data.data.token) as AuthData);
          localStorage.setItem("token", JSON.stringify(res.data.data.token));
        }
        localStorage.setItem("OTP_TOKEN", res.data.data.otpToken);
        callback({status:res.data.status,transactionId :res?.data?.data?.paymentDetails.transaction_id})
        // history.push(
        //   `${APPLICATION_ROUTES.PAYMENT_SUCCESSFUL}?transaction=${res?.data?.data?.paymentDetails.transaction_id}`,
        // );
      } else {

         dispatch(
          setSuccessErrorMessage(
            res.data.message,
            "error",
            ALERT_CONSTANTS.SIGN_UP,
          ),
        );
        callback(false)

      }
    } catch (error) {
      console.log("error", error);
      dispatch(
        setSuccessErrorMessage(
          "Something went wrong. Please try again!",
          "error",
          ALERT_CONSTANTS.SIGN_UP,
        ),
      );

      dispatch(setCommonLoading(false, ALL_BUTTON_FLAGS.ON_REGISTER));
      callback(false)

    }
  };
  const verifyEmailOtp = (data: any, history: any) => async (dispatch: any) => {
    try {
      setLoading(true);
      const res = await _POST(VERIFY_OTP_API_URL, data);
      setLoading(false);
      console.log("res in verify Email otp", res.data.data);
      if (res && res.data && res.data.code === 200 && res.data.status) {
        if (
          (res &&
            res.data &&
            res.data.data &&
            res.data.data.userPlan === "Free") ||
          res.data.data.userPlan === "Standard"
        ) {
          localStorage.clear();
          await setAuthState(decodeToken(res.data.data.token) as AuthData);
          localStorage.setItem("token", JSON.stringify(res.data.data.token));
          return history.push(APPLICATION_ROUTES.WELCOMESCREEN);
        } else if (
          res &&
          res.data &&
          res.data.data &&
          res.data.data.userPlan === "Standard"
        ) {
          // localStorage.clear();
          return history.push(APPLICATION_ROUTES.PAYMENT);
        } else {
          //redirect to Payment page
          dispatch(
            setSuccessErrorMessage(
              "Invalide OTP. Please try again.",
              "error",
              ALERT_CONSTANTS.OTP_VERIFICATION,
            ),
          );
        }
      } else {
        dispatch(
          setSuccessErrorMessage(
            "Something went wrong! Please try again",
            "error",
            ALERT_CONSTANTS.OTP_VERIFICATION,
          ),
        );
      }
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      // res?.data && callback(res.data?.data || null);
    } catch (error) {
      setLoading(false);
      console.log("error", error);
    }
  };
  const onForgotPassword = async (email: string) => {
    try {
      dispatch(setCommonLoading(true, ALL_BUTTON_FLAGS.ON_FORGOT_PASSWORD));
      const res = await _POST(FORGOT_PASSWORD_API_URL, { email });

      if (res.data.status) {
        await _POST(SEND_RESET_PASS_EMAIL_API_URL, {
          ...res.data.data,
        });
        dispatch(
          setSuccessErrorMessage(
            "Email sent successfully for reseting password",
            "success",
            ALERT_CONSTANTS.FORGOT_PASSWORD,
          ),
        );
        history.push(APPLICATION_ROUTES.FORGOT_PASSWORD_HELP);
        dispatch(setCommonLoading(false, ALL_BUTTON_FLAGS.ON_FORGOT_PASSWORD));
        dispatch(
          setSuccessErrorMessage("", "error", ALERT_CONSTANTS.FORGOT_PASSWORD),
        );
        return;
      }
      dispatch(
        setSuccessErrorMessage(
          "No account found with this email",
          "error",
          ALERT_CONSTANTS.FORGOT_PASSWORD,
        ),
      );

      dispatch(setCommonLoading(false, ALL_BUTTON_FLAGS.ON_FORGOT_PASSWORD));
    } catch (error) {
      dispatch(
        setSuccessErrorMessage(
          "Something went wrong. Please try again later.",
          "error",
          ALERT_CONSTANTS.FORGOT_PASSWORD,
        ),
      );
      dispatch(setCommonLoading(false, ALL_BUTTON_FLAGS.ON_FORGOT_PASSWORD));
    }
  };

  const onResetPassword = async (
    resetPasswordData: ResetPasswordType,
    userData?: any,
  ) => {
    try {
      dispatch(setCommonLoading(true, ALL_BUTTON_FLAGS.ON_RESET_PASSWORD));
      const res = await _POST(RESET_PASSWORD_API_URL, {
        ...resetPasswordData,
        ...userData,
      });

      dispatch(setCommonLoading(false, ALL_BUTTON_FLAGS.ON_RESET_PASSWORD));
      if (res.data.status) {
        toast.success("Password has been reset successfully");
        dispatch(
          setSuccessErrorMessage(
            "Password has been reset successfully",
            "success",
            ALERT_CONSTANTS.RESET_PASSWORD,
          ),
        );
        dispatch(
          setSuccessErrorMessage("", "error", ALERT_CONSTANTS.RESET_PASSWORD),
        );
        history.push(APPLICATION_ROUTES.LOGIN);

        return;
      }

      dispatch(
        setSuccessErrorMessage(
          "Error occurred on reset password",
          "error",
          ALERT_CONSTANTS.RESET_PASSWORD,
        ),
      );
    } catch (error) {
      dispatch(
        setSuccessErrorMessage(
          "Error occurred on reset password",
          "error",
          ALERT_CONSTANTS.RESET_PASSWORD,
        ),
      );

      dispatch(setCommonLoading(false, ALL_BUTTON_FLAGS.ON_RESET_PASSWORD));
    }
  };

  const onLogout = async () => {
    // setRemember(true);
    dispatch({
      type: "USER_LOGOUT",
      payload: null,
    });
    await localStorage.removeItem("token");
    await localStorage.removeItem("persist:root");
    await sessionStorage.removeItem("token");
    await setAuthState(null);
    setUserPlan(null);
    // await setTimeout(async () => {
    //   await history.push("/");
    // }, 500);
    await history.push("/login");
  };

  const onGoogleLogin = async ({idToken, userPlan, type}: any, callback: any) => {
    try {
      console.log("onGoogleLogin", idToken);
      const res = await _POST(GOOGLE_LOGIN_URL, { idToken, userPlan });
      // console.log("onGoogleLogin res>> ", res);
      if (res?.data?.data && res.data.data.token) {
        localStorage.setItem("OTP_TOKEN", JSON.stringify(res.data.data.token));
        // await setAuthState(decodeToken(res.data.data.token) as AuthData);

     
        if(res.data.data.existingCustomer){
           // if existing customer
          localStorage.setItem("token", JSON.stringify(res.data.data.token));
          if (localStorage.getItem("credentials")) {
            localStorage.removeItem("credentials");
          }
          await setAuthState(decodeToken(res.data.data.token) as AuthData);
          // const decodedToken = decodeToken(res.data.data.token)
          // console.log("decodedToken ongooglelogin", decodedToken)
          dispatch(setSuccessErrorMessage("", "error", ALERT_CONSTANTS.LOGIN));

          dispatch(
            setSuccessErrorMessage(
              "You have successfully logged in !",
              "success",
              ALERT_CONSTANTS.LOGIN,
            ),
          );
          dispatch(setCommonLoading(false, ALL_BUTTON_FLAGS.ON_LOGIN));
          history.push(APPLICATION_ROUTES.DASHBOARD);
        return
        }
        else{
       // if new customer
       if(type !="signUp")
        dispatch(setSuccessErrorMessage("This account is not registered. Please signup first!", "error", ALERT_CONSTANTS.LOGIN));
      else{
        const userData:any =  decodeToken(res.data.data.token);
        // console.log("userData ongooglelogin", userData)
     onSignUp({...userData, idToken , loginType:"google"})
      }
     
        }
      

        // dispatch(setSuccessErrorMessage("", "error", ALERT_CONSTANTS.LOGIN));
        // callback();
      } else {
        console.log("onGoogleLogin res>> ELSEEEEEEEEEEEEE ");
        dispatch(
          setSuccessErrorMessage(
            res.data.data.loginMsg,
            "error",
            ALERT_CONSTANTS.LOGIN,
          ),
        );
      }
    } catch (err) {
      dispatch(
        setSuccessErrorMessage(
          "Failed to login with google",
          "error",
          ALERT_CONSTANTS.LOGIN,
        ),
      );
    }
  };

  const checkLocalStorage = async () => {
    const token =
      (await localStorage.getItem("token")) ||
      (await sessionStorage.getItem("token"));
    if (token) {
      console.log("checkIfTokenExpired inside check local", token);
      await setAuthState(decodeToken(token) as AuthData);
      setLoading(false);
      return;
    }
    setLoading(false);
  };

  const checkIfTokenExpired = () => {
    console.log("checkIfTokenExpired 1 ", new Date().toDateString());
    const token =
      localStorage.getItem("token") || sessionStorage.getItem("token");

    if (token && token != "undefined") {
      const decodedJwt = decodeToken(token) as AuthData;
      console.log("checkIfTokenExpired 2", decodedJwt);

      if (decodedJwt.exp * 1000 < Date.now()) {
        console.log("checkIfTokenExpired 3");
        onLogout();
      }
    }
  };

  React.useEffect(() => {
    checkLocalStorage();
  }, []);

  React.useEffect(() => {
    console.log("checkIfTokenExpired authState change", authState);
    if (authState) {
      dispatch(GetUserPlan());
    }
  }, [authState, pathname]);

  // React.useEffect(() => {
  //   if (myPlan?.sessionId && authState) {
  //     setUserPlan({ ...authState, ...myPlan });
  //   }
  // }, [userSurveyStats, authState]);

  React.useEffect(() => {
    checkIfTokenExpired();
  }, [pathname]);

  return (
    <Provider
      value={{
        authData: authState,
        refreshToken,
        onLogin,
        onSignUp,
        signUpAfterPayment,
        onForgotPassword,
        authStateLoading: loading,
        setAuthState,
        onResetPassword,
        onLogout,
        setAuthError,
        authError,
        onGoogleLogin,
        authSuccess,
        setAuthSuccess,
        userPlan,
        verifyEmailOtp,
      }}
      {...props}
    />
  );
};

export { AuthContext, AuthProvider };
