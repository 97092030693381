import { CircularProgress, Grid } from "@material-ui/core";
import * as React from "react";
import { useFormik } from "formik";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import Header from "../../../parts/Header";
import envelopeImage from "../../../assets/images/envelope.png";
import Arrow2 from "../../../assets/images/Arrow2.png";
import {
  CenterContainer,
  EnvelopeImg,
  ForgotPasswordDetailText,
  ForgotPasswordField,
  ForgotPasswordText,
  ReturnToLoginButton,
  SendButton,
} from "./forgotPasswordStyled";
import { AuthContext } from "../../../context/authContext/authContext";
import { forgotPasswordSchema, forgotPasswordValues } from "./util";
import { ErrorText } from "../../../styledComponents/Text";
import {
  ALERT_CONSTANTS,
  ALL_BUTTON_FLAGS,
  APPLICATION_ROUTES,
} from "../../../utils/constant";
import { RootState } from "../../../redux/store";
import styles from "./styles.module.css";
import SuccessErrorMessageBox from "../../../components/SuccessErrorMessageBox/SuccessErrorMessageBox";

const ForgotPassword = () => {
  const authContext = React.useContext(AuthContext);
  const { onForgotPassword } = authContext;
  const { loading } = useSelector((state: RootState) => state.CommonReducer);
  const formik = useFormik({
    initialValues: forgotPasswordValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: (values) => {
      onForgotPassword(values.email);
    },
  });

  return (
    <>
      <Header />

      <Grid
        direction="row"
        container
        style={{ margin: "0" }}
        justifyContent="space-around"
        className={styles.forgotPassContainer}
      >
        <Grid item xs={10} md={4}>
          <div>
            <div className={styles.envelopeImage}>
              <EnvelopeImg
                src={envelopeImage}
                alt="envelople"
                width="10px"
                size="20px"
              />
            </div>
            <ForgotPasswordText>Forgot your password?</ForgotPasswordText>
            <br />

            <ForgotPasswordDetailText style={{ fontSize: "16px" }}>
              <p className={styles.detailText}>
                Enter your email address and we will send you instructions on
                how to change your password.
              </p>
            </ForgotPasswordDetailText>
          </div>

          <div className="section">
            <CenterContainer onSubmit={formik.handleSubmit}>
              <SuccessErrorMessageBox
                type="error"
                messageKey={ALERT_CONSTANTS.FORGOT_PASSWORD}
              />

              <div className={styles.emailField}>
                <ForgotPasswordField
                  variant="outlined"
                  type="email"
                  name="email"
                  placeholder="Enter your Email"
                  className={styles.emailText}
                  onChange={formik.handleChange}
                />
                {formik.touched.email && (
                  <ErrorText>{formik.errors.email}</ErrorText>
                )}
              </div>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Link to={APPLICATION_ROUTES.LOGIN}>
                  <ReturnToLoginButton>
                    <img
                      src={Arrow2}
                      alt="arrow"
                      width={1}
                      className={styles.arrow}
                    />
                    <div>
                      <span className={styles.returnText}>
                        Return to Login{" "}
                      </span>
                    </div>
                  </ReturnToLoginButton>
                </Link>
                <SendButton
                  disabled={
                    loading.state &&
                    loading.buttonFlag === ALL_BUTTON_FLAGS.ON_FORGOT_PASSWORD
                  }
                  type="submit"
                >
                  {loading.state &&
                  loading.buttonFlag === ALL_BUTTON_FLAGS.ON_FORGOT_PASSWORD ? (
                    <CircularProgress color="secondary" size="20px" />
                  ) : (
                    <p className={styles.sendText}>Send</p>
                  )}
                </SendButton>
              </div>
            </CenterContainer>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default ForgotPassword;
