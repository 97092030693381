/* eslint-disable no-console */
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { Provider as ReduxProvider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { persistor, STORE } from "./redux/store";
import Routing from "./routes/index";
import reportWebVitals from "./reportWebVitals";

console.log(
  "in Production env",
  process.env.NODE_ENV,
  typeof process.env.NODE_ENV,
);

/// //// hide console.log in production environment ///////
if (process.env.NODE_ENV === "production") {
  console.log = () => {};
  console.error = () => {};
  console.debug = () => {};
}

ReactDOM.render(
  <React.StrictMode>
    <ReduxProvider store={STORE}>
      <PersistGate loading={null} persistor={persistor}>
        <Router>
          <Routing />
        </Router>
      </PersistGate>
    </ReduxProvider>
  </React.StrictMode>,
  document.getElementById("root"),
);

reportWebVitals();
