import * as React from "react";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";

import DialogTitle from "@material-ui/core/DialogTitle";
import { Input } from "../../../components";
import { CenterContainerDiv } from "../../authentication/Signup/styled";
import styles from "./styles.module.css";

interface IALertBoxProps {
  open: boolean;
  handleClose?: React.MouseEventHandler<HTMLButtonElement>;
  handleDelete?: () => void;
}

const DeleteSurveyModal = ({
  open,
  handleClose,
  handleDelete,
}: IALertBoxProps) => {
  const [deleteField, setDeleteField] = React.useState<string>("");

  const handleDisable = () => {
    if (deleteField !== "DISABLE") return;
    handleDelete();
  };

  return (
    <div className="deleteSurveyContainer">
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={styles.deleteSurveyModal}
      >
        <DialogTitle id="alert-dialog-title" className={styles.alertTitle}>
          Disable this survey?
        </DialogTitle>
        <CenterContainerDiv
          style={{
            marginTop: 0,
            gap: 5,
            justifyContent: "flex-start",
            alignItems: "flex-start",
            padding: "0px 24px 24px",
          }}
        >
          <p>
            If you disable your survey, you won't be able to get survey
            responses any longer.
          </p>
          <p>If you're sure, please type "DISABLE" in the box below.</p>
          <Input
            type="text"
            name="delete"
            value={deleteField}
            placeholder="Enter DISABLE"
            handleChange={(e: any) => setDeleteField(e.target.value)}
          />
        </CenterContainerDiv>

        <DialogActions className={styles.deleteBtnContainer}>
          <Button
            className={"cancelButton"}
            onClick={handleClose}
            autoFocus
            style={{ fontWeight: "700" }}
          >
            Cancel
          </Button>
          <Button
            disabled={deleteField.toUpperCase() !== "DISABLE"}
            onClick={handleDisable}
            style={{ background: "#D04437", color: "#fff", fontWeight: "700" }}
          >
            Disable
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DeleteSurveyModal;
