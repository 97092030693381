import { _POST } from "../../../services/axios.method";
import { GET_SURVEY_QUESTIONS } from "../../../utils/apiConstant";
import {
  QUESTION_OPTIONS,
  QUESTION_TYPE,
  APPLICATION_ROUTES,
} from "../../../utils/constant";
import { generateAlphateFromIndex } from "../../../utils/helper";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const defaultCardAnswer = ({ index }: any) => {
  return {
    title: generateAlphateFromIndex(index),
    subTitle: "",
    imageUri: "",
  };
};
const { CARDS } = QUESTION_TYPE;
export const onChange = (
  e: any,
  selectedIndex: number,
  answerIndex?: any,
  state?: any,
  setState?: any,
) => {
  const { name, value }: any = e.target;

  const updatedState =
    state &&
    state.length > 0 &&
    state.map((formState: any, index: number) => {
      if (selectedIndex === index) {
        switch (name) {
          case "answer":
            Object.assign(formState, {
              [name]: Object.values({
                ...formState[name],
                [answerIndex]: value,
              }),
            });
            return formState;

          case "questionType":
            Object.assign(formState, {
              answer:
                value === CARDS
                  ? [
                      defaultCardAnswer({
                        index: answerIndex || 0,
                      }),
                    ]
                  : [""],
              [name]: value,
              questionTypeName: QUESTION_OPTIONS.find(
                (option) => option.value === value,
              ).name,
            });
            return formState;

          case "title":
          case "subTitle":
            Object.assign(formState, {
              answer: Object.values({
                ...formState.answer,
                [answerIndex]: {
                  ...formState.answer[answerIndex],
                  [name]: value,
                },
              }),
            });
            return formState;
          default:
            Object.assign(formState, { [name]: value });
            return formState;
        }
      } else {
        return formState;
      }
    });

  setState(updatedState);
};

export const filterQuestionSectionState = (questions: any) => {
  return (
    questions &&
    questions.filter((elem: any) => !(elem?.isWelcome || elem?.isClosing))
  );
};

export const filterWelcomeSectionState = (questions: any) => {
  return questions && questions.filter((elem: any) => elem?.isWelcome);
};

export const filterClosingSectionState = (questions: any) => {
  return questions && questions.filter((elem: any) => elem?.isClosing);
};

export const getUniqueQuestions = (questions: string[]) => {
  const uniqueQuestions = [];
  questions &&
    questions.length > 0 &&
    questions.forEach((question: string) => {
      if (!uniqueQuestions.includes(question)) {
        uniqueQuestions.push(question);
      }
    });

  return uniqueQuestions;
};

export const convertToIntentIds = async (questions: any) => {
  const intentToDelete =
    (await questions) && questions.filter((elem: any) => !elem?.isWelcome);

  const intentIds =
    (await intentToDelete) &&
    intentToDelete.map(function (item: any) {
      return item.intentId;
    });
  console.log("intentIDS", intentIds);
  return intentIds;
};

export const getIntentsOfSurvey = async (surveyId: any): Promise<any> => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await _POST(GET_SURVEY_QUESTIONS, { surveyId });

      resolve(res.data?.data?.questions || []);
    } catch (err) {
      console.log("err", err);
      reject(err);
    }
  });
};

export const breadcrumbsList = [
  {
    name: "Home",
    crumbLink: APPLICATION_ROUTES.HOME,
  },
  {
    name: "My Surveys",
    crumbLink: APPLICATION_ROUTES.DASHBOARD,
  },
  {
    name: "Create New Survey",
    crumbLink: APPLICATION_ROUTES.CREATE_NEW_SURVEY,
  },
];

export const breadcrumbsListEditSurvey = [
  {
    name: "Home",
    crumbLink: APPLICATION_ROUTES.HOME,
  },
  {
    name: "My Surveys",
    crumbLink: APPLICATION_ROUTES.DASHBOARD,
  },
];

export const scrollToTop = () => {
  window.history.scrollRestoration = "manual";
  window.scrollTo(0, 0);
};
