import {
  mainBorderColor,
  primaryColor,
  secondaryColor,
} from "./colorConstants";

const theme = {
  secondaryColor,
  primary: primaryColor,
  borderColor: mainBorderColor,
};
export default theme;
