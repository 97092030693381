export const QUESTION_TYPE = {
  MCQ: "MCQ",
  SUBJECTIVE: "SUBJECTIVE",
  CARDS: "CARDS",
};

export const MAX_QUESTION_LENGTH = 250;

export const QUESTION_OPTIONS = [
  {
    name: "Multiple Choice Question (MCQ)",
    value: QUESTION_TYPE.MCQ,
    label: "Multiple Choice Question (MCQ)",
  },
  {
    name: "MCQ with image Option",
    value: QUESTION_TYPE.CARDS,
    label: "MCQ with image Option",
  },
  {
    name: "Short Answer Question",
    value: QUESTION_TYPE.SUBJECTIVE,
    label: "Short Answer Question",
  },
];

export const APPLICATION_ROUTES = {
  LOGIN: "/login",
  SIGN_UP: "/signup",
  OTP_VERIFICATION: "/otpVerification",
  HOME: "/",
  PRICING: "/pricing",
  ALL_ANALYTICS_PAGE: "/allAnalytics",
  CREATE_NEW_SURVEY: "/createNewSurvey",
  ADD_QUESTIONIONARIES: "/addQuestionaries",
  EDIT_QUESTIONIONARIES: "/editQuestionaries",
  ACCOUNT: "/account",
  MANAGE_SURVEY: "/manageSurvey",
  DASHBOARD: "/dashboard",
  RESOURCES: "/resources",
  ABOUT_US: "/aboutUs",
  FORGET_PASSWORD: "/forgotPassword",
  ERROR_PAGE: "/error",
  SURVEY_ANALYTICS: "/surveyAnalytics",
  PAYMENT: "/payment",
  VIEW_SURVEY: "/viewSurvey",
  RESET_PASSWORD: "/reset-password",
  ANALYTICS: "/analytics",
  SHARE_SURVEY: "/shareCode",
  PAYMENT_SUCCESSFUL: "/result",
  PAYMENT_SUMMARY: "/payment/summary",
  CONTACT: "/contact",
  WELCOMESCREEN: "/welcome",
  CONTINUE_PAYMENT: "/continuePayment",
  FORGOT_PASSWORD_HELP: "/forgotpasswordhelp",
  MAX_PLAN_LIMIT: "/maxplanlimit",
};

export const APP_ROLE = {
  ALL: "all",
  ADMIN: "admin",
  SUPERADMIN: "superAdmin",
};

export const USER_PLANS = {
  FREE: "Free",
  STANDARD: "Standard",
  PREMIUM: "Premium",
};

export const ALL_BUTTON_FLAGS = {
  SAVE_PROCEES: "SAVE_PROCEES",
  SAVE_SURVEY_QUESTIONS: "SAVE_SURVEY_QUESTIONS",
  SEARCH_SURVEY_HEADER: "SEARCH_SURVEY_HEADER",
  CREATE_SURVEY_BUTTON: "CREATE_SURVEY_BUTTON",
  CREATE_DIALOG_FLOW_INTENT_BUTTON: "CREATE_DIALOG_FLOW_INTENT_BUTTON",
  ON_LOGIN: "ON_LOGIN",
  ON_REGISTER: "ON_REGISTER",
  ON_FORGOT_PASSWORD: "ON_FORGOT_PASSWORD",
  ON_RESET_PASSWORD: "ON_RESET_PASSWORD",
  ON_OTP_VERIFY: "ON_OTP_VERIFY",
  ON_CONTACT_US: "ON_CONTACT_US",
  UPDATE_SURVEY: "UPDATE_SURVEY",
  GET_SURVEY_DETAILS: "GET_SURVEY_DETAILS",
  GET_SURVEY_TRANSCRIPT: "GET_SURVEY_TRANSCRIPT",
  GET_MOST_RESPONDED_SURVEY: "GET_MOST_RESPONDED_SURVEY",
  GET_SURVEYS_BY_USER: "GET_SURVEYS_BY_USER",
  GET_PUBLISHED_SURVEYS_BY_USER: "GET_PUBLISHED_SURVEYS_BY_USER",
  GET_DISABLED_SURVEYS_BY_USER: "GET_DISABLED_SURVEYS_BY_USER",
  GET_DASHBOARD_DATA: "GET_DASHBOARD_DATA",
  DELETE_SURVEY: "DELETE_SURVEY",
  DELETE_SURVEY_QUESTION: "DELETE_SURVEY_QUESTION",
  DELETE_EXISTING_SURVEY_QUESTION: "DELETE_EXISTING_SURVEY_QUESTION",
  GET_QUESTION_ANALYTICS_DATA: "GET_QUESTION_ANALYTICS_DATA",
  CREATE_CHECKOUT_SESSION: "CREATE_CHECKOUT_SESSION",
  GET_USER_SURVEYS: "GET_USER_SURVEYS",
  GET_USER_PAYMENT_STATUS: "GET_USER_PAYMENT_STATUS",
  ALL_BUTTON_FLAGS: "ALL_BUTTON_FLAGS",
};

export const ALERT_CONSTANTS = {
  QUESTION_OPTION: "QUESTION_OPTION",
  QUESTION_IMAGE: "QUESTION_IMAGE",
  LOGO_IMAGE: "LOGO_IMAGE",

  LOGIN: "LOGIN",
  SIGN_UP: "SIGN_UP",
  FORGOT_PASSWORD: "FORGOT_PASSWORD",
  RESET_PASSWORD: "RESET_PASSWORD",
  OTP_VERIFICATION: "OTP_VERIFICATION",
  PROFILE: "PROFILE",
  QUESTION: "QUESTION",
  ADD_QUESTIONAIRE: "ADD_QUESTIONAIRE",
  CONTACT_US: "CONTACT_US",
  CODE_COPY: "CODE_COPY",
  LINK_COPY: "LINK_COPY",
  CREATE_NEW_SURVEY: "CREATE_NEW_SURVEY",
  SECRET_LOGIN: "SECRET_LOGIN",
};

export const TOOLTIP_MESSAGES = {
  YOUR_LOGO: "You can upload an image for your survey assistant.",
  QUESTIONS: "You can provide the survey questions.",
  ADD_OPTIONS: "You can add more options for this question.",
  QUESTION_TYPE: "You can choose different question type.",
  WELCOME_MESSAGE:
    "Please provide default greeting message. This message will be displayed when user interacts with the assistant",
  CLOSING_MESSAGE: "Please provide closing/thank you message.",
  ADD_IMAGE: "You can add more image options for this question.",
  ADD_IMAGE_BOX: "You can upload an image for this question's option.",
  ADD_IMAGE_TITLE: "You can enter a title for this question's option.",
  ADD_ANSWER: "You can enter the answer for this question.",
  UPLOAD_AN_IMAGE: "You can upload an image for this question.",
};

export const STATUS_CODES = {
  OK: 200,
  BAD_REQUEST: 400,
  UN_AUTHORISED: 403,
  NOT_FOUND: 404,
  INTERNAL_ERROR: 500,
};
