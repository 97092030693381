import * as React from "react";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import { CenterContainerDiv } from "../../../authentication/Signup/styled";
import "./style.css";
import sendImg from "../../../../assets/images/sendGif.gif";
import alertImg from "../../../../assets/images/alert.gif";
import tailImg from "../../../../assets/images/sendTail.png";
import { getIntentsOfSurvey } from "../utils";
import CircularLoader from "../../../../components/Loader";
import { APPLICATION_ROUTES, QUESTION_TYPE } from "../../../../utils/constant";
import {
  saveSurveyQuestions,
  setErrorOnSections,
} from "../../../../redux/action-creator/dialogflow.action";

interface IALertBoxProps {
  open: boolean;
  handleClose?: React.MouseEventHandler<HTMLButtonElement>;
  handlePublish?: React.MouseEventHandler<HTMLButtonElement>;
  surveyId: any;
}

const PublishSurveyModal = ({
  open,
  handleClose,
  handlePublish,
  surveyId,
}: IALertBoxProps) => {
  const [loading, setLoading] = React.useState(false);

  const [surveyQuestions, setSurveyQuestions] = React.useState([]);
  const dispatch = useDispatch();
  const [isValidated, setIsValidated] = React.useState(false);
  const history = useHistory();
  const getSurveyQuestions = async () => {
    setLoading(true);
    const questions = await getIntentsOfSurvey(surveyId);
    setSurveyQuestions(questions);
    setLoading(false);
  };

  const validateQuestions = () => {
    let isValidated = true;
    const errorOnSections = [];
    for (
      let currentIndex = 0;
      currentIndex < surveyQuestions.length;
      currentIndex++
    ) {
      console.log(
        "surveyQuestions[currentIndex]",
        surveyQuestions[currentIndex],
      );
      if (surveyQuestions[currentIndex]?.isWelcome) {
        if (
          surveyQuestions[currentIndex]?.welcomeMessage.length > 0 &&
          surveyQuestions[currentIndex]?.welcomeMessage[0] === ""
        ) {
          errorOnSections.push("1");
          isValidated = false;
          continue;
        }
      } else if (
        surveyQuestions[currentIndex].question.trim() === "" &&
        surveyQuestions[currentIndex]?.isClosing
      ) {
        errorOnSections.push("3");
        isValidated = false;
        continue;
      } else if (surveyQuestions[currentIndex].question.trim() === "") {
        errorOnSections.push("2");
        isValidated = false;
        continue;
      } else if (
        surveyQuestions[currentIndex].questionType === QUESTION_TYPE.SUBJECTIVE
      ) {
        // if (
        //   surveyQuestions[currentIndex].answer[0] &&
        //   surveyQuestions[currentIndex].answer[0].length > 20
        // ) {
        //   isValidated = false;
        //   continue;
        // }
        if (
          surveyQuestions[currentIndex].isUploadImageWithMCQ &&
          surveyQuestions[currentIndex].imageUri === ""
        ) {
          errorOnSections.push("2");
          isValidated = false;
          continue;
        }
      } else if (
        surveyQuestions[currentIndex].questionType === QUESTION_TYPE.MCQ
      ) {
        if (surveyQuestions[currentIndex].answer.includes("")) {
          errorOnSections.push("2");
          isValidated = false;
          continue;
        }
        if (
          surveyQuestions[currentIndex].answer.find(
            (data: string) => data.length > 20,
          )
        ) {
          errorOnSections.push("2");
          isValidated = false;
          continue;
        }
        if (
          surveyQuestions[currentIndex].isUploadImageWithMCQ &&
          surveyQuestions[currentIndex].imageUri === ""
        ) {
          errorOnSections.push("2");
          isValidated = false;
          continue;
        }
      } else if (
        surveyQuestions[currentIndex].questionType === QUESTION_TYPE.CARDS
      ) {
        if (
          surveyQuestions[currentIndex].answer.filter(
            (data: any) => data.title === "" || data.imageUri === "",
          ).length > 0
        ) {
          errorOnSections.push("2");
          isValidated = false;
          continue;
        }
      }
    }
    return { isValidated, errorOnSections };
  };

  const validateSurveyQuestions = () => {
    const { isValidated: validated, errorOnSections } = validateQuestions();

    dispatch(setErrorOnSections(errorOnSections));
    if (validated) {
      setIsValidated(true);
    }
  };

  React.useEffect(() => {
    getSurveyQuestions();
  }, []);

  React.useEffect(() => {
    if (surveyQuestions.length > 0) {
      validateSurveyQuestions();
    }
  }, [surveyQuestions]);

  const onPublish = (e: any) => {
    dispatch(
      saveSurveyQuestions(
        {
          surveyId,
          payloadContent: surveyQuestions,
          isEdit: true,
        },
        false,
        () => handlePublish(e),
      ),
    );
  };

  return (
    <div className="deleteSurveyContainer">
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="deleteSurveyModal"
      >
        {loading ? (
          <CircularLoader />
        ) : (
          <>
            {isValidated ? (
              <div className="sendImgContainer">
                <img src={sendImg} alt="Send" className="sendGif" />
                <img src={tailImg} alt="Send" className="tailImg" />
              </div>
            ) : (
              <div style={{ textAlign: "center" }}>
                <img src={alertImg} alt="Send" className="sendGif" />
              </div>
            )}
            {isValidated ? (
              <DialogTitle id="alert-dialog-title-question">
                Are you sure you want to publish this survey ?
              </DialogTitle>
            ) : (
              <DialogTitle id="alert-dialog-title-question">
                Please complete your survey !
              </DialogTitle>
            )}
            {isValidated && (
              <CenterContainerDiv
                style={{
                  marginTop: 0,
                  gap: 0,
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "0px 24px 24px",
                }}
              >
                <p>You won't be able to edit this survey again.</p>
                <p>If you are sure, please click on "Publish" button.</p>
              </CenterContainerDiv>
            )}

            <DialogActions className="deleteBtnBlock">
              <Button
                onClick={handleClose}
                autoFocus
                style={{ fontWeight: "700" }}
                className={"cancelButton"}
              >
                Cancel
              </Button>
              {isValidated ? (
                <Button
                  onClick={onPublish}
                  style={{
                    background: "#D04437",
                    color: "#fff",
                    fontWeight: "700",
                  }}
                >
                  Publish
                </Button>
              ) : (
                <Button
                  onClick={(e) => {
                    history.push(
                      `${APPLICATION_ROUTES.EDIT_QUESTIONIONARIES}?surveyId=${surveyId}&section=1`,
                    );
                    handleClose(e);
                  }}
                  style={{
                    background: "#D04437",
                    color: "#fff",
                    fontWeight: "700",
                    padding: "4px 8px",
                  }}
                >
                  Edit
                </Button>
              )}
            </DialogActions>
          </>
        )}
      </Dialog>
    </div>
  );
};

export default PublishSurveyModal;
