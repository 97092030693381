import * as React from "react";
import styles from "./styles.module.css";
import Header from "../../../parts/Header";
import img from "../../../assets/images/maxPlanLimit.png";
import { BASE_URL_FOR_NEXT_WEB } from "../../../utils/apiConstant";

const MaxPlanLimit: React.FC = () => {
  return (
    <>
      <Header />
      <div className={styles.maxPlanLimitContainer}>
        <div className={styles.maxPlanPageInner}>
          <div style={{ textAlign: "center" }}>
            <img src={img} alt="Max Plan Limit" />
          </div>
          <h2>You have reached the maximum limit of your plan!</h2>
          <p>Please upgrade now! </p>
          <div style={{ textAlign: "center" }}>
            <button
              onClick={() =>
                window.open(`${BASE_URL_FOR_NEXT_WEB}/planPricing`, "_self")
              }
            >
              Upgrade Plan
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default MaxPlanLimit;
