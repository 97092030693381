import * as React from "react";
import Header from "../../../parts/Header";
import styles from "./style.module.css";
import forgotPasswordHelpGif from "../../../assets/images/forgotHelp.gif";
import { Link } from "react-router-dom";
import { APPLICATION_ROUTES } from "../../../utils/constant";

const ForgotPasswordHelp: React.FC = () => {
  return (
    <>
      <Header />
      <div className={`classContainer ${styles.passwordHelpContainer}`}>
        <div style={{ textAlign: "center" }}>
          <img src={forgotPasswordHelpGif} alt="Forgot Password Help" />
        </div>
        <h2 style={{ textAlign: "center" }}>Help is on the way!</h2>
        <p>
          Keep an eye out on your inbox-we just emailed you a link to reset your
          password, if you don't receive anything, first check your spam folder
          and then try again.
        </p>
        <Link to={APPLICATION_ROUTES.FORGET_PASSWORD} className="resendEmail">
          Resend Email
        </Link>
      </div>
    </>
  );
};
export default ForgotPasswordHelp;
