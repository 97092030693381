import { Button } from "@material-ui/core";
import styled from "styled-components";
import {
  mainButtonColor,
  normalTextColor,
} from "../../../utils/colorConstants";

export const SignUpText = styled.p`
  font-style: normal;
  font-weight: bold;
  text-align: center;
  font-size: 40px;
  color: ${mainButtonColor};
`;

export const CenterContainerDiv = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 80px;
  gap: 22px;
`;

export const SignUpDetailText = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 30px;
  text-align: center;
  color: ${normalTextColor};
`;

export const HighlightDetailText = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 30px;
  text-align: center;
  color: ${normalTextColor};
  height: 100%;
`;

export const GoogleButton = styled(Button)`
  background-color: #1976d2 !important;
  border-radius: 0px !important;
  font-family: Roboto !important;
  font-size: 16px !important;
  line-height: 27px !important;
  padding: 5px 45px !important;
  text-align: center !important;
  color: #fff !important;
`;

export const GoogleButtonDiv = styled.div`
  margin: 10px auto;
  width: 100%;
`;
