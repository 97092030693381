import { Button, TextField } from "@material-ui/core";
import styled from "styled-components";
import {
  headingColor,
  linkColor,
  secondaryColor,
  tertiaryButtonColor,
} from "../../../utils/colorConstants";

export const CenterContainer = styled.form`
  display: flex;
  width: 100%;
  // justify-content: center;
  // align-items: center;
  flex-direction: column;
  margin-top: 20px;
  gap: 22px;
  margin-bottom: 200px;
`;

export const EnvelopeImg: any = styled.img`
  width: 100px;
  margin-bottom: 25px;
`;

export const ForgotPasswordText = styled.p`
  font-family: Raleway;
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 30px;
  text-align: center;
  color: ${headingColor};
  margin: 0;
`;

export const ForgotPasswordDetailText = styled.p``;

export const ForgotPasswordField = styled(TextField)`
  padding: 0px;

  border-radius: 6px !important;
  & .MuiOutlinedInput-notchedOutline {
  }
`;

export const SendButton = styled(Button)`
  background-color: ${tertiaryButtonColor} !important;
  border-radius: 6px !important;
  text-transform: capitalize !important;
  color: ${secondaryColor} !important;
  min-width: 120px !important;
`;

export const ReturnToLoginButton = styled(Button)`
  font-family: Raleway;
  font-weight: 500;
  font-size: 10px;
  padding: 0px !important;
  text-decoration-line: underline !important;
  text-transform: capitalize !important;
  color: ${linkColor} !important;
  & .MuiButton-label {
    gap: 10px !important;
  }
`;
