import styled from "styled-components";

const Input: any = styled.input`
  width: 100%;
  background: #ffffff;
  border: 0.906097px solid #d1cdcd;
  box-sizing: border-box;
  border-radius: 3.62439px;
  height: 50px;
  margin-bottom: 20px;
  padding: 0px 20px;
  ::placeholder {
    color: #5a5a5a;
    font-weight: 500;
  }
`;

export default Input;
