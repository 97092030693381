import { ActionType } from "../types";

export const initialState: any = {
  surveyData: {},
  intentSurveyData: [],
  surveyList: [],
  disabledSurveyList: [],
  publishedSurveyList: [],
  surveySearchList: [],
  answerGenius: [],
  surveyQuestionSuggestions: null,
  surveyQuestionSuggestionsModal: null,
  surveyWelcomeSuggestions: null,
  surveyClosingSuggestions: null,
  isWelcomeSectionTriggered: false,
  welcomeIntentData: null,
  errorOnSections: [],
  dashboardData: {
    allSurveys: 0,
    inactiveSurveys: 0,
    disabledSurveys: 0,
  },
  messages: [],
};

// eslint-disable-next-line @typescript-eslint/default-param-last
export const dialogFlowReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case ActionType.UPDATE_DEFAULT_INTENT:
      return {
        ...state,
        welcomeIntentData: action.payload,
      };
    case ActionType.GET_QUESTION_SUGGESTIONS:
      return {
        ...state,
        surveyQuestionSuggestions: action.payload,
      };
    case ActionType.SET_ERROR_ON_SECTIONS:
      return {
        ...state,
        errorOnSections: action.payload,
      };
    case ActionType.GET_QUESTION_SUGGESTIONS_MODAL:
      return {
        ...state,
        surveyQuestionSuggestionsModal: action.payload,
      };
    case ActionType.GET_ANSWER_GENIUS:
      return {
        ...state,
        answerGenius: action.payload,
      };
    case ActionType.GET_WELCOME_SUGGESTIONS:
      return {
        ...state,
        surveyWelcomeSuggestions: action.payload,
      };
    case ActionType.GET_CLOSING_SUGGESTIONS:
      return {
        ...state,
        surveyClosingSuggestions: action.payload,
      };
    case ActionType.CREATE_SURVEY:
      return {
        ...state,
        surveyData: action.payload,
      };
    case ActionType.UPDATE_SURVEY:
      return {
        ...state,
        surveyData: {
          ...state.surveyData,
          title: action.payload.title,
          category: action.payload.surveyCategory,
          description: action.payload.description,
        },
      };
    case ActionType.GET_SURVEY:
      return {
        ...state,
        surveyData: action.payload,
      };

    case ActionType.GET_INTENTS_SURVEY:
      return {
        ...state,
        intentSurveyData: action?.payload || null,
      };

    case ActionType.GET_SURVEY_LIST:
      return {
        ...state,
        surveyList: action.payload,
      };
    case ActionType.GET_DISABLED_SURVEY_LIST:
      return {
        ...state,
        disabledSurveyList: action.payload,
      };
    case ActionType.GET_PUBLISHED_SURVEY_LIST:
      return {
        ...state,
        publishedSurveyList: action.payload,
      };
    case ActionType.SEARCH_SURVEY_HEADER:
      return {
        ...state,
        surveySearchList: action.payload,
      };
    case ActionType.GET_DASHBOARD_DATA:
      console.log("Action.payload from line 87", action.payload);
      return {
        ...state,
        dashboardData: action.payload,
      };
    case ActionType.WELCOME_SECTION_TRIGGERED:
      return {
        ...state,
        isWelcomeSectionTriggered: action.payload,
      };
    case ActionType.GET_ALL_CATEGORIES:
      return {
        ...state,
        categories: action.payload,
      };
    case ActionType.GET_MESSAGES:
      return {
        ...state,
        messages: action.payload,
      };
    default:
      return state;
  }
};
