import * as React from "react";
import { Grid } from "@material-ui/core";
import Header from "../../../parts/Header";
import errorImg from "../../../assets/images/404.png";
import { ErrorBox, ErrorHeading, ErrorImage, ErrorPara } from "./errorStyled";

const ErrorPage: React.FC = () => {
  return (
    <>
      <Header />
      <ErrorBox>
        <ErrorHeading>Page not found </ErrorHeading>
        <ErrorPara>Sorry the page you are searching is not found</ErrorPara>
        <Grid container justifyContent="center">
          <Grid item xs={5} md={3}>
            <ErrorImage src={errorImg} alt="errorImage" />
          </Grid>
        </Grid>
      </ErrorBox>
    </>
  );
};
export default ErrorPage;
