import styled from "styled-components";

const GradientDivider = styled.div`
  display: flex;
  width: 100%;
  height: 1px;
  padding: 10px;
  background: linear-gradient(
    90deg,
    #fbae23 0%,
    #f46313 46.9%,
    rgba(229, 34, 11, 0.68) 100%
  );
`;

export default GradientDivider;
