import * as Yup from "yup";

export const contactValues = {
  firstname: "",
  lastname: "",
  phone: "",
  email: "",
  message: "",
  businessName: "",
};
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const contactSchema = Yup.object({
  firstname: Yup.string()
    .matches(/^[a-zA-Z][a-zA-Z ]+[a-zA-Z]$/, "Only alphabets are allowed")
    .required("Firstname is a required field"),
  lastname: Yup.string()
    .matches(/^[a-zA-Z][a-zA-Z ]+[a-zA-Z]$/, "Only alphabets are allowed")
    .required("Lastname is a required field"),
  phone: Yup.string()
    .min(9, "Invalid Phone Number")
    .max(14, "Invalid Phone Number")
    .required("This is a required field"),
  email: Yup.string()
    .email("Invalid Email")
    .required("Email is a required field"),
  message: Yup.string()
    .trim()
    .min(1, "Message is a required field")
    .required("Message is a required field"),
});
