import { CircularProgress } from "@mui/material";
import * as React from "react";
import styles from "../styles.module.css";
import Search from "./search";
import SearchIconWrapper from "./searchIconWrapper";
import { useAutocomplete } from "@mui/base/AutocompleteUnstyled";
import { styled } from "@mui/material/styles";
import StyledInputBase from "./styledInputBase";
import { searchSurveysByUser } from "../../../redux/action-creator/dialogflow.action";
import { useDispatch, useSelector } from "react-redux";
import useDebounce from "../../../hooks/useDebounce";
import { RootState } from "../../../redux/store";
import { ActionType } from "../../../redux/types";
import { ALL_BUTTON_FLAGS, APPLICATION_ROUTES } from "../../../utils/constant";
import { useHistory } from "react-router";

import surveyChattyNew from "../../../assets/images/surveyChattyNew.png";

const Listbox = styled("ul")(({ theme }) => ({
  width: "100%",
  margin: 0,
  padding: 0,
  zIndex: 1,
  position: "absolute",
  listStyle: "none",
  backgroundColor: theme.palette.background.paper,
  overflowY: "auto",
  overflowX: "hidden",
  maxHeight: 200,
  border: "1px solid rgba(0,0,0,.25)",
  '& li[data-focus="true"]': {
    backgroundColor: "#4a8df6",
    color: "white",
    cursor: "pointer",
  },
  "& li:active": {
    backgroundColor: "#2977f5",
    color: "white",
  },
}));

const HeaderSearchBar = (props) => {
  const history = useHistory();
  const { surveySearchList } = useSelector(
    (state: RootState) => state.DialogFlowReducer,
  );
  const { loading } = useSelector((state: RootState) => state.CommonReducer);

  const [search, setSearch] = React.useState("");
  const onSearchClick = (id: any) => {
    history.push(`${APPLICATION_ROUTES.VIEW_SURVEY}?surveyId=${id}`);
  };
  const {
    getRootProps,
    getInputProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
  } = useAutocomplete({
    id: "use-autocomplete-demo",
    options: surveySearchList,
    getOptionLabel: (option: any) => option.title,
    onChange: (e, selectedValue) => {
      onSearchClick(selectedValue._id);
    },
    onInputChange: (e, inputValue) => setSearch(inputValue),
    inputValue: search,
    clearOnBlur: false,
  });
  const dispatch = useDispatch();

  const debouncedSearch = useDebounce(search, 700);

  const [insideSearch, setInsideSearch] = React.useState(false);

  console.log(
    "debouncedSearchdebouncedSearch",
    `${debouncedSearch}`.trim(),
    surveySearchList,
    groupedOptions,
  );

  React.useEffect(() => {
    if (`${debouncedSearch}`.trim() !== "") {
      dispatch(searchSurveysByUser(`${debouncedSearch}`.trim()));
      return;
    }
    dispatch({
      type: ActionType.SEARCH_SURVEY_HEADER,
      payload: [],
    });
  }, [debouncedSearch]);
  console.log("groupedOptions", groupedOptions);

  return (
    <Search
      sx={props.responsive}
      className={`${styles.searchBarBlock} ${props.className}`}
    >
      <SearchIconWrapper>
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14.8453 14.9996L11.5404 11.6888L14.8453 14.9996ZM13.3719 7.26407C13.3719 8.92488 12.7121 10.5177 11.5378 11.6921C10.3634 12.8664 8.77059 13.5262 7.10977 13.5262C5.44895 13.5262 3.85616 12.8664 2.68179 11.6921C1.50741 10.5177 0.847656 8.92488 0.847656 7.26407C0.847656 5.60325 1.50741 4.01046 2.68179 2.83608C3.85616 1.66171 5.44895 1.00195 7.10977 1.00195C8.77059 1.00195 10.3634 1.66171 11.5378 2.83608C12.7121 4.01046 13.3719 5.60325 13.3719 7.26407V7.26407Z"
            stroke="#121212"
            strokeLinecap="round"
          />
        </svg>
      </SearchIconWrapper>

      <div>
        <div {...getRootProps()}>
          <StyledInputBase
            onClick={() => setInsideSearch(true)}
            onMouseLeave={() => setInsideSearch(false)}
            value={search}
            fullWidth
            {...(getInputProps() as any)}
            type="search"
            placeholder="Search"
          />
        </div>

        <Listbox
          {...getListboxProps()}
          className={styles.searchResultContainer}
        >
          {loading.state &&
          loading.buttonFlag === ALL_BUTTON_FLAGS.SEARCH_SURVEY_HEADER ? (
            <CircularProgress />
          ) : groupedOptions.length > 0 ? (
            groupedOptions.map((option: any, index: number) => {
              return (
                <li
                  style={{ padding: 5, cursor: "pointer" }}
                  {...getOptionProps({ option, index })}
                >
                  {option.title}
                  {option.surveyLogo ? (
                    <img src={option.surveyLogo} alt="Survey Logo" />
                  ) : (
                    <img src={surveyChattyNew} alt="Survey Logo" />
                  )}
                </li>
              );
            })
          ) : (
            insideSearch &&
            `${debouncedSearch}`.trim() !== "" && (
              <li style={{ justifyContent: "center" }}> No Results Found</li>
            )
          )}
        </Listbox>
      </div>
    </Search>
  );
};

export default HeaderSearchBar;
