import { Tooltip, withStyles } from "@material-ui/core";

export const CustomStyledTooltip = withStyles(() => ({
  tooltip: {
    padding: 12,
    fontSize: 14,
    fontFamily: "Mulish",
    backgroundColor: "#000",
    color: "#fff",
  },
  popper: {
    color: "#FFA500",
  },
}))(Tooltip);
