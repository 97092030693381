import { ActionType } from "../types";

export const initialState: any = {
  surveyData: {
    data: {
      options: {
        labels: ["positive", "negative", "neutral"],
      },
      series: [0, 0, 0],
    },
  },
  surveyEmotionData: {
    data: {
      options: {
        labels: ["sadness", "joy", "fear", "anger", "disgust"],
      },
      series: [0, 0, 0, 0, 0],
    },
  },
  visitorsData: {
    users: [
      {
        _id: "2022-01-17",
        visitors: 0,
      },
    ],
    totalCount: 0,
  },
  conversationData: [],
  _surveyTranscriptData: [],
  _userSpecificTranscriptData: [],
  mostRespondedSurveys: [],
  responseAnalyticsData: [
    {
      question: "Was this your first time at the restaurant?",
      questionType: "MCQ",
      questionTypeName: "Multiple choice text",
      answer: ["Yes", "No"],
      welcomeMessage: [],
      isQuestion: true,
      intentId:
        "projects/surveyassistant-dnrd/agent/intents/3b337af5-fe42-4788-9bec-955253ca0a6c",
      intentIdNext:
        "projects/surveyassistant-dnrd/agent/intents/5150a7b5-4f6d-490a-98ef-f4d792c43410",
      questionNo: "2",
    },
    {
      question: "How did you hear about us?",
      questionType: "CARDS",
      questionTypeName: "Multiple choice images",
      answer: [
        {
          title: "Social Media",
          subTitle: "",
          imageUri:
            "https://storage.googleapis.com/surveybucketdev/survey-product-o9qj/social media.jpg",
        },
        {
          title: "Friends & Family",
          subTitle: "",
          imageUri:
            "https://storage.googleapis.com/surveybucketdev/survey-product-o9qj/friends and family.png",
        },
      ],
      welcomeMessage: [],
      isQuestion: true,
      intentId:
        "projects/surveyassistant-dnrd/agent/intents/3b337af5-fe42-4788-9bec-955253ca0a6c",
      intentIdNext:
        "projects/surveyassistant-dnrd/agent/intents/5150a7b5-4f6d-490a-98ef-f4d792c43410",
      questionNo: "2",
    },
  ],
  questionAnalyticsData: [
    {
      _id: [""],
      count: 0,
    },
  ],
};

// eslint-disable-next-line @typescript-eslint/default-param-last
export const analyticsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case ActionType.SURVEY_ANALYTICS_SENTIMENTS:
      return {
        ...state,
        surveyData: action.payload,
      };
    case ActionType.GET_MOST_RESPONDED_SURVEY:
      return {
        ...state,
        mostRespondedSurveys: action.payload,
      };
    case ActionType.SURVEY_ANALYTICS_EMOTIONS:
      return {
        ...state,
        surveyEmotionData: action.payload,
      };
    case ActionType.GET_VISITORS_DATA:
      return {
        ...state,
        visitorsData: action.payload,
      };
    case ActionType.GET_SURVEY_TRANSCRIPT:
      return {
        ...state,
        _surveyTranscriptData: action.payload,
      };
    case ActionType.GET_USER_SPRECIFIC_TRANSCRIPT:
      return {
        ...state,
        _userSpecificTranscriptData: action.payload,
      };
    case ActionType.GET_CONVERSATION_ANALYTICS_DATA:
      return {
        ...state,
        conversationData: action.payload,
      };
    case ActionType.GET_RESPONSE_ANALYTICS_DATA:
      return {
        ...state,
        responseAnalyticsData: action.payload,
      };
    case ActionType.GET_QUESTION_ANALYTICS_DATA:
      return {
        ...state,
        questionAnalyticsData: action.payload,
      };
    default:
      return state;
  }
};
