import styled from "styled-components";

const Select1 = styled.select`
  color: black;
  padding: 0.6em;
  background-color: white;
  margin: 0.25em;
  border-radius: 30px;
`;

export default Select1;
