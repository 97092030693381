import { AppBar, Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import styled from "styled-components";
import {
  menuFontColor,
  primaryOrangeGradient,
  secondaryButtonColor,
  secondaryColor,
} from "../../utils/colorConstants";

type CustomAppBarType = {
  scroll_on: boolean;
};
export const CustomAppBar = styled(AppBar)<CustomAppBarType>`
  background-color: transparent !important;
  ${(props) => props.scroll_on && `background:${primaryOrangeGradient}`}
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const MobileMenuContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 15px 45px;
`;

export const HeaderLogo = styled.img`
  width: 210px;
`;

type MenuLinkType = {
  scroll_on: boolean;
  active?: boolean;
};
export const MenuLink = styled.span<MenuLinkType>`
  font-style: normal;
  position: relative;
  font-weight: 500;
  font-size: 1em;
  line-height: 30px;
  color: ${(props) => (props.scroll_on ? secondaryColor : menuFontColor)};
  &:after {
    content: "";
    position: absolute;
    width: 100%;
    transform: ${(props) => (props.active ? "scaleX(1)" : "scaleX(0)")};
    height: 2px;
    bottom: -12px;
    left: 0;
    background-color: ${(props) =>
      props.scroll_on ? secondaryColor : menuFontColor};
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }
  &:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }
`;

export const MenuVertiBar = styled.span<MenuLinkType>`
  font-style: normal;
  position: relative;
  font-weight: 500;
  font-size: 1em;
  line-height: 30px;
  color: ${(props) => (props.scroll_on ? secondaryColor : menuFontColor)};
`;

export const MenuContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 40px;
`;

export const MenuContainer1 = styled.div`
  display: flex;
  align-items: center;
  gap: 80px;
`;

export const CustomLink = styled(Link)`
  text-decoration: none;
`;

export const LoginSignupContainer = styled.div`
  display: flex;
  gap: 15px;
`;

export const ProfileButton = styled(Button)<MenuLinkType>`
  font-style: normal;
  font-weight: bold !important;
  font-size: 16px !important;
  line-height: 35px;
  text-transform: capitalize !important;
  color: ${(props) =>
    props.scroll_on ? secondaryColor : menuFontColor} !important;
`;

export const PlanDetailContainer = styled.div`
  display: flex;
  margin-top: 20px;
  justify-content: center;
`;

export const PlanDetail = styled.div`
  background: ${secondaryButtonColor};
  box-shadow: 0px 0px 6.95538px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  padding: 8px;
  display: flex;
  align-items: center;
  gap: 25px;
  margin-bottom: 5px;
  justify-content: space-between;
  @media (max-width: 550px) {
    width: 100%;
  }
`;

export const PlantDetailText = styled.p`
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  margin: 0;
  color: ${secondaryColor};
`;

export const PlanUpgradeButton = styled(Button)`
  font-style: normal;
  font-weight: bold !important;
  font-size: 11px !important;
  background: ${secondaryColor} !important;
  line-height: 35px;
  text-transform: capitalize !important;
  color: ${menuFontColor} !important;
  padding: 0px !important;
  border-radius: 6px !important;
`;

export const MobileLinkContainer = styled.div`
  background: ${primaryOrangeGradient};
  padding: 12px 20px;
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 8px;
`;

export const MobileLink = styled.div`
  font-family: Roobert;
  font-style: normal;
  font-size: 16px;
  font-weight: 500;
  color: ${secondaryColor};
`;

export const MobileNav = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
`;
