/* eslint-disable class-methods-use-this */
import cogoToast, { CTOptions } from "cogo-toast";

class Toaster {
  success = (message: string) => {
    const options = {
      position: "top-center",
      heading: "Success",
      hideAfter: 10,
      // bar: { size: "2px", style: "solid/dashed/dotted", color: "#228B22" }
    };
    cogoToast.success(message, options as CTOptions);
  };

  error = (message: string) => {
    const options = {
      position: "top-center",
      heading: "Error",
      hideAfter: 10,
      // bar: { size: "2px", style: "solid/dashed/dotted", color: "#228B22" }
    };
    cogoToast.error(message, options as CTOptions);
  };

  info = (message: string) => {
    const options = {
      position: "top-center",
      heading: "Info",
      hideAfter: 5,
    };
    cogoToast.info(message, options as CTOptions);
  };
}

const toast = new Toaster();

export default toast;
