import * as React from "react";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import { Grid } from "@material-ui/core";
import { useSelector } from "react-redux";
import CryptoJS from "crypto-js";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import InputBox from "../../../components/Input/index";
import FormHalfDiv from "../../../styledComponents/FormHalfDiv";
import ContainerDiv from "../../../styledComponents/ContainerDiv";
import Header from "../../../parts/Header";
import { loginSchema, loginValues } from "./util";
import { ErrorText, ErrorTextpassw } from "../../../styledComponents/Text";
import { AuthContext } from "../../../context/authContext/authContext";
import {
  ALERT_CONSTANTS,
  ALL_BUTTON_FLAGS,
  APPLICATION_ROUTES,
} from "../../../utils/constant";
import GoogleLoginButton from "./GoogleLoginButton";
import { RootState } from "../../../redux/store";
import styles from "./styles.module.css";
import loginImage from "../../../assets/images/Login2.png";
import NewButton from "../../../components/Button/newButton";
import SuccessErrorMessageBox from "../../../components/SuccessErrorMessageBox/SuccessErrorMessageBox";
import { BASE_URL_FOR_NEXT_WEB } from "../../../utils/apiConstant";

const Login: React.FC = () => {
  const authContext = React.useContext(AuthContext);
  const { onLogin, authError, setAuthError } = authContext;
  const { loading } = useSelector((state: RootState) => state.CommonReducer);
  const [tempError, setTempError] = React.useState("");
  const [showPassword, setShowPassword] = React.useState(false);

  const formik = useFormik({
    initialValues: loginValues,
    validationSchema: loginSchema,
    onSubmit: (values) => {
      console.log("values in file 41", values);
      onLogin(values.email, values.password, values.rememberMe);
    },
  });
  console.log("Loading.state@@@==>>", loading.state);

  React.useEffect(() => {
    if (authError) {
      setTempError(authError);
      setAuthError("");
    }
  }, [authError]);

  React.useEffect(() => {
    const cred = localStorage.getItem("credentials");
    console.log("cred in login.js page", cred);

    if (cred) {
      const bytes = CryptoJS.AES.decrypt(
        cred,
        process.env.REACT_APP_ENV_CRYPTOJS_SECRET_KEY,
      );
      const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      console.log("decryptedData", decryptedData);
      if (decryptedData) {
        formik.setFieldValue("email", decryptedData.email);
        formik.setFieldValue("password", decryptedData.password);
        formik.setFieldValue("rememberMe", decryptedData.rememberMe);
      }
    } else {
      formik.setFieldValue("email", "");
      formik.setFieldValue("password", "");
    }
  }, []);

  return (
    <div className={`${styles.loginPageMain}`}>
      <Header />
      <div className={`classContainer ${styles.loginContainer}`}>
        <Grid container className={`${styles.insideLogin}`}>
          <Grid item xs={12} md={6} lg={6} className={`${styles.loginCol1}`}>
            <div className={`${styles.loginBlock}`}>
              <h1 className={`majorHeading ${styles.loginTitle}`}>Login</h1>
              <p style={{ margin: "10px 0px" }}>
                Login to access our smart survey platform
              </p>
              <GoogleLoginButton />
              <div className={`${styles.separatingContainer}`}>
                <hr />
                <p>or</p>

                <hr />
              </div>
              <p className={styles.manualHeadingLine}>Login with your Email</p>
              <FormHalfDiv
                onSubmit={formik.handleSubmit}
                className={styles.loginFormBlock}
              >
                <InputBox
                  className="emailLogin"
                  type="email"
                  value={formik.values.email}
                  name="email"
                  placeholder="Email*"
                  handleChange={formik.handleChange}
                />
                {formik.touched.email && (
                  <ErrorText>{formik.errors.email}</ErrorText>
                )}
                <div className="passwordContainerLogin">
                  <InputBox
                    className={`${styles.passwordLogin}`}
                    type={showPassword ? "text" : "password"}
                    value={formik.values.password}
                    name="password"
                    placeholder="Password*"
                    handleChange={formik.handleChange}
                    icon={showPassword ? <Visibility /> : <VisibilityOff />}
                    onIconClick={() => setShowPassword(!showPassword)}
                  />
                  {formik.touched.password && (
                    <ErrorTextpassw>{formik.errors.password}</ErrorTextpassw>
                  )}
                </div>

                <ContainerDiv
                  style={{
                    justifyContent: "space-between",
                    marginBottom: "30px",
                    alignItems: "center",
                  }}
                  className={`${styles.forgotBlock}`}
                >
                  <span
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {" "}
                    <input
                      type="checkbox"
                      id="scales"
                      name="rememberMe"
                      onChange={formik.handleChange}
                      checked={
                        formik.values.rememberMe
                          ? Boolean(formik.values.rememberMe)
                          : false
                      }
                    />
                    <p className="loginText">Remember Me</p>
                  </span>
                  <Link
                    to={APPLICATION_ROUTES.FORGET_PASSWORD}
                    className="loginText"
                  >
                    Forgot Password?
                  </Link>
                </ContainerDiv>
                {tempError && <ErrorText>{tempError}</ErrorText>}
                <SuccessErrorMessageBox
                  type="error"
                  messageKey={ALERT_CONSTANTS.LOGIN}
                />
                <NewButton
                  loader={loading}
                  primary
                  btnPrimary={false}
                  type="submit"
                  buttonFlag={ALL_BUTTON_FLAGS.ON_LOGIN}
                  className={styles.authenticateBtn}
                >
                  Login
                </NewButton>
              </FormHalfDiv>
              <p style={{ marginTop: "20px", cursor: "pointer" }}>
                Don’t have an existing account?{" "}
                <a
                  style={{ color: "#0846D5", fontWeight: "600" }}
                  onClick={() => {
                    window.open(
                      `${BASE_URL_FOR_NEXT_WEB}/planPricing`,
                      "_self",
                    );
                  }}
                >
                  Sign Up
                </a>
              </p>
            </div>
          </Grid>
          <Grid item xs={12} md={6} lg={6} className={styles.loginCol2}>
            <img src={loginImage} alt="Login/Signin" />
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default Login;
