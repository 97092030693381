import * as React from "react";
import { useContext } from "react";
import { useSelector } from "react-redux";
import { Route, Redirect, RouteProps, useLocation } from "react-router-dom";
import { AuthContext } from "../context/authContext/authContext";
import LoadingPage from "../pages/staticPages/loadingPage";
import { RootState } from "../redux/store";
import { APPLICATION_ROUTES } from "../utils/constant";

type PrivateRouteType = {
  roles: string[];
} & RouteProps;

export const PrivateRoute: React.FC<PrivateRouteType> = ({
  component: Component,
  roles,
  ...rest
}) => {
  const authContext = useContext(AuthContext);
  const { authData, authStateLoading }: any = authContext;
  const { pathname } = useLocation();
  const { myPlan } = useSelector((state: RootState) => state.UserReducer);

  if (authStateLoading) {
    return <LoadingPage />;
  }

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!authData) {
          return (
            <Redirect
              to={{
                pathname: APPLICATION_ROUTES.LOGIN,
                state: { from: props.location },
              }}
            />
          );
        }

        if (roles.indexOf(authData.role) === -1) {
          return (
            <Redirect
              to={{
                pathname: APPLICATION_ROUTES.ERROR_PAGE,
              }}
            />
          );
        }
        if (
          myPlan?.planType === "Expired" &&
          pathname != APPLICATION_ROUTES.PAYMENT_SUMMARY
        ) {
          return (
            <Redirect
              to={{
                pathname: APPLICATION_ROUTES.MAX_PLAN_LIMIT,
              }}
            />
          );
        }

        return <Component {...props} />;
      }}
    />
  );
};
