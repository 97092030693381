import * as React from "react";
// import { Route, Switch, useHistory } from "react-router-dom";
import { Route, Switch } from "react-router-dom";
import { GoogleOAuthProvider } from "@react-oauth/google";
// import { useDispatch } from "react-redux";
import { AuthProvider } from "../context/authContext/authContext";
import ErrorPage from "../pages/staticPages/errorPage";
import LoadingPage from "../pages/staticPages/loadingPage";
import Footer from "../parts/Footer";
import { availableRoutes } from "./availableRoutes";
import { PrivateRoute } from "./privateRoute";
import { RestrictedRoute } from "./restrictedRoute";
import "./global.css";
import ScrollToTop from "../components/ScrollToTop";
// import { googleClientId, LOGIN_API_URL2 } from "../utils/apiConstant";
import { googleClientId } from "../utils/apiConstant";
// import SecretLogin from "../pages/staticPages/SecretLogin";
// import {
//   ALERT_CONSTANTS,
//   ALL_BUTTON_FLAGS,
//   APPLICATION_ROUTES,
// } from "../utils/constant";
// import { _POST } from "../services/axios.method";
// import {
//   setLoading,
//   setSuccessErrorMessage,
// } from "../redux/action-creator/common.action";
// import Contact from "../pages/screens/contact";

const Routing: React.FC = () => {
  // const [secretUser, setSecretUser] = React.useState<any>(false);
  // const history = useHistory();
  // React.useEffect(() => {
  //   const user = localStorage.getItem("secretUser");
  //   if (user) {
  //     setSecretUser(JSON.parse(user));
  //   }
  // }, []);
  // const dispatch = useDispatch();
  // const onSaveSecretUser = async (userData: any) => {
  //   dispatch(setLoading(true, ALL_BUTTON_FLAGS.ON_LOGIN));

  //   const res = await _POST(LOGIN_API_URL2, userData);
  //   console.log("LOGIN_API_URL2", LOGIN_API_URL2, res);
  //   dispatch(setLoading(false, ALL_BUTTON_FLAGS.ON_LOGIN));
  //   if (res?.data?.data) {
  //     setSecretUser(res.data.data);
  //     localStorage.setItem("secretUser", JSON.stringify(res.data.data.token));
  //     setSecretUser(res.data.data.token);
  //     history.push(APPLICATION_ROUTES.HOME);
  //     return;
  //   }
  //   dispatch(
  //     setSuccessErrorMessage(
  //       "Invalid token.",
  //       "error",
  //       ALERT_CONSTANTS.SECRET_LOGIN,
  //     ),
  //   );
  // };

  return (
    <>
      <React.Suspense fallback={<LoadingPage />}>
        {/* {!secretUser ? (
          <Switch>
            <Route exact path="/">
              <SecretLogin onSaveSecretUser={onSaveSecretUser} />
            </Route>
            <Route exact path="#contact">
              <Contact isRestricted />
            </Route>
            <Route>
              <SecretLogin onSaveSecretUser={onSaveSecretUser} />
            </Route>
          </Switch>
        ) : ( */}
        <GoogleOAuthProvider clientId={googleClientId}>
          <AuthProvider>
            <ScrollToTop />
            <Switch>
              {availableRoutes.map((route) =>
                route.isPrivate ? (
                  <PrivateRoute
                    key={route.routePath}
                    exact
                    path={route.routePath}
                    roles={route.accessRoles}
                    component={route.component}
                  />
                ) : (
                  <RestrictedRoute
                    key={route.routePath}
                    exact
                    path={route.routePath}
                    component={route.component}
                    visibleAfterLogin={route.visibleAfterLogin}
                  />
                ),
              )}
              <Route component={ErrorPage} />
            </Switch>
          </AuthProvider>
        </GoogleOAuthProvider>
        {/* )} */}
      </React.Suspense>
      <Footer />
    </>
  );
};
export default Routing;
