import * as React from "react";
import { Toolbar, useMediaQuery, useScrollTrigger } from "@material-ui/core";
import { useLocation } from "react-router";
import logo from "../../assets/images/logo.png";
import logoWhite from "../../assets/images/logo-white.png";
import logoBlack from "../../assets/images/logo_black.png";
import {
  CustomAppBar,
  CustomLink,
  HeaderContainer,
  HeaderLogo,
  LoginSignupContainer,
  MenuContainer,
  MenuLink,
  MenuVertiBar,
  MobileMenuContainer,
} from "./headerStyled";
import MobileMenu from "./mobileMenu";
import { menuRoutes } from "../../utils/routesConstant";
import { APPLICATION_ROUTES } from "../../utils/constant";
import { AuthContext } from "../../context/authContext/authContext";
import ProfileMenu from "../HeaderMenuOptions/profileMenu";
import styles from "./styles.module.css";
import { BASE_URL_FOR_NEXT_WEB } from "../../utils/apiConstant";

type HeaderType = {
  isSticky?: boolean;
  isRestricted?: boolean;
};

const Header: React.FC<HeaderType> = ({ isSticky, isRestricted }) => {
  const authContext = React.useContext(AuthContext);
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 50,
  });
  console.log("authContext in header", authContext);
  const mobileView = useMediaQuery("(max-width:900px)");
  const location = useLocation();

  return (
    <CustomAppBar
      elevation={0}
      position={isSticky ? "fixed" : "absolute"}
      scroll_on={isSticky ? trigger : true}
      className={isSticky ? "transhdr" : "ornghdr"}
    >
      {!mobileView && (
        <Toolbar style={{ padding: "20px 105px" }}>
          <HeaderContainer>
            <div
              onClick={() => {
                window.open(`${BASE_URL_FOR_NEXT_WEB}`, "_self");
              }}
            >
              {/* <CustomLink to="/"> */}
              <HeaderLogo
                src={!isSticky ? logoWhite : trigger ? logoWhite : logoBlack}
                alt="logo"
              />
              {/* </CustomLink> */}
            </div>
            <MenuContainer>
              {menuRoutes.map((menu) => {
                // menu.id === 3 ||
                if (isRestricted && menu.id === 3) {
                  // if restricted login then hide plan pricing from menu
                  return null;
                }
                if (isRestricted && menu.id === 4) {
                  // if restricted login then hide my survey from menu
                  return null;
                }
                if (!authContext?.authData && menu.id === 4) {
                  return null;
                }
                if (menu.redirected === true) {
                  return (
                    // <a
                    //   href={menu.url}
                    //   key={menu.name}
                    //   className={styles.navLink}
                    // >
                    //   <span>{menu.name}</span>
                    // </a>
                    <CustomLink
                      key={menu.name}
                      // to={menu.url}
                      onClick={() => {
                        window.open(`${menu.url}`, "_self");
                      }}
                      className={styles.navLink}
                    >
                      <MenuLink
                        active={
                          location.pathname.toLowerCase() ===
                          menu.url.toLowerCase()
                        }
                        scroll_on={isSticky ? trigger : true}
                        className={styles.navLink}
                      >
                        <span>{menu.name}</span>
                      </MenuLink>
                    </CustomLink>
                  );
                }
                return (
                  <CustomLink
                    key={menu.name}
                    to={menu.url}
                    className={styles.navLink}
                  >
                    <MenuLink
                      active={
                        location.pathname.toLowerCase() ===
                        menu.url.toLowerCase()
                      }
                      scroll_on={isSticky ? trigger : true}
                      className={styles.navLink}
                    >
                      <span>{menu.name}</span>
                    </MenuLink>
                  </CustomLink>
                );
              })}
            </MenuContainer>

            {authContext && authContext?.authData ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <ProfileMenu isSticky={isSticky} trigger={trigger} />
              </div>
            ) : (
              !isRestricted && (
                <LoginSignupContainer className={styles.loginSignUp}>
                  <CustomLink
                    to={APPLICATION_ROUTES.LOGIN}
                    className={styles.navLink}
                  >
                    <MenuLink
                      scroll_on={isSticky ? trigger : true}
                      active={
                        location.pathname.toLowerCase() ===
                        APPLICATION_ROUTES.LOGIN
                      }
                    >
                      Login
                    </MenuLink>
                  </CustomLink>
                  <MenuVertiBar scroll_on={isSticky ? trigger : true}>
                    |
                  </MenuVertiBar>
                  <CustomLink
                    // to={APPLICATION_ROUTES.SIGN_UP}
                    onClick={() => {
                      window.open(
                        `${BASE_URL_FOR_NEXT_WEB}/planPricing`,
                        "_self",
                      );
                    }}
                    className={styles.navLink}
                  >
                    <MenuLink
                      scroll_on={isSticky ? trigger : true}
                      active={
                        location.pathname.toLowerCase() ===
                        APPLICATION_ROUTES.SIGN_UP
                      }
                    >
                      Sign Up
                    </MenuLink>
                  </CustomLink>
                </LoginSignupContainer>
              )
            )}
          </HeaderContainer>
        </Toolbar>
      )}

      {mobileView && (
        <MobileMenuContainer>
          <CustomLink to="/">
            <HeaderLogo
              src={!isSticky ? logoWhite : trigger ? logoWhite : logo}
              alt="logo"
            />
          </CustomLink>
          <MobileMenu />
        </MobileMenuContainer>
      )}
    </CustomAppBar>
  );
};

export default Header;
