export const BASE_URL = process.env.REACT_APP_ENV_BASE_URL as string;
export const BASE_URL_FOR_NEXT_WEB = process.env
  .REACT_APP_ENV_BASE_URL_FOR_NEXT_WEB as string;

const BASE_SERVICE = {
  USER: "user/",
  DF: "dialogFlow/",
  ANALYTICS: "analytics/",
  SUGGESTIONS: "suggestions/",
  // USER: "",
  // DF: "",
  // ANALYTICS: "",
  // SUGGESTIONS: "",
};

export const googleClientId = process.env
  .REACT_APP_ENV_GOOGLE_CLIENT_ID as string;
export const CRYPTOJS_SECRET_KEY = process.env
  .REACT_APP_ENV_CRYPTOJS_SECRET_KEY as string;
// chatbot link
export const CHATBOT_LINK = process.env.REACT_APP_ENV_CHATBOT_LINK as string;
export const CHATBOT_CONVO_LINK = process.env
  .REACT_APP_ENV_CHATBOT_CONVO_LINK as string;
export const SIGNUP_SEND_VERIFY_OTP_API_URL = `${BASE_SERVICE.USER}signup-send-verify-otp`;
export const SIGNUP_AFTER_PAYMENT = `${BASE_SERVICE.USER}signup-after-payment`;
export const CONTACTUS_API_URL = `${BASE_SERVICE.USER}sendEmail`;
export const USER_PROFILE_UPDATE_API_URL = `${BASE_SERVICE.USER}profile/update`;
export const LOGIN_API_URL = `${BASE_SERVICE.USER}login`;
export const SEND_OTP_API_URL = `${BASE_SERVICE.USER}sendEmailOtp`;

export const SEND_RESET_PASS_EMAIL_API_URL = `${BASE_SERVICE.USER}sendEmailResetPass`;
export const VERIFY_OTP_API_URL = `${BASE_SERVICE.USER}signup-validate-otp`;
export const ADD_BILLING_DETAILS = `${BASE_SERVICE.USER}add-billing-details`;
export const PASSOWORD_UPDATE = `${BASE_SERVICE.USER}password/update`;

export const GET_CATEGORIES_URL = `${BASE_SERVICE.USER}categories/all`;
export const GET_BUSINESS_CATEGORIES_URL = `${BASE_SERVICE.USER}business-categories/all`;
export const FORGOT_PASSWORD_API_URL = `${BASE_SERVICE.USER}password/forgot`;
export const CREATE_CHECKOUT_SESSION = `${BASE_SERVICE.USER}create-checkout-session`;
export const CREATE_SUBSCRIPTION = `${BASE_SERVICE.USER}create-subscription`;
export const USER_PLAN = `${BASE_SERVICE.USER}userPlan`;
export const PLAN_AND_PRICING = `${BASE_SERVICE.USER}planAndPricing`;
export const ORDER_DETAILS = `${BASE_SERVICE.USER}order-details`;
export const REDUCE_PLAN_SURVEY_COUNT = `${BASE_SERVICE.USER}reducePlanSurveyCount`;
export const UPDATE_BUSINESS_NAME = `${BASE_SERVICE.USER}updateBusinessName`;
export const RESET_PASSWORD_API_URL = `${BASE_SERVICE.USER}password/change`;
export const GET_USER_SURVEYS = `${BASE_SERVICE.USER}get-user-surveys`;
// Suggestions
export const GET_QUESTIONS_SUGGESTIONS = `${BASE_SERVICE.DF}fetch-suggestions`;
export const GET_WELCOME_SUGGESTIONS = `${BASE_SERVICE.DF}fetch-welcome-suggestions`;
export const GET_ANSWER_GENIUS = `${BASE_SERVICE.DF}fetch-answerGenius`;
export const GET_CLOSING_SUGGESTIONS = `${BASE_SERVICE.DF}fetch-closing-suggestions`;
export const LOGIN_API_URL2 = `${BASE_SERVICE.USER}userlogin`;
export const CREATE_SURVEY = `${BASE_SERVICE.DF}survey`;
export const UPDATE_SURVEY = `${BASE_SERVICE.DF}update-survey`;
export const GET_INTENTS_SURVEY = `${BASE_SERVICE.DF}get-survey-intent`;

export const UPLOAD_TO_BUCKET = `${BASE_SERVICE.DF}upload-image`;
export const SAVE_SURVEY_IMAGE_TO_BUCKET = `${BASE_SERVICE.DF}upload-survey-image`;

export const UPDATE_WELCOME_MESSAGE_API = `${BASE_SERVICE.DF}update-welcome-intent`;
export const UPDATE_INTENT = `${BASE_SERVICE.DF}update-intent`;
export const CREATE_DIALOGFLOW_INTENT = `${BASE_SERVICE.DF}create-intent`;
export const SAVE_SURVEY_QUESTIONS = `${BASE_SERVICE.DF}save-survey-questions`;
export const GET_SURVEY_QUESTIONS = `${BASE_SERVICE.DF}get-survey-questions`;
export const DELETE_DIALOGFLOW_INTENT_API = `${BASE_SERVICE.DF}delete-intents`;
export const GET_SURVEY_DETAIL = `${BASE_SERVICE.DF}get-survey-info`;
export const GET_SURVEY_LIST = `${BASE_SERVICE.DF}get-survey-list`;
export const GET_DASHBOARD_DATA = `${BASE_SERVICE.DF}get-dashboard-data`;
export const DISABLE_ENABLE_SURVEY = `${BASE_SERVICE.DF}disable-enable-survey`;
export const DELETE_SURVEY_QUESTION = `${BASE_SERVICE.DF}delete-survey-intent`;
export const GOOGLE_LOGIN_URL = `${BASE_SERVICE.USER}google-login`;
export const GET_ANALYTICS_PIE_CHART_DATA = `${BASE_SERVICE.ANALYTICS}sentiment`;
export const GET_ANALYTICS_EMOTION_PIE_CHART_DATA = `${BASE_SERVICE.ANALYTICS}emotion`;
export const GET_VISITORS_DATA = `${BASE_SERVICE.ANALYTICS}visitorAnalytics`;
export const GET_CONVERSATION_ANALYTICS_DATA = `${BASE_SERVICE.ANALYTICS}conversation`;
export const GET_RESPONSE_ANALYTICS_DATA = `${BASE_SERVICE.ANALYTICS}response/analytics`;
export const GET_MOST_RESPONDED_SURVEY = `${BASE_SERVICE.ANALYTICS}getMostResponsedSurvey`;
export const GET_SURVEY_TRANSCRIPT = `${BASE_SERVICE.ANALYTICS}getSurveyTranscript`;
export const GET_SURVEY_TRANSCRIPT_BY_USER = `${BASE_SERVICE.ANALYTICS}getSurveyTranscriptByUser`;
export const GET_QUESTION_ANALYTICS_DATA = `${BASE_SERVICE.ANALYTICS}calculateQuestionAnalytics`;

export const GET_ALL_MESSAGES = `${BASE_SERVICE.DF}getMessages`;
