import React from "react";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import Grow from "@mui/material/Grow";
import format from "date-fns/format";
import CardImg from "../../assets/images/surveyChattyNew.png";
import styles from "../../pages/screens/DashboardNewUser/styles.module.css";
import { APPLICATION_ROUTES } from "../../utils/constant";
import SurveyActionButtons from "../SurveyActionButtons/SurveyActionButtons";
import { CardRibbonViewStatus } from "../../styledComponents";
import { formatSlugName } from "../../utils/helper";
import { CustomStyledTooltip } from "../ToolTip/styled";

type DashboardCardType = {
  data: any;
  showAnalytics?: boolean;
  delay?: any;
  disableSurvey?: boolean;
};

const DashboardCard: React.FC<DashboardCardType> = ({
  data,
  showAnalytics,
  delay,
  disableSurvey,
}) => {
  const history = useHistory();
  console.log("data**", data);
  return (
    <Grow
      in
      {...(delay ? { timeout: delay } : {})}
      style={{ transformOrigin: "0 0 0" }}
    >
      <div
        className={`${styles.newSurvey} ${
          showAnalytics && styles.analyticsSurveyBlock
        }`}
      >
        <div className="ribbonStrips">
          <CardRibbonViewStatus
            color={
              data?.isActive
                ? data?.isPublished
                  ? "#00C247"
                  : "#FC644C"
                : "#BDBDBD"
            }
            textColor={!data?.isActive ? "#000" : "#FFF"}
          >
            {data?.isActive
              ? data?.isPublished
                ? "Published"
                : "Draft"
              : "Disabled"}
          </CardRibbonViewStatus>
        </div>
        <Link
          className={styles.cardLink}
          to={
            showAnalytics
              ? `${APPLICATION_ROUTES.ANALYTICS}?surveyId=${data._id}&surveyName=${data.title}`
              : `${APPLICATION_ROUTES.VIEW_SURVEY}?surveyId=${data._id}`
          }
        />

        <div className={`${styles.cardInner}`}>
          <div className={styles.cardUpper}>
            <div
              className={`${styles.cardImgContainer}`}
              style={{
                backgroundImage: `url(${
                  data.surveyLogo ? `'${data.surveyLogo}'` : CardImg
                })`,
              }}
            />
            <div className={styles.cardDetails}>
              <div className={styles.cardDescriptionContainer}>
                <div className={styles.cardDescription}>
                  <h4>{data.title}</h4>
                  <p className={styles.cardTitle}>
                    {formatSlugName(data.category)}
                  </p>
                </div>
                {!showAnalytics && (
                  <div className={styles.cardBtn}>
                    <SurveyActionButtons surveyData={data} />
                    {!showAnalytics && !data?.isPublished && !disableSurvey && (
                      <div className={`${styles.cardTime} cardTimeBlock`}>
                        <p className={styles.lastUpdateText}>
                          <span>
                            Created on{" "}
                            {data.createdAt &&
                              format(new Date(data.createdAt), "MM/dd/yyyy")}
                          </span>
                          <span> | </span>
                          <span>
                            Modified on{" "}
                            {data.updatedAt &&
                              format(new Date(data.updatedAt), "MM/dd/yyyy")}
                          </span>
                        </p>
                      </div>
                    )}
                    {!showAnalytics && data?.isPublished && !disableSurvey && (
                      <div className={`${styles.cardTime} cardTimeBlock`}>
                        <p className={styles.lastUpdateText}>
                          {/* <span>
                            Created on{" "}
                            {data.createdAt &&
                              format(new Date(data.createdAt), "MM/dd/yyyy")}
                          </span>
                          <span> | </span> */}
                          <span>
                            Published on{" "}
                            {data.updatedAt &&
                              format(new Date(data.updatedAt), "MM/dd/yyyy")}
                          </span>
                        </p>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {showAnalytics && (
          <div className={styles.analyticsBtn}>
            <CustomStyledTooltip title="The user can view the detailed survey analytics of their created surveys by clicking the 'Analytics' button.">
              <button
                onClick={() =>
                  history.push(
                    `${APPLICATION_ROUTES.ANALYTICS}?surveyId=${data._id}&surveyName=${data.title}`,
                  )
                }
              >
                Analytics
              </button>
            </CustomStyledTooltip>
          </div>
        )}
      </div>
    </Grow>
  );
};

export default DashboardCard;
