import styled from "styled-components";

const ButtonContainer: any = styled.button`
  width: 100%;
  background: ${(props: any) => (props.primary ? "#000" : "#40547B")};
  color: ${(props: any) => (props.btnPrimary ? "#40547B" : "#fff")};
  border-radius: 6px;
  border: none;
  padding: 10px 0px;
  font-family: "Mulish";
  font-size: 22px;
`;

export default ButtonContainer;
