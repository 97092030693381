import { styled } from "@material-ui/core/styles";

const DrawerHeader = styled("div")(({ theme }: any) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(1, 2),

  ...theme.mixins.toolbar,
}));

export default DrawerHeader;
