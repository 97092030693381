export enum ActionType {
  CREATE_SURVEY = "CREATE_SURVEY",
  GET_SURVEY = "GET_SURVEY",
  SEARCH_SURVEY_HEADER = "SEARCH_SURVEY_HEADER",
  UPDATE_DEFAULT_INTENT = "UPDATE_DEFAULT_INTENT",
  SET_ERROR_ON_SECTIONS = "SET_ERROR_ON_SECTIONS",
  UPLOAD_IMAGE_BUCKET = "UPLOAD_IMAGE_BUCKET",
  UPDATE_SURVEY = "UPDATE_SURVEY",
  GET_INTENTS_SURVEY = "GET_INTENTS_SURVEY",
  GET_QUESTION_SUGGESTIONS = "GET_QUESTION_SUGGESTIONS",
  GET_QUESTION_SUGGESTIONS_MODAL = "GET_QUESTION_SUGGESTIONS_MODAL",
  GET_WELCOME_SUGGESTIONS = "GET_WELCOME_SUGGESTIONS",
  GET_ANSWER_GENIUS = "GET_ANSWER_GENIUS",
  GET_CLOSING_SUGGESTIONS = "GET_CLOSING_SUGGESTIONS",
  GET_SURVEY_LIST = "GET_SURVEY_LIST",
  GET_PUBLISHED_SURVEY_LIST = "GET_PUBLISHED_SURVEY_LIST",
  GET_DISABLED_SURVEY_LIST = "GET_DISABLED_SURVEY_LIST",
  GET_DASHBOARD_DATA = "GET_DASHBOARD_DATA",
  GET_MOST_RESPONDED_SURVEY = "GET_MOST_RESPONDED_SURVEY",
  DELETE_SURVEY_QUESTION = "DELETE_SURVEY_QUESTION",
  GET_USER_SPRECIFIC_TRANSCRIPT = "GET_USER_SPRECIFIC_TRANSCRIPT",
  PLAN_AND_PRICING = "PLAN_AND_PRICING",
  ORDER_DETAILS = "ORDER_DETAILS",
  GET_USER_SURVEYS = "GET_USER_SURVEYS",

  GET_ALL_CATEGORIES = "GET_ALL_CATEGORIES",
  GET_BUSINESS_CATEGORIES = "GET_BUSINESS_CATEGORIES",

  UPDATE_PROFILE = "UPDATE_PROFILE",
  GET_USER_PLAN = "GET_USER_PLAN",
  UPDATE_BUSINESS_NAME = "UPDATE_BUSINESS_NAME",
  REDUCE_USER_PLAN = "REDUCE_USER_PLAN",
  WELCOME_SECTION_TRIGGERED = "WELCOME_SECTION_TRIGGERED",
  SET_ERROR_MESSAGE = "SET_ERROR_MESSAGE",
  REMOVE_ERROR_MESSAGE = "REMOVE_ERROR_MESSAGE",
  SET_SUCCESS_MESSAGE = "SET_SUCCESS_MESSAGE",
  REMOVE_SUCCESS_MESSAGE = "REMOVE_SUCCESS_MESSAGE",

  LOADING_STATE = "LOADING_STATE",
  SURVEY_ANALYTICS_SENTIMENTS = "SURVEY_ANALYTICS_SENTIMENTS",
  SURVEY_ANALYTICS_EMOTIONS = "SURVEY_ANALYTICS_EMOTIONS",
  GET_VISITORS_DATA = "GET_VISITORS_DATA",
  GET_CONVERSATION_ANALYTICS_DATA = "GET_CONVERSATION_ANALYTICS_DATA",
  GET_RESPONSE_ANALYTICS_DATA = "GET_RESPONSE_ANALYTICS_DATA",
  GET_QUESTION_ANALYTICS_DATA = "GET_QUESTION_ANALYTICS_DATA",

  GET_MESSAGES = "GET_MESSAGES",
  GET_SURVEY_TRANSCRIPT = "GET_SURVEY_TRANSCRIPT",
}
