import * as yup from "yup";
import { ref } from "yup";

export const registerValues = {
  email: "",
  firstName: "",
  lastName: "",
  businessName: "",
  password: "",
  confirmPassword: "",
  businessCategory: "",
  selectedPlan: "",
  planPrice: "",
};

export const registerSchema = yup
  .object({
    email: yup
      .string()
      .email("Please enter a valid email.")
      .required("This is a required field."),
    password: yup
      .string()
      .min(5, "Password must be at least 5 characters.")
      .required("This is a required field.")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
        "Password must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character.",
      ),
    confirmPassword: yup
      .string()
      .min(5, "Confirm password must be at least 5 characters.")
      .required("This is a required field.")
      .oneOf([ref("password")], "Passwords do not match."),
    firstName: yup
      .string()
      .required("This is a required field.")
      .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field"),
    lastName: yup
      .string()
      .required("This is a required field.")
      .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field"),
    businessName: yup.string(),
    businessCategory: yup.string(),
  })
  .required();
