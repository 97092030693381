import { ActionType } from "../types";

export const initialState = {
  categories: [] as any,
  businesCategories: [] as any,
  myPlan: {} as any,
  planAndPricing: [] as any,
  orderDetails: {} as any,
  userSurveyStats: {} as any,
};

// eslint-disable-next-line @typescript-eslint/default-param-last
export const userReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case ActionType.GET_ALL_CATEGORIES:
      return {
        ...state,
        categories: action.payload,
      };
    case ActionType.GET_BUSINESS_CATEGORIES:
      return {
        ...state,
        businesCategories: action.payload,
      };
    case ActionType.GET_USER_PLAN:
      return {
        ...state,
        myPlan: action.payload,
      };
    case ActionType.PLAN_AND_PRICING:
      return {
        ...state,
        planAndPricing: action.payload,
      };
    case ActionType.ORDER_DETAILS:
      return {
        ...state,
        orderDetails: action.payload,
      };

    case ActionType.GET_USER_SURVEYS:
      return {
        ...state,
        userSurveyStats: action.payload,
      };

    default:
      return state;
  }
};
