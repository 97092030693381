import { AnyAction, Dispatch } from "redux";
import { _POST } from "../../services/axios.method";
import {
  SAVE_SURVEY_IMAGE_TO_BUCKET,
  UPLOAD_TO_BUCKET,
} from "../../utils/apiConstant";
import { FormDataValue } from "../../utils/interfaceModel";
import { Action } from "../action";
import { ActionType } from "../types";

export const setLoading =
  (state: boolean, buttonFlag: string) =>
  async (dispatch: Dispatch<AnyAction>) => {
    dispatch({
      type: ActionType.LOADING_STATE,
      payload: { state, buttonFlag },
    });
  };

export const setSuccessErrorMessage =
  (message: string, type: "success" | "error", key: string) =>
  async (dispatch: Dispatch<AnyAction>) => {
    if (type === "error") {
      dispatch({
        type: ActionType.SET_ERROR_MESSAGE,
        payload: { message, key: key || "" },
      });
      setTimeout(() => {
        dispatch({
          type: ActionType.REMOVE_ERROR_MESSAGE,
          payload: { message, key: key || "" },
        });
      }, 5000);
      return;
    }

    dispatch({
      type: ActionType.SET_SUCCESS_MESSAGE,
      payload: { message, key: key || "" },
    });
    setTimeout(() => {
      dispatch({
        type: ActionType.REMOVE_SUCCESS_MESSAGE,
        payload: { message, key: key || "" },
      });
    }, 3000);
  };

export const uploadImageToBucket =
  (payload: FormDataValue, onImageUpload: any) =>
  async (dispatch: Dispatch<Action>) => {
    try {
      const response = await _POST(UPLOAD_TO_BUCKET, payload, onImageUpload);

      dispatch({
        type: ActionType.UPLOAD_IMAGE_BUCKET,
        payload: response,
      });

      return response.data.data;
    } catch (err) {
      console.log("error");
    }
  };

export const saveSurveyImageToBucket =
  (payload: any, surveyId: string) => async (dispatch: Dispatch<Action>) => {
    try {
      const response = await _POST(
        `${SAVE_SURVEY_IMAGE_TO_BUCKET}?surveyId=${surveyId}`,
        payload,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
      );
      dispatch({
        type: ActionType.UPLOAD_IMAGE_BUCKET,
        payload: response,
      });
      return response.data.data;
    } catch (err) {
      console.log("error");
    }
  };
