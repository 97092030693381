import ForgotPassword from "../pages/authentication/ForgotPassword";
import AllSurveyAnalytics from "../pages/screens/AllSurveyAnalytics";
import AboutUs from "../pages/staticPages/aboutUs";
import Contact from "../pages/screens/contact";
// import Payment from "../pages/screens/Payment";
import MaxPlanLimit from "../pages/staticPages/maxPlanLimit";
import WelcomeScreen from "../pages/staticPages/welcomeScreen";
import ForgotPasswordHelp from "../pages/staticPages/forgotPasswordHelp/forgotPasswordHelp";
import { APPLICATION_ROUTES, APP_ROLE } from "../utils/constant";
import {
  HomeComponent,
  LoginComponent,
  SignupComponent,
  CreateNewSurveyComponent,
  PricingComponent,
  AddQuestionariesComponent,
  Account,
  ResetPasswordComponent,
  ManageSurvey,
  DashboardNewUser,
  SurveyAnalytics,
  Analytics,
  ShareSurvey,
  PaymentSuccessful,
  PaymentSummary,
  ViewSurvey,
  PaymentComponent,
} from "./dynamicRoutes";
import { OtpVerification } from "../pages/authentication";
import ContinueToPayment from "../pages/staticPages/continueToPayment";

export const availableRoutes = [
  {
    routePath: APPLICATION_ROUTES.PRICING,
    component: PricingComponent,
    isPrivate: false,
    accessRoles: [APP_ROLE.ALL],
    visibleAfterLogin: true,
  },
  {
    routePath: APPLICATION_ROUTES.ABOUT_US,
    component: AboutUs,
    isPrivate: false,
    accessRoles: [APP_ROLE.ALL],
    visibleAfterLogin: true,
  },
  {
    routePath: APPLICATION_ROUTES.CONTACT,
    component: Contact,
    isPrivate: false,
    accessRoles: [APP_ROLE.ALL],
    visibleAfterLogin: true,
  },
  {
    routePath: APPLICATION_ROUTES.PAYMENT,
    component: PaymentComponent,
    isPrivate: false,
    accessRoles: [APP_ROLE.ALL],
    visibleAfterLogin: true,
  },
  {
    routePath: APPLICATION_ROUTES.WELCOMESCREEN,
    component: WelcomeScreen,
    isPrivate: false,
    accessRoles: [APP_ROLE.ALL],
    visibleAfterLogin: true,
  },
  {
    routePath: APPLICATION_ROUTES.FORGOT_PASSWORD_HELP,
    component: ForgotPasswordHelp,
    isPrivate: false,
    accessRoles: [APP_ROLE.ALL],
    visibleAfterLogin: true,
  },
  {
    routePath: APPLICATION_ROUTES.CONTINUE_PAYMENT,
    component: ContinueToPayment,
    isPrivate: true,
    accessRoles: [APP_ROLE.ALL, APP_ROLE.ADMIN, APP_ROLE.SUPERADMIN],
    visibleAfterLogin: true,
  },
  {
    routePath: APPLICATION_ROUTES.LOGIN,
    component: LoginComponent,
    isPrivate: false,
    accessRoles: [APP_ROLE.ALL],
    visibleAfterLogin: false,
  },
  {
    routePath: APPLICATION_ROUTES.ALL_ANALYTICS_PAGE,
    component: AllSurveyAnalytics,
    isPrivate: true,
    accessRoles: [APP_ROLE.ALL, APP_ROLE.ADMIN, APP_ROLE.SUPERADMIN],
    visibleAfterLogin: true,
  },
  {
    routePath: APPLICATION_ROUTES.RESET_PASSWORD,
    component: ResetPasswordComponent,
    isPrivate: false,
    accessRoles: [APP_ROLE.ALL],
    visibleAfterLogin: false,
  },
  {
    routePath: APPLICATION_ROUTES.SIGN_UP,
    component: SignupComponent,
    isPrivate: false,
    accessRoles: [APP_ROLE.ALL],
    visibleAfterLogin: false,
  },
  {
    routePath: APPLICATION_ROUTES.OTP_VERIFICATION,
    component: OtpVerification,
    isPrivate: false,
    accessRoles: [APP_ROLE.ALL],
    visibleAfterLogin: false,
  },
  {
    routePath: APPLICATION_ROUTES.FORGET_PASSWORD,
    component: ForgotPassword,
    isPrivate: false,
    accessRoles: [APP_ROLE.ALL],
    visibleAfterLogin: true,
  },
  {
    routePath: APPLICATION_ROUTES.HOME,
    component: HomeComponent,
    isPrivate: false,
    accessRoles: [APP_ROLE.ALL],
    visibleAfterLogin: true,
  },
  {
    routePath: APPLICATION_ROUTES.CREATE_NEW_SURVEY,
    component: CreateNewSurveyComponent,
    isPrivate: true,
    accessRoles: [APP_ROLE.ADMIN, APP_ROLE.SUPERADMIN],
    visibleAfterLogin: true,
  },
  {
    routePath: APPLICATION_ROUTES.ADD_QUESTIONIONARIES,
    component: AddQuestionariesComponent,
    isPrivate: true,
    accessRoles: [APP_ROLE.ADMIN],
    visibleAfterLogin: true,
  },
  {
    routePath: APPLICATION_ROUTES.EDIT_QUESTIONIONARIES,
    component: AddQuestionariesComponent,
    isPrivate: true,
    accessRoles: [APP_ROLE.ADMIN],
    visibleAfterLogin: true,
  },
  {
    routePath: APPLICATION_ROUTES.MANAGE_SURVEY,
    component: ManageSurvey,
    isPrivate: true,
    accessRoles: [APP_ROLE.ADMIN],
    visibleAfterLogin: true,
  },
  {
    routePath: APPLICATION_ROUTES.ACCOUNT,
    component: Account,
    isPrivate: true,
    accessRoles: [APP_ROLE.ADMIN, APP_ROLE.SUPERADMIN],
    visibleAfterLogin: true,
  },
  {
    routePath: APPLICATION_ROUTES.DASHBOARD,
    component: DashboardNewUser,
    isPrivate: true,
    accessRoles: [APP_ROLE.ADMIN],
    visibleAfterLogin: true,
  },
  {
    routePath: APPLICATION_ROUTES.VIEW_SURVEY,
    component: ViewSurvey,
    isPrivate: true,
    accessRoles: [APP_ROLE.ADMIN],
    visibleAfterLogin: true,
  },
  {
    routePath: APPLICATION_ROUTES.ANALYTICS,
    component: Analytics,
    isPrivate: true,
    accessRoles: [APP_ROLE.ADMIN],
    visibleAfterLogin: true,
  },
  {
    routePath: APPLICATION_ROUTES.SURVEY_ANALYTICS,
    component: SurveyAnalytics,
    isPrivate: true,
    accessRoles: [APP_ROLE.ADMIN],
    visibleAfterLogin: true,
  },
  {
    routePath: APPLICATION_ROUTES.SHARE_SURVEY,
    component: ShareSurvey,
    isPrivate: true,
    accessRoles: [APP_ROLE.ADMIN],
    visibleAfterLogin: true,
  },
  {
    routePath: APPLICATION_ROUTES.PAYMENT_SUCCESSFUL,
    component: PaymentSuccessful,
    isPrivate: false,
    accessRoles: [APP_ROLE.ADMIN],
    visibleAfterLogin: true,
  },
  {
    routePath: APPLICATION_ROUTES.PAYMENT_SUMMARY,
    component: PaymentSummary,
    isPrivate: true,
    accessRoles: [APP_ROLE.ADMIN],
    visibleAfterLogin: true,
  },
  {
    routePath: APPLICATION_ROUTES.MAX_PLAN_LIMIT,
    component: MaxPlanLimit,
    isPrivate: false,
    accessRoles: [APP_ROLE.ALL],
    visibleAfterLogin: true,
  },
];
