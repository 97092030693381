import * as React from "react";
import { Grid } from "@mui/material";
import { useHistory, useLocation } from "react-router";
import Header from "../../../parts/Header";
import styles from "../welcomeScreen/styles.module.css";
import WelcomeImg from "../../../assets/images/welcomeScreen.png";
import partyGif from "../../../assets/images/party.gif";
import ButtonContainer from "../../../styledComponents/button/button";
import { APPLICATION_ROUTES } from "../../../utils/constant";

const ContinueToPayment: React.FC = () => {
  const history = useHistory();
  const location: any = useLocation();
  const [userData, setUserData] = React.useState<any>(null);
  console.log("userData", userData);
  React.useEffect(() => {
    if (!location.state?.userData) {
      history.push(APPLICATION_ROUTES.DASHBOARD);
      return;
    }
    setUserData(location.state?.userData);
  }, []);
  return (
    <>
      <Header />
      <div className={`classContainer ${styles.welcomeScreenContainer}`}>
        <Grid container>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
            className={styles.welcomeCol1}
          >
            <div className={styles.welcomeScreenHeading}>
              <div className={styles.partyPopper}>
                <img src={partyGif} alt="congratulations" />
              </div>
              <h4
                style={{ color: "#FC644C", fontWeight: 700, marginBottom: 25 }}
              >
                Sign Up Successful for {userData?.selectedPlan} Plan!
              </h4>
              <h2 className={`majorHeading ${styles.majorHeading}`}>WELCOME</h2>
            </div>
            <h5 style={{ fontWeight: 700 }}>to SurveyChatty</h5>
            <p>Thanks for being Awesome!</p>
            <div className={styles.btnContainer}>
              <ButtonContainer
                primary={false}
                color={false}
                onClick={() => {
                  history.push(APPLICATION_ROUTES.PAYMENT_SUMMARY, {
                    PlanName: userData?.selectedPlan || "",
                    PlanPrice: Number(userData?.planPrice) || 0,
                    planType: userData?.selectedPlan || "",
                  });
                }}
              >
                Continue To Payment
              </ButtonContainer>
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
            className={styles.welcomeCol2}
          >
            <img src={WelcomeImg} alt="Welcome" />
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default ContinueToPayment;
