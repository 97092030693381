import styled from "styled-components";

const SearchBox = styled.input`
  border-style: none;
  color: black;
  background-color: white;
  border-radius: 20px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  width: ${(props) => (props.width ? props.width : "60%")};
  padding: 10px;
  placeholder: ${(props) => (props.placeholder ? props.placeholder : "")};
`;

export default SearchBox;
