import * as React from "react";
import { useLocation, useHistory } from "react-router";
import { useGoogleLogin } from "@react-oauth/google";
import { GoogleButton, GoogleButtonDiv } from "../Signup/styled";
import googleIcon from "../../../assets/images/googleIcon.svg";
import { AuthContext } from "../../../context/authContext/authContext";
import { APPLICATION_ROUTES } from "../../../utils/constant";

import styles from "./styles.module.css";

const GoogleLoginButton:React.FC<any> = ({userPlan, type}) => {
  const history = useHistory();

  const authContext = React.useContext(AuthContext);

  const { onGoogleLogin } = authContext;

  const login = useGoogleLogin({
    onSuccess: (codeResponse) => {
      console.log(
        "response from line 20 in GoogleLoginButton.tsx file",
        codeResponse,
      );
      let googleLoginParams:any = {
        idToken:codeResponse.code, userPlan, type
      }
      onGoogleLogin(googleLoginParams, () => {
        history.push(APPLICATION_ROUTES.CREATE_NEW_SURVEY);
      });
    },
    flow: "auth-code",
  });

  const location = useLocation();

  return (
    <GoogleButtonDiv className={`${styles.googleContainer}`}>
      <GoogleButton onClick={() => login()}>
        <img className="googleIcon" src={googleIcon} alt="icon" />
        {location.pathname === APPLICATION_ROUTES.SIGN_UP
          ? "sign up "
          : "login "}
        with Google
      </GoogleButton>
    </GoogleButtonDiv>
  );
};

export default GoogleLoginButton;
