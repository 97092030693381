import styled from "styled-components";

type TextareaType = {
  width?: string;
  height?: string;
};

const Textarea = styled.textarea<TextareaType>`
  height: ${(props: any) => (props.height ? props.height : "100px")};
  resize: none;
  width: 100%;
  margin: auto;
  outline: none;
  border-color: #d8d8d8;
  border-radius: 6px;
  padding: 15px;
  width: ${(props: any) => (props.width ? props.width : "100%")};
  font-size: 18px;
`;
export default Textarea;
