import { Grid } from "@mui/material";
import React from "react";
import SpeakerNotesOffIcon from "@material-ui/icons/SpeakerNotesOff";
import EditIcon from "@material-ui/icons/Edit";
import ShareIcon from "@material-ui/icons/Share";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useHistory, useLocation } from "react-router";
import { useDispatch } from "react-redux";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import PublishIcon from "@mui/icons-material/Publish";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import CallMadeIcon from "@mui/icons-material/CallMade";
import { APPLICATION_ROUTES } from "../../utils/constant";
import DeleteSurveyModal from "../../pages/screens/DashboardNewUser/deleteSurveyModal";
import {
  createDialogFlowIntent,
  disableEnableSurvey,
  setErrorOnSections,
} from "../../redux/action-creator/dialogflow.action";
import styles from "../../pages/screens/DashboardNewUser/styles.module.css";
import PublishSurveyModal from "../../pages/screens/AddQuestionaries/publishSurveyModal/publishSurveyModal";
import ButtonContainer from "../../styledComponents/button/button";
import { CustomStyledTooltip } from "../ToolTip/styled";
import { CHATBOT_LINK } from "../../utils/apiConstant";

type SurveyActionButtonsType = {
  surveyData: any;
  buttonText?: boolean;
  notShare?: boolean;
  fromAnalytics?: boolean;
  className?: string;
};

const SurveyActionButtons: React.FC<SurveyActionButtonsType> = ({
  surveyData,
  buttonText,
  notShare,
  fromAnalytics,
  className,
}) => {
  const [isDeleteAlertDialogOpen, setIsDeleteAlertDialogOpen] =
    React.useState(false);

  const handleCloseModal = () => {
    setIsDeleteAlertDialogOpen(false);
  };
  const [surveyIdToDelete, setSurveyIdToDelete] = React.useState("");
  const [surveyIdToPublish, setSurveyIdToPublish] = React.useState("");
  const [openPublishModal, setOpenPublishModal] = React.useState(false);

  const dispatch = useDispatch();
  const history = useHistory();
  const handleDelete = () => {
    dispatch(
      disableEnableSurvey({
        surveyData: { surveyId: surveyIdToDelete, isActive: false },
        callback: () => {
          history.replace(APPLICATION_ROUTES.DASHBOARD);
        },
      }),
    );
    setIsDeleteAlertDialogOpen(false);
    setSurveyIdToDelete("");
  };
  const onPublishSurvey = async () => {
    dispatch(
      createDialogFlowIntent(surveyIdToPublish, () => {
        history.push(
          `${
            APPLICATION_ROUTES.SHARE_SURVEY
          }?surveyId=${surveyIdToPublish}&sharing=${true}&isPublished=true`,
        );
      }),
    );
    setSurveyIdToPublish("");
  };

  const location = useLocation();
  const handleOptionClick = (
    value: string,
    surveyId: string,
    surveyName: string,
  ) => {
    switch (value) {
      case "edit":
        dispatch(setErrorOnSections([]));
        history.push(
          `${APPLICATION_ROUTES.EDIT_QUESTIONIONARIES}?surveyId=${surveyId}&section=1`,
        );
        break;

      case "disable":
        setSurveyIdToDelete(surveyId);
        setIsDeleteAlertDialogOpen(true);
        break;

      case "share":
        history.push(
          `${
            APPLICATION_ROUTES.SHARE_SURVEY
          }?surveyId=${surveyId}&sharing=${true}&isPublished=true`,
        );
        break;
      case "test":
        window.open(`${CHATBOT_LINK}/${surveyId}?test=test`, "_blank");

        break;

      case "analytics":
        history.push(
          `${APPLICATION_ROUTES.ANALYTICS}?surveyId=${surveyId}&surveyName=${surveyName}`,
        );
        break;
      case "view":
        history.push(`${APPLICATION_ROUTES.VIEW_SURVEY}?surveyId=${surveyId}`);
        break;
      case "enable":
        dispatch(
          disableEnableSurvey({
            surveyData: { surveyId, isActive: true },
            callback: () => {
              history.replace(APPLICATION_ROUTES.DASHBOARD);
            },
          }),
        );
        break;
      case "publish":
        setSurveyIdToPublish(surveyId);
        setOpenPublishModal(true);
        break;
      default:
        break;
    }
  };

  return (
    <Grid
      container
      className={
        location.pathname == APPLICATION_ROUTES.VIEW_SURVEY
          ? `${styles.viewSurveyHeadCol2} ${styles.viewSurveyActionBtn} ${className}`
          : `${styles.viewSurveyHeadCol2} actionBtnContainer ${className}`
      }
    >
      {location.pathname !== APPLICATION_ROUTES.VIEW_SURVEY && (
        <Grid item>
          <CustomStyledTooltip title="View Survey" placement="bottom">
            <ButtonContainer
              onClick={() =>
                handleOptionClick(
                  "view",
                  surveyData._id,
                  surveyData?.title || "",
                )
              }
            >
              <>
                <VisibilityIcon />
                {buttonText && "View"}
              </>
            </ButtonContainer>
          </CustomStyledTooltip>
        </Grid>
      )}
      {surveyData?.isPublished === false && surveyData?.isActive === true ? (
        <>
          <Grid item>
            <CustomStyledTooltip title="Edit Survey" placement="bottom">
              <ButtonContainer
                onClick={() =>
                  handleOptionClick(
                    "edit",
                    surveyData._id,
                    surveyData?.title || "",
                  )
                }
              >
                <>
                  <EditIcon />
                  {buttonText && "Edit Survey"}
                </>
              </ButtonContainer>
            </CustomStyledTooltip>
          </Grid>
          <Grid item>
            <CustomStyledTooltip title="Publish Survey" placement="bottom">
              <ButtonContainer
                onClick={() =>
                  handleOptionClick(
                    "publish",
                    surveyData._id,
                    surveyData?.title || "",
                  )
                }
              >
                <>
                  <PublishIcon />
                  {buttonText && "Publish Survey"}
                </>
              </ButtonContainer>
            </CustomStyledTooltip>
          </Grid>
        </>
      ) : (
        surveyData?.isPublished === false && (
          <Grid item>
            <CustomStyledTooltip title="Edit" placement="bottom">
              <ButtonContainer
                onClick={() =>
                  handleOptionClick(
                    "edit",
                    surveyData._id,
                    surveyData?.title || "",
                  )
                }
              >
                <>
                  <EditIcon />
                  {buttonText && "Edit"}
                </>
              </ButtonContainer>
            </CustomStyledTooltip>
          </Grid>
        )
      )}
      {surveyData?.isPublished && (
        <>
          {surveyData?.isActive && !fromAnalytics && (
            <Grid item>
              <CustomStyledTooltip title="Test Survey" placement="bottom">
                <ButtonContainer
                  onClick={() =>
                    handleOptionClick(
                      "test",
                      surveyData._id,
                      surveyData?.title || "",
                    )
                  }
                >
                  <>
                    <CallMadeIcon />
                    {buttonText && "Test your survey"}
                  </>
                </ButtonContainer>
              </CustomStyledTooltip>
            </Grid>
          )}
          {surveyData?.isActive === true && !notShare && (
            <Grid item>
              <CustomStyledTooltip title="Share Survey" placement="bottom">
                <ButtonContainer
                  onClick={() =>
                    handleOptionClick(
                      "share",
                      surveyData._id,
                      surveyData?.title || "",
                    )
                  }
                >
                  <>
                    <ShareIcon />
                    {buttonText && "Share Survey"}
                  </>
                </ButtonContainer>
              </CustomStyledTooltip>
            </Grid>
          )}
          {location.pathname !== APPLICATION_ROUTES.ANALYTICS && (
            <Grid item>
              <CustomStyledTooltip title="Survey Analytics" placement="bottom">
                <ButtonContainer
                  onClick={() =>
                    handleOptionClick(
                      "analytics",
                      surveyData._id,
                      surveyData?.title || "",
                    )
                  }
                >
                  <>
                    <AnalyticsIcon />
                    {buttonText && "Analytics"}
                  </>
                </ButtonContainer>
              </CustomStyledTooltip>
            </Grid>
          )}
          {surveyData?.isActive === true && !notShare && (
            <Grid item>
              <CustomStyledTooltip title="Disable Survey" placement="bottom">
                <ButtonContainer
                  onClick={() =>
                    handleOptionClick(
                      "disable",
                      surveyData._id,
                      surveyData?.title || "",
                    )
                  }
                >
                  <>
                    <SpeakerNotesOffIcon />
                    {buttonText && "Disable Survey"}
                  </>
                </ButtonContainer>
              </CustomStyledTooltip>
            </Grid>
          )}
        </>
      )}
      {surveyData?.isActive === false && (
        <Grid item>
          <CustomStyledTooltip title="Enable survey" placement="bottom">
            <ButtonContainer
              onClick={() =>
                handleOptionClick(
                  "enable",
                  surveyData._id,
                  surveyData?.title || "",
                )
              }
            >
              <>
                <PowerSettingsNewIcon />
                {buttonText && "Enable"}
              </>
            </ButtonContainer>
          </CustomStyledTooltip>
        </Grid>
      )}
      <DeleteSurveyModal
        open={isDeleteAlertDialogOpen}
        handleClose={handleCloseModal}
        handleDelete={handleDelete}
      />
      {openPublishModal && (
        <PublishSurveyModal
          open={openPublishModal}
          handleClose={() => setOpenPublishModal(false)}
          handlePublish={() => {
            onPublishSurvey();
            setOpenPublishModal(false);
          }}
          surveyId={surveyData._id}
        />
      )}
    </Grid>
  );
};

export default SurveyActionButtons;
