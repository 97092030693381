import { BASE_URL_FOR_NEXT_WEB } from "./apiConstant";
import { APPLICATION_ROUTES } from "./constant";

export const menuRoutes = [
  {
    id: 1,
    name: "Home",
    url: BASE_URL_FOR_NEXT_WEB,
    redirected: true,
  },
  // {
  //   id: 2,

  //   name: "About Us",
  //   url: APPLICATION_ROUTES.ABOUT_US,
  //   access: ["admin", "superAdmin"],
  // },
  {
    id: 3,

    name: "Plans & Pricing",
    url: BASE_URL_FOR_NEXT_WEB + "/planPricing",
    access: ["admin", "superAdmin"],
    redirected: true,
  },
  {
    id: 4,
    name: "My Surveys",
    url: APPLICATION_ROUTES.DASHBOARD,
    access: ["admin", "superAdmin"],
    redirected: false,
  },
  {
    id: 5,
    name: "Contact Us",
    url: BASE_URL_FOR_NEXT_WEB + "/contact",
    redirected: true,
  },
];

export const dashboardRoutes = [
  {
    id: 1,
    name: " Create Survey",
    url: APPLICATION_ROUTES.CREATE_NEW_SURVEY,
    activeUrls: [
      APPLICATION_ROUTES.CREATE_NEW_SURVEY,
      APPLICATION_ROUTES.EDIT_QUESTIONIONARIES,
      APPLICATION_ROUTES.ADD_QUESTIONIONARIES,
    ],
    access: ["superAdmin"],
    icon: "ViewListOutlined",
  },
  {
    id: 2,
    name: "My Surveys",
    url: APPLICATION_ROUTES.DASHBOARD,
    activeUrls: [APPLICATION_ROUTES.DASHBOARD, APPLICATION_ROUTES.VIEW_SURVEY],
    access: ["admin", "superAdmin"],
    icon: "Dashboard",
  },
  {
    id: 3,
    name: "Analytics",
    url: APPLICATION_ROUTES.ALL_ANALYTICS_PAGE,
    activeUrls: [
      APPLICATION_ROUTES.ALL_ANALYTICS_PAGE,
      APPLICATION_ROUTES.ANALYTICS,
    ],
    access: ["admin", "superAdmin"],
    icon: "Poll",
  },

  {
    id: 4,
    name: "Profile",
    url: APPLICATION_ROUTES.ACCOUNT,
    activeUrls: [APPLICATION_ROUTES.ACCOUNT],
    access: ["admin", "superAdmin"],
    icon: "AccountBoxOutlined",
  },
  {
    id: 5,
    name: "Logout",
    activeUrls: [],
    access: ["admin", "superAdmin"],
    icon: "ExitToAppOutlined",
  },
];
