import styled from "styled-components";
import {
  headingColor,
  secondaryButtonColor,
} from "../../../utils/colorConstants";

export const ErrorBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-direction: column;
  padding: 120px 0px 30px 0px;
`;
export const ErrorHeading = styled.h1`
  font-weight: 700;
  font-size: 2.5rem;
  line-height: 1.5;
  text-align: center;
  color: ${secondaryButtonColor};
  font-family: "Raleway", sans-serif;
`;

export const ErrorPara = styled.p`
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 1;
  text-align: center;
  color: ${headingColor};
  font-family: "Raleway", sans-serif;
`;
export const ErrorImage = styled.img`
  width: 100%;
`;
