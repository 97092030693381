import { ActionType } from "../types";

const initialState = {
  loading: {
    state: false,
    buttonFlag: "",
  },
  errorMessages: [],
  successMessages: [],
};
// eslint-disable-next-line @typescript-eslint/default-param-last
export const commonReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case ActionType.LOADING_STATE:
      return {
        ...state,
        loading: { ...action.payload },
      };
    case ActionType.SET_ERROR_MESSAGE:
      return {
        ...state,
        errorMessages: [...state.errorMessages, action.payload],
      };
    case ActionType.SET_SUCCESS_MESSAGE:
      return {
        ...state,
        successMessages: [...state.successMessages, action.payload],
      };
    case ActionType.REMOVE_SUCCESS_MESSAGE:
      return {
        ...state,
        successMessages: state.successMessages.filter(
          (data: any) => data.key !== action.payload.key,
        ),
      };
    case ActionType.REMOVE_ERROR_MESSAGE:
      return {
        ...state,
        errorMessages: state.errorMessages.filter(
          (data: any) => data.key !== action.payload.key,
        ),
      };
    default:
      return state;
  }
};
