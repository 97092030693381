import { Grid } from "@material-ui/core";
import * as React from "react";
import Header from "../../../parts/Header";
import LoaderImage from "../../../assets/images/botLoader.gif";

interface ILoadingProps{
  noHeader?:boolean;
}

const LoadingPage:React.FC<ILoadingProps> = ({noHeader}) => {
  return (
    <>
    {noHeader ? null :   <Header />}
      <Grid
        container
        justifyContent="center"
        style={{ margin: "150px 0 100px 0" }}
      >
        <Grid item>
          <img src={LoaderImage} alt="loader" style={{ width: "300px" }} />
        </Grid>
      </Grid>
    </>
  );
};

export default LoadingPage;
