import styled from "styled-components";

const FormHalfDiv = styled.form`
  // width: 50%;
  // padding: 5% 10%;
  // /* border-radius:20px; */
  // /* box-shadow: 0 0 4px ; */
  // background-color: #fff;
  // height: 100%;
`;

export default FormHalfDiv;
